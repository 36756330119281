<section class="swiper-section">
  <swiper [config]="swiperConfig" class="mySwiper">
    <ng-template swiperSlide *ngFor="let item of carouselElements; index as i">
      <ng-container *ngVar="getImage(i) as currentSlide">
        <div
          *ngIf="currentSlide.background; else noBackground"
          class="swiper-wrapper-background"
          (click)="onClickSwiperItem(item.redirectPath)"
          [ngStyle]="{
            'background-color': currentSlide.background.color,
            'background-image': getBackgroundColor(currentSlide.background.image)
          }"
        >
          <div class="netfone-container">
            <img class="img-with-bg" [src]="currentSlide.image" alt="" />
          </div>
        </div>
        <ng-template #noBackground>
          <div class="swiper-wrapper-without-background" (click)="onClickSwiperItem(item.redirectPath)">
            <img class="img-without-bg" [src]="currentSlide.image" alt="" />
          </div>
        </ng-template>
      </ng-container>
    </ng-template>
  </swiper>
</section>
<section class="packages-section">
  <div class="light-orange-design-circle"></div>
  <div class="dark-orange-design-circle"></div>
  <div class="netfone-container">
    <div class="packages-wrapper">
      <ose-large-mobile-package
        *ngFor="let packageItem of packages"
        [packageDetails]="packageItem"
        (buttonClicked)="viewPackage($event)"
        [displayAsPillOnMobile]="true"
      ></ose-large-mobile-package>
    </div>
  </div>
  <div class="ask-us-wrapper">
    <ose-endless-line type="black" class="black-background"></ose-endless-line>
    <div class="netfone-container flex-row justify-content-between align-items-center">
      <div class="title">Kérdésed van? Tedd fel nekünk, segítünk!</div>
      <button class="question-button" (click)="navigateAndScrollTo('/kapcsolat', 'contactUsForm')">Érdeklődöm</button>
    </div>
  </div>
</section>
<section class="advantage-section orange-bg">
  <div class="netfone-container">
    <div class="advantage reverse">
      <div class="image-container">
        <img class="advantage-image first" src="assets/images/home_phone_2.webp" alt="" />
      </div>
      <div class="advantage-body first">
        <ose-endless-line type="white" class="advantage-background first"></ose-endless-line>
        <h3 class="advantage-title">
          TELEFONOK RÉSZLETRE <br />
          <span class="highlighted">0 FT</span>-TÓL
        </h3>
        <p class="advantage-description">
          A nyomógombostól a felsőkategóriáig nálunk mindent megtalálsz, hiszen a mi portfóliónk legszélesebb az
          országban. Nézd folyamatosan weboldalunkat a hétről-hétre megújuló ajánlatainkért.
        </p>
        <div class="action-button-wrapper">
          <button class="new-netfone-button secondary black" routerLink="/keszulekek">Árak és készülékek</button>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="reason-section">
  <div class="section-title-wrapper">
    <ose-endless-line type="orange" class="grey-background"></ose-endless-line>
    <div class="netfone-container">
      <div class="title">5+1 JÓ OK AMIÉRT ÉRDEMES MINKET VÁLASZTANOD ÚJ MOBILSZOLGÁLTATÓDNAK</div>
    </div>
  </div>
  <div class="mobile-reason-wrapper netfone-container">
    <div class="reason">
      <div class="title-wrapper">
        <img src="../../../assets/images/ikon_mar_ennyien_black_no_text.webp" alt="" />
        <h1 class="title">MÁR ENNYIEN VÁLASZTOTTAK MINKET!</h1>
      </div>
      <div class="description">
        <img class="map" src="assets/images/mar_ennyien_terkep.webp" alt="" />
      </div>
    </div>
    <div class="reason">
      <div class="title-wrapper">
        <img src="assets/images/argarancia_ikon_sarga.svg" alt="" />
        <h1 class="title">ÁRGARANCIA</h1>
      </div>
      <div class="description">
        Mi is hűséget fogadunk Neked és biztosítunk Téged arról, hogy a piacon elérhető publikus árakhoz képest az
        árelőnyt mindig fenntartjuk.
      </div>
    </div>
    <div class="reason">
      <div class="title-wrapper">
        <img src="../../../assets/images/ikon_lefedettseg_black_no_text.svg" alt="" />
        <h1 class="title">ORSZÁGOS LEFEDETTSÉG</h1>
      </div>
      <div class="description">Magyarország egész területén 98%-os lefedettséget biztosítunk ügyfeleink számára.</div>
    </div>
    <div class="reason">
      <div class="title-wrapper">
        <img src="../../../assets/images/ikon_nem_csak_multunk_1_black_no_text.svg" alt="" />
        <h1 class="title">NEM CSAK MÚLTUNK, JÖVŐNK IS VAN</h1>
      </div>
      <div class="description">
        A Netfone Telecom több mint 10 év, a távközlésben szerzett tapasztalattal büszkélkedhet. Itt nem állunk meg,
        célunk és törekvésünk egy több generációs telekommunikációs vállalat felépítése. Rád is számítunk az úton.
      </div>
    </div>
    <div class="reason">
      <div class="title-wrapper">
        <img src="../../../assets/images/ikon_szamhordozas_black_no_text.svg" alt="" />
        <h1 class="title">MEG AKAROD TARTANI A RÉGI MOBILSZÁMOD?</h1>
      </div>
      <div class="description">
        Gyere át hozzánk, legyél Te is Netfone-os és ha akarod tartsd meg a régi telefonszámod, mi mindent villámgyorsan
        elintézünk.
      </div>
    </div>
    <div class="reason">
      <div class="title-wrapper">
        <img src="../../../assets/images/ikon_mobilszolgaltato_1_black_no_text.svg" alt="" />
        <h1 class="title">MOBILSZOLGÁLTATÓ VAGYUNK</h1>
      </div>
      <div class="description">
        Büszkék vagyunk rá, hogy nem flotta vagyunk hanem valódi Mobilszolgáltató. Hogyan lehet ez? Pontosan úgy ahogy
        több internet vagy vezetékes telefonszolgáltató is működik. Kötöttünk egy Mobil Nagykereskedelmi szerződést két
        nagy mobilszolgáltatóval és fejlesztettünk egy saját mobilplatformot amivel vezéreljük a hálózatokat.
      </div>
    </div>
  </div>
  <div class="pc-reason-wrapper netfone-container">
    <div class="reason">
      <div class="title-wrapper">
        <img src="assets/images/ikon_mar_ennyien_black_no_text.webp" alt="" />
        <h1 class="title">MÁR ENNYIEN VÁLASZTOTTAK MINKET!</h1>
      </div>
      <div class="description">
        <img class="map" src="assets/images/mar_ennyien_terkep_nagy.webp" alt="" />
      </div>
    </div>
    <div class="reason-column-wrapper">
      <div class="reason-column">
        <div class="reason">
          <div class="title-wrapper">
            <img src="assets/images/argarancia_ikon_sarga.svg" alt="" />
            <h1 class="title">ÁRGARANCIA</h1>
          </div>
          <div class="description">
            Mi is hűséget fogadunk Neked és biztosítunk Téged arról, hogy a piacon elérhető publikus árakhoz képest az
            árelőnyt mindig fenntartjuk.
          </div>
        </div>
        <div class="reason">
          <div class="title-wrapper">
            <img src="../../../assets/images/ikon_lefedettseg_black_no_text.svg" alt="" />
            <h1 class="title">ORSZÁGOS LEFEDETTSÉG</h1>
          </div>
          <div class="description">
            Magyarország egész területén 98%-os lefedettséget biztosítunk ügyfeleink számára.
          </div>
        </div>
        <div class="reason">
          <div class="title-wrapper">
            <img src="../../../assets/images/ikon_nem_csak_multunk_1_black_no_text.svg" alt="" />
            <h1 class="title">NEM CSAK MÚLTUNK, JÖVŐNK IS VAN</h1>
          </div>
          <div class="description">
            A Netfone Telecom több mint 10 év, a távközlésben szerzett tapasztalattal büszkélkedhet. Itt nem állunk meg,
            célunk és törekvésünk egy több generációs telekommunikációs vállalat felépítése. Rád is számítunk az úton.
          </div>
        </div>
      </div>
      <div class="reason-column">
        <div class="reason">
          <div class="title-wrapper">
            <img src="../../../assets/images/ikon_szamhordozas_black_no_text.svg" alt="" />
            <h1 class="title">MEG AKAROD TARTANI A RÉGI MOBILSZÁMOD?</h1>
          </div>
          <div class="description">
            Gyere át hozzánk, legyél Te is Netfone-os és ha akarod tartsd meg a régi telefonszámod, mi mindent
            villámgyorsan elintézünk.
          </div>
        </div>
        <div class="reason">
          <div class="title-wrapper">
            <img src="../../../assets/images/ikon_mobilszolgaltato_1_black_no_text.svg" alt="" />
            <h1 class="title">MOBILSZOLGÁLTATÓ VAGYUNK</h1>
          </div>
          <div class="description">
            Büszkék vagyunk rá, hogy nem flotta vagyunk hanem valódi Mobilszolgáltató. Hogyan lehet ez? Pontosan úgy
            ahogy több internet vagy vezetékes telefonszolgáltató is működik. Kötöttünk egy Mobil Nagykereskedelmi
            szerződést két nagy mobilszolgáltatóval és fejlesztettünk egy saját mobilplatformot amivel vezéreljük a
            hálózatokat.
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="flexible-section">
  <div class="section-title-wrapper">
    <ose-endless-line type="orange" class="orange-background"></ose-endless-line>
    <ose-endless-line type="white" class="white-background"></ose-endless-line>
    <div class="netfone-container">
      <div class="title">RUGALMAS DÍJCSOMAG KONCEPCIÓK</div>
    </div>
  </div>
  <div class="netfone-container">
    <div class="flexible-detail-wrapper">
      <div class="flexible-detail">
        <img src="assets/images/service-chooser/ikon_100_perc_1.svg" alt="" />
        <div class="description">100 PERC BESZÉLGETÉS AKÁR 100 GB INTERNET</div>
        <a class="view-details" routerLink="/mobil-szolgaltatas">Részletek</a>
      </div>
      <div class="flexible-detail">
        <img src="assets/images/service-chooser/ikon_inland_1_.svg" alt="" />
        <div class="description">KORLÁTLAN BESZÉLGETÉS BELFÖLDÖN</div>
        <a class="view-details" routerLink="/mobil-szolgaltatas">Részletek</a>
      </div>
      <!--      <div class="flexible-detail">-->
      <!--        <img src="assets/images/service-chooser/ikon_eu_1.svg" alt="" />-->
      <!--        <div class="description">TELEFONÁLJ, INTERNETEZZ SZABADON BÁRMELY EU ORSZÁGBAN</div>-->
      <!--        <a class="view-details" routerLink="/mobil-szolgaltatas">Részletek</a>-->
      <!--      </div>-->
    </div>
  </div>
</section>
<section class="advantage-section grey-bg">
  <div class="netfone-container">
    <div class="advantage reverse">
      <div class="image-container">
        <img class="advantage-image first" src="assets/images/continuous_couple.webp" alt="" />
      </div>
      <div class="advantage-body third">
        <ose-endless-line type="light-gray" class="advantage-background third"></ose-endless-line>
        <h3 class="advantage-title black">EMLÉKSZEL? AZT ÍRTUK, HOGY RUGALMAS DÍJCSOMAGOK</h3>
        <p class="advantage-description">
          Nálunk szabadon változtathatod bármikor díjcsomagjai-dat, ahogy Neked a legjobb. Egyik hónapban sokat
          be-szélsz, semmi gond, válaszd bármely korlátlan beszélgetős csomagunkat. <br />Úgy érzed, hogy nem használod
          ki a neted vagy nem beszélsz eleget? Nálunk büntetlenül válthatsz kisebb vagy nagyobb csomagra is, nem kerül
          semmibe. Jól hangzik igaz? Nálunk így is van.
        </p>
      </div>
    </div>
  </div>
  <div class="dark-orange-circle-pc"></div>
  <div class="orange-circle-pc"></div>
  <div class="light-orange-circle-pc"></div>
</section>
<section class="order-process-section">
  <div class="order-process-title-wrapper">
    <div class="netfone-container">
      <h1 class="order-process-title">Egyszerű a megrendelés folyamata</h1>
      <h1 class="order-process-title-mobile">A megrendelés menete</h1>
    </div>
  </div>
  <div class="netfone-container">
    <div class="step-container">
      <ose-endless-line type="white" class="order-process-background"></ose-endless-line>
      <ul class="step-list">
        <li class="step-item">
          <h6 class="step-label">1. lépés</h6>
          <div class="step-image-wrapper">
            <img class="step-image" src="assets/images/first_step.svg" alt="" />
          </div>
          <h6 class="step-title">Válaszd ki a csomagot</h6>
          <small class="step-description">Nézd meg, melyik a legjobb!</small>
        </li>
        <li class="step-item">
          <h6 class="step-label">2. lépés</h6>
          <div class="step-image-wrapper">
            <img class="step-image" src="assets/images/second_step.svg" alt="" />
          </div>
          <h6 class="step-title">Felvesszük veled a kapcsolatot</h6>
          <small class="step-description">Lepontosítunk mindent, hogy a legjobb csomagot adhassuk.</small>
        </li>
        <li class="step-item">
          <h6 class="step-label">3. lépés</h6>
          <div class="step-image-wrapper">
            <img class="step-image" src="assets/images/third_step.svg" alt="" />
          </div>
          <h6 class="step-title">Pár napon belül élvezheted</h6>
          <small class="step-description">Számhordozással vagy anélkül néhány nap alatt velünk telefonálhatsz.</small>
        </li>
      </ul>
    </div>
  </div>
  <div class="dark-orange-circle-tablet"></div>
  <div class="orange-circle-tablet"></div>
  <div class="light-orange-circle-tablet"></div>
</section>

<section class="colleague-section" id="colleagues">
  <div class="netfone-container">
    <p class="introduction">Jó arcok, akik az ország leginnovatívabb Mobilcége mögött állnak</p>
    <p class="description">
      Van itt aztán mindenki. Kicsi, nagy, kutyás és cicás, vicces és komoly, netfüggő és nagydumás. A célunk viszont az
      a munkaidőn belül - és sokszor kívül is - hogy stabil, korrekt, de legfőképpen élvezhető szolgáltatást
      nyújthassunk Neked. Így születhetett meg a zseniális 50 gigás csomagunk, ezért büszkélkedhetünk az ország legjobb
      arc ügyfélszolgálatával és ezért olyan elégedettek az ügyfeleink.
      <br /><br />
      Munkaidőn kívül pedig horgászunk, vitorlázunk és bulizunk - de majd ezt is megbeszéljük, amikor az ügyfelünk
      leszel.
    </p>
  </div>
</section>
<section class="colleague-carousel-section">
  <div class="netfone-container">
    <ose-colleague-carousel></ose-colleague-carousel>
  </div>
</section>
<section class="further-discount">
  <div class="netfone-container">
    <div class="further-discount-container">
      <button class="new-netfone-button secondary black" routerLink="/mobil-szolgaltatas">MOST AKAROM</button>
      <h3 class="further-discount-title">Nézd meg kedvező csomagjainkat</h3>
    </div>
  </div>
  <div class="dark-orange-circle-mobile"></div>
  <div class="orange-circle-mobile"></div>
  <div class="light-orange-circle-mobile"></div>
</section>
