import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { EnvironmentVariablesService } from '../services/environment-variable-service/environment-variables.service';
import { ExposedEnvironmentVariable } from '../../../environments/environment-variables';
import { environment } from '../../../environments/environment';

function isAbsoluteUrl(url: string): boolean {
  return url.startsWith('http') || url.startsWith('//');
}

@Injectable()
export class ServerStateInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    private environmentVariablesService: EnvironmentVariablesService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.request && !isAbsoluteUrl(req.url)) {
      const DEV_URL = 'http://localhost:8080';
      // const PROD_URL = `${this.request.protocol}://${this.request.get('host')}`;
      const PROD_URL = this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL);
      const CURRENT_ENV_URL = environment.production ? PROD_URL : DEV_URL;

      const pathSeparatorForEnv = CURRENT_ENV_URL.endsWith('/') ? '' : '/';

      const pathSeparator = req.url.startsWith('/') ? '' : '/';
      const url = CURRENT_ENV_URL + pathSeparatorForEnv + 'api/v1' + pathSeparator + req.url;
      const serverRequest = req.clone({ url });

      return next.handle(serverRequest);
    }

    return next.handle(req);
  }
}
