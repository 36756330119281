import { Injectable } from '@angular/core';
import { XhrService } from '../../../core/services/xhr-service/xhr.service';
import {
  FindByShortUrlRequestDto,
  FindByShortUrlResponseDto,
  GetShortUrlRequestDto,
  GetShortUrlResponseDto,
  RegisterShortUrlClick,
} from '../../interfaces/url-shortener.interfaces';
import { API } from '../../../global/API.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlShortenerService {
  constructor(private xhr: XhrService) {}

  shortenUrl(request: GetShortUrlRequestDto): Observable<GetShortUrlResponseDto> {
    return this.xhr.post<GetShortUrlResponseDto>(API.urlShortener.shortenUrl, request);
  }

  findByShortUlr(request: FindByShortUrlRequestDto): Observable<FindByShortUrlResponseDto> {
    return this.xhr.post(API.urlShortener.findByShortUrl, request);
  }

  registerClick(request: RegisterShortUrlClick): Observable<unknown> {
    return this.xhr.post(API.share.shortUrl, request);
  }
}
