import { Injectable } from '@angular/core';
import { XhrService } from '../../../core/services/xhr-service/xhr.service';
import { Observable } from 'rxjs';
import { IValidateAdCampaignResponseDTO } from '../../interfaces/ad-campaing.interfaces';
import { strings } from '../../functions/global-string.functions';
import { API } from '../../../global/API.constants';
import { GlobalConstants } from '../../../global/global.constants';
import { SessionStorageService } from '../../../core/services/session-storage-service/session-storage.service';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';
import { FacebookPixelService } from '../../../core/services/facebook-pixel-service/facebook-pixel.service';

@Injectable({
  providedIn: 'root',
})
export class AdCampaignService {
  constructor(
    private xhr: XhrService,
    private sessionStorageService: SessionStorageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private facebookPixelService: FacebookPixelService
  ) {}

  private validateAdCampaign(request: string): Observable<IValidateAdCampaignResponseDTO> {
    return this.xhr.get(
      strings.replaceURLParams(API.adCampaign.validate, { adCampaignIdentifier: request }),
      null,
      null,
      false
    );
  }

  setAdCampaign(adCampaign: string) {
    const currentAdCampaign = this.sessionStorageService.getItem(
      GlobalConstants.sessionStorageKeys.adCampaignIdentifier
    );

    if (!adCampaign || currentAdCampaign === adCampaign) {
      return;
    }

    this.validateAdCampaign(adCampaign).subscribe((response) => {
      this.googleAnalyticsService.eventEmitter(
        'kampany_megtekintes',
        'hirdetesek',
        'view',
        'Kampány megtekintés',
        response.adCampaignIdentifier
      );

      this.facebookPixelService.eventEmitter('trackCustom', 'kampany_megtekintes', {
        data: response.adCampaignIdentifier,
      });

      this.sessionStorageService.setItem(
        GlobalConstants.sessionStorageKeys.adCampaignIdentifier,
        response.adCampaignIdentifier
      );
    });
  }
}
