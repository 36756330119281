import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingPageService } from '../../../modules/landing-pages/landing-page.service';
import { CookieDialogService } from '../../services/cookie-dialog/cookie-dialog.service';
import { InactiveMultipleLandingPages } from '../../constants/landing-pages.constants';

export interface CustomLandingUrlMapper {
  url: string;
  redirectTo?: string;
  queryParams?: Record<string, any>;
}

@Injectable({
  providedIn: 'root',
})
export class CustomLandingGuard implements CanActivate {
  urlMapper: CustomLandingUrlMapper[] = [
    {
      url: 'akcios-keszulekek-reszlet-nelkul',
      redirectTo: 'keszulekek',
      queryParams: { akcio: true },
    },
  ];

  constructor(
    private landingPageService: LandingPageService,
    private router: Router,
    private cookieDialogService: CookieDialogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    InactiveMultipleLandingPages.forEach((page) => {
      this.urlMapper.push({
        url: page,
        redirectTo: '/',
      });
    });
    const nicknev = route.paramMap.get('username')?.trim()?.toLocaleLowerCase();
    const finalStateUrl = state.url[0] === '/' ? state.url.substring(1, state.url.length) : state.url;
    const newRoute = this.urlMapper.find((item) => item.url === finalStateUrl.split('/')[0]);
    if (!newRoute) {
      this.router.navigate(['/404']);
      return false;
    }

    if (InactiveMultipleLandingPages.includes(newRoute.url)) {
      return this.setNicknameAndNavigate(nicknev, newRoute);
    }

    switch (newRoute.url) {
      case 'akcios-keszulekek-reszlet-nelkul':
        return this.setNicknameAndNavigate(nicknev, newRoute);
    }
    return false;
  }

  private setNicknameAndNavigate(nicknev: string, newRoute: CustomLandingUrlMapper) {
    if (nicknev) {
      this.cookieDialogService.shouldOpenCookieDialog.next(true);
      this.landingPageService.findAndSetRecommender(nicknev);
    }
    this.router.navigate([newRoute.redirectTo], { queryParams: newRoute.queryParams ?? {} });
    return true;
  }
}
