import { Injectable } from '@angular/core';
import { XhrService } from '../../../core/services/xhr-service/xhr.service';
import { GetLoggedInUserResponseDto } from './profile.interfaces';
import { API } from '../../../global/API.constants';
import { GlobalConstants } from '../../../global/global.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private xhr: XhrService) {}

  loggedInUser(): Observable<GetLoggedInUserResponseDto> {
    return this.xhr.get<GetLoggedInUserResponseDto>(API.user.loggedInUser, { withCredentials: true });
  }

  update(request: FormData): Observable<void> {
    return this.xhr.post<void>(API.user.update, request, {}, GlobalConstants.SuccessMessages.UPDATE);
  }
}
