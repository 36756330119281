import { Params } from '@angular/router';

export interface ISwiperData {
  small: ISwiperResolutionData;
  smallMedium: ISwiperResolutionData;
  medium: ISwiperResolutionData;
  mediumLarge: ISwiperResolutionData;
  large: ISwiperResolutionData;
  redirectPath: IRedirectPath;
}

export interface ISwiperResolutionData {
  image: string;
  background?: {
    image?: string;
    color?: string;
  };
}

export interface IRedirectPath {
  url: string;
  queryParams?: Params;
}

export enum SwiperImageExtension {
  PNG = '.png',
  SVG = '.svg',
  JPEG = '.jpeg',
  JPG = '.jpg',
  WEBP = '.webp',
}
