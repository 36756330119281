import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object, private cookieService: CookieService) {
    // EMPTY
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setItem(key: string, value: any, expires: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set(key, value, expires);
    }
  }

  getItem(key: string): string {
    if (isPlatformBrowser(this.platformId)) {
      return this.cookieService.get(key);
    }
    return null;
  }

  storeObject(key: string, value: any[], expires: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set(key, JSON.stringify(value), expires);
    }
  }

  getObject<T>(key: string): T {
    if (isPlatformBrowser(this.platformId)) {
      const value = this.cookieService.get(key);
      return value ? (JSON.parse(value) as T) : null;
    }
    return null;
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.delete(key);
    }
  }
}
