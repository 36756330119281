<ng-container *ngIf="!desktopViewActive; else desktopView">
  <div class="mobile-wrapper" (scroll)="onScroll($event)">
    <ng-container *ngFor="let colleague of colleagueDataList; let i = index">
      <div class="colleague-wrapper" [class]="{ active: i === activeItemIndex }">
        <div class="image-wrapper">
          <img class="colleague-image" loading="lazy" [src]="colleague.imageName" [alt]="colleague.name" />
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #desktopView>
  <div class="desktop-wrapper">
    <span class="prev-button" (click)="jumpToPrevPerson()"></span>
    <div class="colleague-list-wrapper">
      <ng-container *ngFor="let colleague of colleagueDataList; let i = index">
        <div class="colleague-wrapper" [class]="{ active: i === activeItemIndex }">
          <div class="image-wrapper">
            <img class="colleague-image" loading="lazy" [src]="colleague.imageName" [alt]="colleague.name" />
          </div>
        </div>
      </ng-container>
    </div>
    <span class="next-button" (click)="jumpToNextPerson()"></span>
  </div>
</ng-template>

<h5 class="colleague-name">{{ activeColleague?.name }}</h5>
<p class="colleague-reason">
  {{ activeColleague?.description }}
</p>
