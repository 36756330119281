import { HostListener, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CheckBrowserTypeService } from '../check-browser-type/check-browser-type.service';
import { Observable, Subject } from 'rxjs';
import { ExposedEnvironmentVariable } from '../../../../environments/environment-variables';
import { Router } from '@angular/router';
import { UrlShortenerService } from '../url-shortener/url-shortener.service';
import { EnvironmentVariablesService } from '../../../core/services/environment-variable-service/environment-variables.service';

declare const FB: any;

@Injectable({
  providedIn: 'root',
})
export class SocialShareService {
  copied = new Subject<boolean>();

  private _innerWidth: number;

  get copiedAsObservable(): Observable<boolean> {
    return this.copied.asObservable();
  }
  get innerWidth(): number {
    return this._innerWidth;
  }

  set innerWidth(value: number) {
    this._innerWidth = value;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private checkBrowserTypeService: CheckBrowserTypeService,
    private router: Router,
    private urlShortenerService: UrlShortenerService,
    private environmentVariablesService: EnvironmentVariablesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this._innerWidth = (event.target as Window).innerWidth;
  }

  shareToMessenger(currentShareUrl: string): void {
    if (this.checkBrowserTypeService.isMobileOrTablet()) {
      window.open(
        'fb-messenger://share?link=' +
          encodeURIComponent(currentShareUrl) +
          '&app_id=' +
          encodeURIComponent('450921742498767')
      );
    } else {
      FB.ui(
        {
          method: 'send',
          link: currentShareUrl,
        },
        function (response: unknown) {}
      );
    }
  }

  shareToViber(currentShareUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open(`viber://forward?text=${currentShareUrl}`);
    }
  }

  shareToFacebook(currentShareUrl: string): void {
    FB.ui(
      {
        method: 'share',
        href: currentShareUrl,
      },
      function (response: unknown) {}
    );
  }

  shareToWhatsapp(currentShareUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open(`whatsapp://send?text=${currentShareUrl}`);
    }
  }

  shareToTelegram(currentShareUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open(`https://telegram.me/share/url?url=${currentShareUrl}`);
    }
  }

  copyShortUlr(currentShareUrl: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = currentShareUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied.next(true);
    setTimeout(() => {
      this.copied.next(false);
    }, 2000);
  }

  generateCurrentUrl(shortUrl: string): string {
    return `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL)}/s/${shortUrl}`;
  }
}
