import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage-service/session-storage.service';
import { XhrService } from '../../core/services/xhr-service/xhr.service';
import { FindDslServiceResponseDto } from './dsl-services.interfaces';
import { OrderService } from '../order/order.service';
import { GoogleAnalyticsService } from '../../core/services/google-analytics-service/google-analytics.service';
import { Observable } from 'rxjs';
import { API } from '../../global/API.constants';
import { FindByCRMRequestDto } from '../../global/global.interfaces';
import { Router } from '@angular/router';
import { DslPackageDetails } from '../../shared/components/dsl-package/dsl-package.interfaces';
import { GlobalConstants } from '../../global/global.constants';
import { IOrderUrlMobileAndDslServiceQueryParams, IOrderUrlQueryParams } from '../order/order.interfaces';
import { OrderTypeQueryParamEnum } from '../../global/global.enums';

@Injectable({
  providedIn: 'root',
})
export class DslServicesService {
  private _allDslServices: FindDslServiceResponseDto[];

  get allDslServices(): FindDslServiceResponseDto[] {
    return this._allDslServices;
  }

  set allDslServices(value: FindDslServiceResponseDto[]) {
    this._allDslServices = value;
  }

  constructor(
    private sessionStorageService: SessionStorageService,
    private xhr: XhrService,
    private orderService: OrderService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  findAllDslService(): Observable<FindDslServiceResponseDto[]> {
    return this.xhr.get(API.services.findAllDsl);
  }

  findDslServiceByCRM(request: FindByCRMRequestDto): Observable<FindDslServiceResponseDto> {
    return this.xhr.post<FindDslServiceResponseDto>(API.services.findByCRM, request);
  }

  updateOrderObjectWithSelectedDslPackage(packageItem: DslPackageDetails): void {
    this.sessionStorageService.removeItem(GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails);
    this.sessionStorageService.removeItem(GlobalConstants.sessionStorageKeys.installmentMode);

    this.orderService.selectedPhone = null;
    this.orderService.selectedMobileService = null;

    this.findDslServiceByCRM({ crmAzonosito: packageItem.crmId }).subscribe((response) => {
      this.googleAnalyticsService.eventEmitter('kattintas', 'dsl_megrendelem', null, 'gomb');
      this.orderService.selectedDslService = {
        dslPackage: {
          crmAzonosito: response.crmAzonosito,
          csomagNev: response.csomagNev,
          szegmens: response.szegmens,
          arTipus: response.arTipus,
          bennfoglaltNet: response.bennfoglaltNet,
          szolgaltatasArazas: response.szolgaltatasArazasResponseDTOList.find(
            (item) => item.husegHonap === packageItem.loyaltyMonth
          ),
        },
      };

      const serviceQueryParams: IOrderUrlMobileAndDslServiceQueryParams = {
        crmIdentifier: response.crmAzonosito,
        loyaltyMonth: packageItem.loyaltyMonth,
      };
      const queryParams: IOrderUrlQueryParams = {
        type: OrderTypeQueryParamEnum.DLS_SERVICE,
        data: serviceQueryParams,
      };
      const encodedQueryParams = btoa(JSON.stringify(queryParams));

      this.router.navigate(['megrendeles'], { relativeTo: null, queryParams: { od: encodedQueryParams } });
    });
  }
}
