import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ScrollService } from '../../../shared/services/scroll-service/scroll.service';
import { ShareDialogComponent } from '../../../shared/dialogs/share-dialog/share-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @ViewChild(ShareDialogComponent) shareDialogComponent: ShareDialogComponent;
  constructor(private scrollService: ScrollService) {}

  navigate(url: string): void {
    window.open(url);
  }

  getCurrentYear(): string {
    return moment().format('YYYY').toString();
  }

  navigateAndScrollTo(navigateTo: string, scrollTo: string): void {
    this.scrollService.navigateAndScrollToElement(navigateTo, scrollTo);
  }

  openShareDialog(): void {
    this.shareDialogComponent.open();
  }
}
