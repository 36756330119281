import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CompletionObserver, Subscription, timer } from 'rxjs';
import { NotificationService } from './notification.service';
import { Alert, AlertType } from './notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() id: string;

  alerts: Alert[] = [];
  subscription: Subscription;

  private completeObservable: CompletionObserver<number> = {
    complete: () => this.RemoveOldestAlert(),
  };

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.subscription = this.notificationService.onAlert(this.id).subscribe((alert) => {
      if (!alert.message) {
        // clear alerts when an empty alert is received
        this.alerts = [];
        return;
      }

      // add alert to array
      this.alerts.push(alert);
      timer(3000).subscribe(this.completeObservable);
    });
  }

  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    this.subscription.unsubscribe();
  }

  RemoveOldestAlert(): void {
    this.removeAlert(this.alerts[0]);
  }

  removeAlert(alert: Alert): void {
    // remove specified alert from array
    this.alerts = this.alerts.filter((x) => x !== alert);
  }

  cssClass(alert: Alert): string | null {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-success';
      case AlertType.Error:
        return 'alert alert-danger';
      case AlertType.Info:
        return 'alert alert-info';
      case AlertType.Warning:
        return 'alert alert-warning';
    }
  }
}
