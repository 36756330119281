import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookieDialogService {
  get cookieDialogExceptedAsObservable(): Observable<boolean> {
    return this.cookieDialogExcepted.asObservable();
  }

  get shouldOpenCookieDialogAsObservable(): Observable<boolean> {
    return this.shouldOpenCookieDialog.asObservable();
  }
  cookieDialogExcepted = new BehaviorSubject<boolean>(true);
  shouldOpenCookieDialog = new BehaviorSubject<boolean>(false);
}
