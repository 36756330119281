import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FormType } from './order.component';
import {
  DslPackageOrderBase,
  MobileOrderBase,
  MobilePackageOrderBase,
  OrderCallTimeframe,
  OrderClient,
  OrderDslDetails,
  OrderPhone,
  OrderRequestDto,
  OrderResponseDto,
  OrderSaveMsisdn,
  SaveOrderService,
} from './order.interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from '../../global/API.constants';
import { FindUserByNicknevResponseDto } from '../personal-site/personal-site.interfaces';
import { tap } from 'rxjs/operators';
import { validateBankAccountNumber } from './order-payment-method/bank-account.validator';
import { CallTimeframe, MsisdnPortingEnum, OrderServiceTypeEnum, PartnerTypeEnum } from '../../global/global.enums';
import { GlobalConstants } from '../../global/global.constants';
import { HttpHeaders } from '@angular/common/http';
import { CookieStorageService } from '../../core/services/cookie-storage-service/cookie-storage.service';
import { SessionStorageService } from '../../core/services/session-storage-service/session-storage.service';
import { XhrService } from '../../core/services/xhr-service/xhr.service';
import { AdCampaignService } from '../../shared/services/ad-campaign/ad-campaign.service';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  personalDataFormType: FormType;
  orderForm: FormGroup;

  isCountryHungary = true;

  isOrderSuccessful = false;
  existingClientId: number;
  phoneNumberUsedForIdentification: string;

  // Returns all characters, except numbers
  onlyNumberRegexp = new RegExp(/\D/g);

  private _paymentForm: FormGroup = new FormGroup({});
  private _otherDetailsForm = new FormGroup({});
  private _simCardActivationForm = new FormGroup({});
  private _dslDetailsForm = new FormGroup({});
  private _personalDataForm: FormGroup = new FormGroup({});
  private _cofidisInstallmentForm: FormGroup = new FormGroup({});
  private _phoneNumberPortForm = new FormGroup(
    {
      newPhoneNumber: new FormControl(true),
      personalData: new FormGroup({}),
      phoneNumberData: new FormGroup({}),
    },
    [this.validatePhoneNumberPort]
  );

  private _selectedPhone: MobileOrderBase;
  private _selectedMobileService: MobilePackageOrderBase;
  private _selectedDslService: DslPackageOrderBase;
  private _orderResponse: OrderResponseDto;
  private _isPaperInvoiceFeeApplied = false;
  private _isExistingClient$ = new BehaviorSubject<boolean>(false);
  private _orderHeaders = new HttpHeaders().set('X-Principal-Name', 'Netfone Web');

  constructor(
    private xhr: XhrService,
    private sessionStorageService: SessionStorageService,
    private fb: FormBuilder,
    private cookieStorageService: CookieStorageService,
    private adCampaignService: AdCampaignService
  ) {
    this.orderForm = this.fb.group(
      {
        personalDataForm: this._personalDataForm,
        phoneNumberPortForm: this._phoneNumberPortForm,
        paymentForm: this._paymentForm,
        otherDetailsForm: this._otherDetailsForm,
        simCardActivationForm: this._simCardActivationForm,
        dslDetails: this._dslDetailsForm,
        cofidisInstallmentForm: this._cofidisInstallmentForm,
      },
      { validators: [validateBankAccountNumber()] }
    );
  }

  get orderHeaders(): HttpHeaders {
    return this._orderHeaders;
  }

  get selectedMobileService(): MobilePackageOrderBase {
    return this._selectedMobileService;
  }

  set selectedMobileService(value: MobilePackageOrderBase) {
    this._selectedMobileService = value;
  }

  get selectedDslService(): DslPackageOrderBase {
    return this._selectedDslService;
  }

  set selectedDslService(value: DslPackageOrderBase) {
    this._selectedDslService = value;
  }

  set selectedPhone(value: MobileOrderBase) {
    this._selectedPhone = value;
  }
  get selectedPhone(): MobileOrderBase {
    return this._selectedPhone;
  }

  get isPaperInvoiceFeeApplied(): boolean {
    return this._isPaperInvoiceFeeApplied;
  }

  set isPaperInvoiceFeeApplied(value: boolean) {
    this._isPaperInvoiceFeeApplied = value;
  }

  get orderResponse(): OrderResponseDto {
    return this._orderResponse;
  }

  set orderResponse(value: OrderResponseDto) {
    this._orderResponse = value;
  }

  get cofidisInstallmentForm(): FormGroup {
    return this.orderForm.get('cofidisInstallmentForm') as FormGroup;
  }

  set cofidisInstallmentForm(form: FormGroup) {
    this.orderForm.setControl('cofidisInstallmentForm', form);
  }

  set simCardActivationForm(form: FormGroup) {
    this.orderForm.setControl('simCardActivationForm', form);
  }

  get simCardActivationForm(): FormGroup {
    return this.orderForm.get('simCardActivationForm') as FormGroup;
  }

  set paymentForm(form: FormGroup) {
    this.orderForm.setControl('paymentForm', form);
  }

  get paymentForm(): FormGroup {
    return this.orderForm.get('paymentForm') as FormGroup;
  }

  get personalDataForm(): FormGroup {
    return this.orderForm.get('personalDataForm') as FormGroup;
  }

  set personalDataForm(form: FormGroup) {
    this.orderForm.setControl('personalDataForm', form);
  }

  get phoneNumberPortForm(): FormGroup {
    return this.orderForm.get('phoneNumberPortForm') as FormGroup;
  }

  set phoneNumberPortForm(form: FormGroup) {
    this.orderForm.setControl('phoneNumberPortForm', form);
  }

  get otherDetailsForm(): FormGroup {
    return this.orderForm.get('otherDetailsForm') as FormGroup;
  }

  set otherDetailsForm(form: FormGroup) {
    this.orderForm.setControl('otherDetailsForm', form);
  }

  get dslDetailsForm(): FormGroup {
    return this.orderForm.get('dslDetails') as FormGroup;
  }

  set dslDetailsForm(form: FormGroup) {
    this.orderForm.setControl('dslDetails', form);
  }

  resetAllSelectedData(): void {
    this.selectedPhone = null;
    this.selectedDslService = null;
    this.selectedMobileService = null;
  }

  changeIsExistingClient(isExistingClient: boolean): void {
    this._isExistingClient$.next(isExistingClient);
  }

  getChangeIsExistingClientAsObservable(): Observable<boolean> {
    return this._isExistingClient$.asObservable().pipe(
      tap((isExisting) => {
        if (isExisting) {
          this.personalDataForm = new FormGroup({});
          this.personalDataFormType = null;
        }
      })
    );
  }

  createOrderObjectWithExistingClient(): OrderRequestDto {
    const orderServiceFromOseRequestDTO: SaveOrderService = this.createOrderMobilePackageDetails();
    const comment = this.createCommentDetails();
    const callbackTimeIntervalRequestDTO = this.createCallTimeframeDetails();
    const partnerRequestDTO: OrderClient = this.createExistingUserPersonalDetails();
    const deviceRequestDTO = this.createPhoneOrderDetails();
    const adCampaignIdentifier = this.getAdCampaignIdentifier();

    return {
      saveOrderServiceRequestDTO: orderServiceFromOseRequestDTO,
      partnerRequestDTO,
      deviceRequestDTO,
      comment,
      callbackTimeIntervalRequestDTO,
      adCampaignIdentifier,
    };
  }

  createOrderObjectOnlyDevice(): OrderRequestDto {
    const orderServiceFromOseRequestDTO: SaveOrderService = null;
    const comment = this.createCommentDetails();
    const callbackTimeIntervalRequestDTO = this.createCallTimeframeDetails();
    const partnerRequestDTO: OrderClient = this.createExistingUserPersonalDetails();
    const deviceRequestDTO = this.createPhoneOrderDetails();
    const adCampaignIdentifier = this.getAdCampaignIdentifier();

    return {
      saveOrderServiceRequestDTO: orderServiceFromOseRequestDTO,
      partnerRequestDTO,
      deviceRequestDTO,
      comment,
      callbackTimeIntervalRequestDTO,
      adCampaignIdentifier,
    };
  }

  createDslOrderObject(): OrderRequestDto {
    const partnerRequestDTO: OrderClient = this.personalDataForm.get('companyName')?.value
      ? this.createLegalClientPersonalDetails()
      : this.createPrivatePersonalClientDetails();

    const deviceRequestDTO: OrderPhone = null;
    const orderServiceFromOseRequestDTO: SaveOrderService = this.createOrderDslPackageDetails();

    const comment = this.createCommentDetails();
    const callbackTimeIntervalRequestDTO = this.createCallTimeframeDetails();
    const resellerId = this.generateRecommender()?.crmAzonosito;
    const adCampaignIdentifier = this.getAdCampaignIdentifier();

    return {
      resellerId,
      adCampaignIdentifier,
      saveOrderServiceRequestDTO: orderServiceFromOseRequestDTO,
      partnerRequestDTO,
      deviceRequestDTO,
      comment,
      callbackTimeIntervalRequestDTO,
    };
  }

  createDslOrderObjectWithExistingClient(): OrderRequestDto {
    const deviceRequestDTO: OrderPhone = null;
    const orderServiceFromOseRequestDTO: SaveOrderService = this.createOrderDslPackageDetails();

    const comment = this.createCommentDetails();
    const callbackTimeIntervalRequestDTO = this.createCallTimeframeDetails();

    const partnerRequestDTO: OrderClient = this.createExistingUserPersonalDetails();
    const adCampaignIdentifier = this.getAdCampaignIdentifier();

    return {
      saveOrderServiceRequestDTO: orderServiceFromOseRequestDTO,
      partnerRequestDTO,
      deviceRequestDTO,
      comment,
      callbackTimeIntervalRequestDTO,
      adCampaignIdentifier,
    };
  }

  generateRecommender(): FindUserByNicknevResponseDto {
    if (this.cookieStorageService.getItem(GlobalConstants.cookieAcceptedKey)) {
      if (this.simCardActivationForm.get('sim.simNumber').value) {
        return this.cookieStorageService.getObject<FindUserByNicknevResponseDto>(
          GlobalConstants.sessionStorageKeys.simRecommender
        );
      } else {
        return this.cookieStorageService.getObject<FindUserByNicknevResponseDto>(
          GlobalConstants.sessionStorageKeys.recommender
        );
      }
    } else {
      if (this.simCardActivationForm.get('sim.simNumber').value) {
        return this.sessionStorageService.getObject<FindUserByNicknevResponseDto>(
          GlobalConstants.sessionStorageKeys.simRecommender
        );
      } else {
        return this.sessionStorageService.getObject<FindUserByNicknevResponseDto>(
          GlobalConstants.sessionStorageKeys.recommender
        );
      }
    }
  }

  createMobileOrderObject(): OrderRequestDto {
    const partnerRequestDTO: OrderClient = this.personalDataForm.get('companyName')?.value
      ? this.createLegalClientPersonalDetails()
      : this.createPrivatePersonalClientDetails();

    const orderServiceFromOseRequestDTO = this.createOrderMobilePackageDetails();
    const deviceRequestDTO: OrderPhone = this.createPhoneOrderDetails();

    const comment = this.createCommentDetails();
    const callbackTimeIntervalRequestDTO = this.createCallTimeframeDetails();
    const resellerId = this.generateRecommender()?.crmAzonosito;
    const adCampaignIdentifier = this.getAdCampaignIdentifier();

    return {
      comment,
      partnerRequestDTO,
      deviceRequestDTO,
      resellerId,
      callbackTimeIntervalRequestDTO,
      saveOrderServiceRequestDTO: orderServiceFromOseRequestDTO,
      adCampaignIdentifier,
    };
  }

  private validatePhoneNumberPort(control: AbstractControl): ValidationErrors | null {
    if (control.get('newPhoneNumber').value) {
      return null;
    }
    const validationResult = control.get('personalData').valid && control.get('phoneNumberData').valid;
    return validationResult ? null : { wrongForm: true };
  }

  private createPrivatePersonalClientDetails(): OrderClient {
    return {
      crmCustomerId: null,
      partnerType: PartnerTypeEnum.PRIVATE_PERSON,
      mothersName: this.personalDataForm.get('mothersName').value,
      placeOfBirth: this.personalDataForm.get('placeOfBirth').value,
      dateOfBirth: this.personalDataForm.get('dateOfBirth')?.value.split('.').join('-'),
      addressZipCode: this.personalDataForm.get('zipCode').value,
      addressCity: this.personalDataForm.get('city').value,
      taxId: null,
      registrationNumber: null,
      contactPerson: null,
      contactPhoneCountryCode: this.getContactPhoneCountryCode(this.personalDataForm),
      contactPhoneAreaCode: this.personalDataForm.get('phoneNumberAreaCode').value.replace(this.onlyNumberRegexp, ''),
      contactPhonePhoneNumber: this.personalDataForm.get('phoneNumber').value.replace(this.onlyNumberRegexp, ''),
      contactEmailAddress: this.personalDataForm.get('email').value,
      billingMethod: this.paymentForm.get('invoiceType').value,
      paymentMethod: this.paymentForm.get('paymentMethod').value,
      surname: this.personalDataForm.get('firstname').value,
      forename: this.personalDataForm.get('lastname').value,
      companyName: null,
      addressPublicSpaceAndHouseNumber: this.personalDataForm.get('publicDomainAndHouseNumber').value,
      phoneNumberUsedForIdentification: null,
    };
  }

  private createLegalClientPersonalDetails(): OrderClient {
    return {
      crmCustomerId: null,
      partnerType: PartnerTypeEnum.LEGAL_ENTITY,
      mothersName: null,
      placeOfBirth: null,
      dateOfBirth: null,
      addressZipCode: this.personalDataForm.get('zipCode').value,
      addressCity: this.personalDataForm.get('city').value,
      taxId: this.personalDataForm.get('taxNumber').value,
      registrationNumber: this.personalDataForm.get('companyRegistrationNumber').value,
      contactPerson: this.personalDataForm.get('legalRepresentativeName').value,
      contactPhoneCountryCode: this.getContactPhoneCountryCode(this.personalDataForm),
      contactPhoneAreaCode: this.personalDataForm.get('phoneNumberAreaCode').value.replace(this.onlyNumberRegexp, ''),
      contactPhonePhoneNumber: this.personalDataForm.get('phoneNumber').value.replace(this.onlyNumberRegexp, ''),
      contactEmailAddress: this.personalDataForm.get('email').value,
      paymentMethod: this.paymentForm.get('paymentMethod').value,
      billingMethod: this.paymentForm.get('invoiceType').value,
      surname: null,
      forename: null,
      companyName: this.personalDataForm.get('companyName').value,
      addressPublicSpaceAndHouseNumber: this.personalDataForm.get('publicDomainAndHouseNumber').value,
      phoneNumberUsedForIdentification: null,
    };
  }

  private createExistingUserPersonalDetails(): OrderClient {
    return {
      crmCustomerId: this.existingClientId,
      partnerType: PartnerTypeEnum.CRM_CUSTOMER,
      mothersName: null,
      placeOfBirth: null,
      dateOfBirth: null,
      addressZipCode: null,
      addressCity: null,
      taxId: null,
      registrationNumber: null,
      contactPerson: null,
      contactPhoneCountryCode: null,
      contactPhoneAreaCode: null,
      contactPhonePhoneNumber: null,
      contactEmailAddress: null,
      paymentMethod: this.paymentForm.get('paymentMethod')?.value,
      billingMethod: this.paymentForm.get('invoiceType')?.value,
      surname: null,
      forename: null,
      companyName: null,
      addressPublicSpaceAndHouseNumber: null,
      phoneNumberUsedForIdentification: this.phoneNumberUsedForIdentification.replace(this.onlyNumberRegexp, ''),
    };
  }

  private createOrderMobilePackageDetails(): SaveOrderService {
    const service: SaveOrderService = {
      crmPackageId: this._selectedPhone?.selectedPhoneService.crmAzonosito
        ? +this._selectedPhone?.selectedPhoneService.crmAzonosito
        : this._selectedMobileService.selectedPackageBaseDetails.crmAzonosito,
      loyaltyMonths:
        this._selectedPhone?.selectedPhoneService.activePricing.husegHonap ??
        this._selectedMobileService.selectedPackagePrices.husegHonap,
      orderServiceType: OrderServiceTypeEnum.MOBILE,
      xdslLineRequestDTO: null,
      saveMsisdnRequestDTO: this.createPhoneNumberPortOrderDetails(),
    };

    return service;
  }

  private createOrderDslPackageDetails(): SaveOrderService {
    return {
      crmPackageId: this._selectedDslService.dslPackage.crmAzonosito,
      loyaltyMonths: this._selectedDslService.dslPackage.szolgaltatasArazas.husegHonap,
      orderServiceType: OrderServiceTypeEnum.XDSL,
      rockmobilBandId: null,
      extraSupport: null,
      saveMsisdnRequestDTO: null,
      xdslLineRequestDTO: this.createDslDetails(),
    };
  }

  private createPhoneOrderDetails(): OrderPhone {
    if (this._selectedMobileService) {
      return null;
    }

    if (this._selectedPhone.installment) {
      return {
        deviceId: +this._selectedPhone.selectedPhoneDetails.keszulekKod,
        devicePricingId: this._selectedPhone.selectedPhoneService.keszulekArazas.keszulekArazasId,
        deviceColourId: this._selectedPhone.selectedPhoneColor.keszulekSzinId,
        deviceInstallmentId: this._selectedPhone.selectedPhoneService.keszulekArazas.keszulekReszletResponseDTO
          .keszulekReszletId,
      };
    }
    return {
      deviceId: +this._selectedPhone.selectedPhoneDetails.keszulekKod,
      devicePricingId: this._selectedPhone.selectedPhoneService.keszulekArazas.keszulekArazasId,
      deviceColourId: this._selectedPhone.selectedPhoneColor.keszulekSzinId,
    };
  }

  private createPhoneNumberPortOrderDetails(): OrderSaveMsisdn {
    if (this.phoneNumberPortForm.get('newPhoneNumber').value) {
      return {
        msisdnPorting: MsisdnPortingEnum.NEW_MSISDN,
        simNumber: this.simCardActivationForm.get('sim.simNumber').value,
        portInCountryCode: null,
        portInAreaCode: null,
        portInPhoneNumber: null,
        currentProvider: null,
        currentServiceType: null,
      };
    }
    return {
      msisdnPorting: MsisdnPortingEnum.PORT_IN,
      simNumber: this.simCardActivationForm.get('sim.simNumber').value,
      portInCountryCode: this.getContactPhoneCountryCode(
        this.phoneNumberPortForm,
        'phoneNumberData.phoneNumberCountry'
      ), // TODO
      portInAreaCode: this.phoneNumberPortForm
        .get('phoneNumberData.phoneNumberAreaCode')
        .value.replace(this.onlyNumberRegexp, ''),
      portInPhoneNumber: this.phoneNumberPortForm
        .get('phoneNumberData.phoneNumber')
        .value.replace(this.onlyNumberRegexp, ''),
      currentProvider: this.phoneNumberPortForm.get('phoneNumberData.currentProvider').value,
      currentServiceType: this.phoneNumberPortForm.get('phoneNumberData.currentPaymentMethod').value,
    };
  }

  private createCommentDetails(): string {
    return this.orderForm.get('otherDetailsForm.comment').value;
  }

  private getContactPhoneCountryCode(form: FormGroup, countryCodeFormControlName = 'phoneNumberCountry'): string {
    return form.get(countryCodeFormControlName).value.match(/^\+.*$/)
      ? form.get(countryCodeFormControlName).value.substring(1)
      : form.get(countryCodeFormControlName).value;
  }

  private createCallTimeframeDetails(): OrderCallTimeframe {
    const selectedCallTimeFrames: CallTimeframe[] = this.orderForm.get('otherDetailsForm.contactTime').value;
    return {
      allDay: selectedCallTimeFrames.includes(CallTimeframe.ALL_DAY),
      morning: selectedCallTimeFrames.includes(CallTimeframe.MORNING),
      noon: selectedCallTimeFrames.includes(CallTimeframe.NOON),
      afternoon: selectedCallTimeFrames.includes(CallTimeframe.AFTERNOON),
      night: selectedCallTimeFrames.includes(CallTimeframe.EVENING),
    };
  }

  private createDslDetails(): OrderDslDetails {
    return {
      ownerAddressZipCode: this.dslDetailsForm.get('zipCode').value,
      ownerAddressCity: this.dslDetailsForm.get('city').value,
      ownerAddressPublicSpace: this.dslDetailsForm.get('publicDomain').value,
      ownerAddressNatureOfPublicSpace: this.dslDetailsForm.get('typeOfPublicDomain').value,
      ownerAddressHouseNumber: this.dslDetailsForm.get('houseNumber').value,
      ownerAddressHouseNumberSupplement: this.dslDetailsForm.get('houseNumberAdditional').value,
    };
  }

  private getAdCampaignIdentifier(): string {
    return this.sessionStorageService.getItem(GlobalConstants.sessionStorageKeys.adCampaignIdentifier);
  }

  order(request: OrderRequestDto): Observable<OrderResponseDto> {
    return this.xhr.post<OrderResponseDto>(API.order, request, { headers: this.orderHeaders }, null, false);
  }
}
