import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  importIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'next',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/next.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'previous',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/previous.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'shopping_cart',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/shopping_cart.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'account',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/account.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'location',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/location.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'assignment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/assignment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'remove',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/remove.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'copy',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/copy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/help.svg')
    );
    this.matIconRegistry.addSvgIcon('add', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/add.svg'));
    this.matIconRegistry.addSvgIcon(
      'view',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/view.svg')
    );
  }
}
