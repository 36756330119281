import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumPipe',
})
export class EnumPipe implements PipeTransform {
  constructor() {
    // EMPTY
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, args?: any): any {
    if (!args) return value;
    return args[value];
  }
}
