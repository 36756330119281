import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { PartnerplusConstants } from '../../../global/partnerplus.constants';
import { MobileServicesService } from '../../mobile-services/mobile-services.service';
import { LandingPageService } from '../../landing-pages/landing-page.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { FindUserByNicknevResponseDto } from '../../personal-site/personal-site.interfaces';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FindAllMobileServiceResponseDto } from '../../mobile-services/mobile-services.interface';

@Injectable({
  providedIn: 'root',
})
export class PartnerPlusResolver implements Resolve<FindAllMobileServiceResponseDto[]> {
  unregisteredUsers: FindUserByNicknevResponseDto[] = PartnerplusConstants;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mobileServicesService: MobileServicesService,
    @Inject(PLATFORM_ID) private platformId: object,
    private landingPageService: LandingPageService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<FindAllMobileServiceResponseDto[]>
    | Promise<FindAllMobileServiceResponseDto[]>
    | FindAllMobileServiceResponseDto[] {
    if (isPlatformServer(this.platformId)) {
      return [];
    }
    const nicknev = route.paramMap.get('username')?.trim().toLocaleLowerCase();
    const crmIdentifier = route.paramMap.get('crmIdentifier')?.trim().toLocaleLowerCase();
    const unregisteredUser: FindUserByNicknevResponseDto = this.unregisteredUsers.find(
      (user) => user.nicknev === nicknev
    );
    return this.mobileServicesService.findAllMobileService().pipe(
      tap((response) => {
        const selectedPackage = response.find((item) => +item.crmAzonosito === +crmIdentifier);
        if (unregisteredUser && selectedPackage) {
          if (isPlatformBrowser(this.platformId)) {
            this.landingPageService.setRecommender(unregisteredUser);
            this.mobileServicesService.updateOrderObjectWithSelectedMobileService(selectedPackage);
          }
        } else if (unregisteredUser) {
          if (isPlatformBrowser(this.platformId)) {
            this.landingPageService.setRecommender(unregisteredUser);
            this.router.navigate(['mobil-szolgaltatas'], { relativeTo: null });
          }
        } else {
          this.router.navigate(['/404'], { relativeTo: null, queryParams: null });
        }
      })
    );
  }
}
