<div
  class="svg-container"
  [class]="{
    'dark-gray': type === 'dark-gray',
    gray: type === 'gray',
    'light-gray': type === 'light-gray',
    orange: type === 'orange',
    tan: type === 'tan',
    white: type === 'white',
    'orange-white': type === 'orange-white',
    'red-black': type === 'red-black',
    black: type === 'black',
    'orange-black-55': type === 'orange-black-55',
    'orange-black-0': type === 'orange-black-0'
  }"
>
  <ng-content></ng-content>
  <img class="svg" [src]="imageSrc" alt="" />
</div>
