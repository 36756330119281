export interface IMobilPackageCardCustomIcon {
  url: string;
  position?: {
    left?: number;
    right?: number;
  };
  size?: {
    width?: number;
    height?: number;
  };
}

export interface IMobilePackageCustomIconStyle {
  width?: string;
  height?: string;
  position?: string;
  left?: string;
  top?: string;
}

export interface MobilePackageDetails {
  size: string;
  pillConfiguration?: {
    row1?: {
      text: string;
      color: string;
    };
    row2?: {
      text: string;
      color: string;
    };
    row3?: {
      text: string;
      color: string;
    };
    sccClass?: string;
  };
  customIcons?: {
    firstIcon?: string | IMobilPackageCardCustomIcon;
    secondIcon?: string | IMobilPackageCardCustomIcon;
  };
  noInternet?: boolean;
  unlimitedInternet?: boolean;
  price: number;
  details: string;
  extra50gb?: boolean;
  summerSale?: boolean;
  extraDetail?: string;
  extraDetailInline?: boolean;
  isExtraDetailTransparent?: boolean;
  packageDisplayType: MobilePackageDisplayType;
  packageDisplayTypePill?: MobilePackageDisplayType;
  displayVat?: boolean;
  priceText?: string;
  crmIdentifier?: string;
  view?: boolean;
  loyaltyMonth: number;
  newPackageDisplayMode?: boolean;
  hundredMinutes?: boolean;
  custom?: boolean;
  isBarlow?: boolean;
  hideIcons?: boolean;
}

export enum MobilePackageDisplayType {
  WHITE_BASE = 'whiteBase',
  WHITE_BASE_WITH_ORANGE = 'whiteBaseWithOrange',
  WHITE_BASE_WITH_ORANGE_NO_BACKGROUND = 'whiteBaseWithOrangeNoBackground',
  GREY_BASE = 'greyBase',
  GREY_BASE_WITH_ORANGE = 'greyBaseWithOrange',
  ORANGE_BASE = 'orangeBase',
  ORANGE_BASE_WITH_GREY = 'orangeBaseWithGrey',
  ORANGE_BASE_WITH_BLACK = 'orangeBaseWithBlack',
  PURPLE_BASE = 'purpleBase',
  PURPLE_BASE_WITH_ORANGE = 'purpleBaseWithOrange',
  TURQUOISE_BASE = 'turquoiseBase',
  TURQUOISE_BASE_WITH_ORANGE = 'turquoiseBaseWithOrange',
  RED_BASED = 'redBased',
  RED_BASED_WITH_WHITE = 'redBasedWithWhite',
}
