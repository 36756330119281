import { Component, Input, OnInit } from '@angular/core';
import {
  FindAllMobileServiceResponseDto,
  IMobileServiceDropdownLabel,
  IMobileServiceResolverData,
} from '../../../modules/mobile-services/mobile-services.interface';
import { FormControl } from '@angular/forms';
import {
  ISelectedMobileServiceDetails,
  ISmallMobileServiceCardData,
} from '../../interfaces/mobile-packages-table.interfaces';
import { MobilePackagesDisplayTypeEnum, SegmentTypesEnum } from '../../../global/global.enums';
import { MobilePackageDetails } from '../large-mobile-package/large-mobile-package.interfaces';
import { Observable } from 'rxjs';
import { API } from '../../../global/API.constants';
import { MobileServicesService } from '../../../modules/mobile-services/mobile-services.service';
import { XhrService } from '../../../core/services/xhr-service/xhr.service';
import { Router } from '@angular/router';
import { PackageType } from '../../../modules/devices/phone.interfaces';

@Component({
  selector: 'ose-all-packages',
  templateUrl: './all-packages.component.html',
  styleUrls: ['./all-packages.component.scss'],
})
export class AllPackagesComponent implements OnInit {
  @Input() data!: IMobileServiceResolverData;
  @Input() backgroundColor = 'orange';
  @Input() withTitle = true;

  // Üzleti felirat
  hundredMinutesBusinessSubtitle = '100 perc beszélgetés belföldön\n' + 'További percdíj 24 Ft';

  // Lakossági felirat
  hundredMinutesResidentialSubtitle = '100 perc beszélgetés belföldön\n' + 'További percdíj 19 Ft';

  loyaltySelector = new FormControl();
  availableLoyaltyTimesDropdown: IMobileServiceDropdownLabel[] = [];
  dropdownSelectedData: ISelectedMobileServiceDetails = {
    selectedLoyaltyTime: undefined,
    selectedSegmentType: undefined,
  };
  serviceListingData: ISmallMobileServiceCardData = {
    unlimited: {
      packages: undefined,
      headerData: {
        title: 'Korlátlan',
        subtitle: 'Korlátlan beszélgetés belföldön',
      },
    },
    hundredMinutes: {
      packages: undefined,
      headerData: {
        title: '100 perc',
        subtitle: '',
      },
    },
    euUnlimited: {
      packages: undefined,
      headerData: {
        title: 'EU Korlátlan',
        subtitle: 'Korlátlan beszélgetés belföldön és az EU országokirányában',
      },
    },
  };
  mobilePackagesTableDisplayTypeEnum = MobilePackagesDisplayTypeEnum;
  MobilePackagesDisplayTypeEnum = MobilePackagesDisplayTypeEnum;

  constructor(private mobileServicesService: MobileServicesService, private xhr: XhrService, private router: Router) {}

  ngOnInit(): void {
    if (this.data) {
      this.data.availableList.forEach((item) => {
        this.availableLoyaltyTimesDropdown.push({
          name: `${item.szegmens === SegmentTypesEnum.LAKOSSAGI ? 'Lakossági' : 'Üzleti'} ${
            item.husegHonap === 0 ? 'hűség nélkül' : `${item.husegHonap} hónap hűséggel`
          }`,
          value: Object.values(item).join('_'),
          originalValue: item,
        });
        this.filterServiceList(this.data.services);
      });
    }
    this.loyaltySelector.setValue(
      this.availableLoyaltyTimesDropdown.find(
        (item) => item.originalValue.szegmens === SegmentTypesEnum.LAKOSSAGI && item.originalValue.husegHonap === 10
      )?.value ?? this.availableLoyaltyTimesDropdown[0].value
    );

    // Lakossági felirat beállítása
    this.serviceListingData.hundredMinutes.headerData.subtitle = this.hundredMinutesResidentialSubtitle;

    this.dropdownSelectedData = {
      selectedSegmentType: this.availableLoyaltyTimesDropdown.find((item) => item.value === this.loyaltySelector.value)
        .originalValue.szegmens,
      selectedLoyaltyTime: this.availableLoyaltyTimesDropdown.find((item) => item.value === this.loyaltySelector.value)
        .originalValue.husegHonap,
    };

    this.loyaltySelector.valueChanges.subscribe((newVal) => {
      this.dropdownSelectedData = {
        selectedSegmentType: this.availableLoyaltyTimesDropdown.find((item) => item.value === newVal).originalValue
          .szegmens,
        selectedLoyaltyTime: this.availableLoyaltyTimesDropdown.find((item) => item.value === newVal).originalValue
          .husegHonap,
      };
      this.mapData();
    });
  }

  order(packageItem: MobilePackageDetails): void {
    this.mobileServicesService.order(packageItem);
  }
  private getAvailableServices(request: ISelectedMobileServiceDetails): Observable<FindAllMobileServiceResponseDto[]> {
    return this.xhr.get(API.services.mobile, {
      params: {
        husegHonap: request.selectedLoyaltyTime,
        szegmens: request.selectedSegmentType,
      },
    });
  }

  private mapData(): void {
    this.getAvailableServices(this.dropdownSelectedData).subscribe((services: FindAllMobileServiceResponseDto[]) => {
      this.serviceListingData.hundredMinutes.headerData.subtitle =
        this.dropdownSelectedData.selectedSegmentType === SegmentTypesEnum.LAKOSSAGI
          ? this.hundredMinutesResidentialSubtitle
          : this.hundredMinutesBusinessSubtitle;
      this.filterServiceList(services);
    });
  }

  private filterServiceList(services: FindAllMobileServiceResponseDto[]): void {
    this.serviceListingData.unlimited.packages = this.filterServiceListByProperty(services, 'BELFOLDI_KORLATLAN');
    this.serviceListingData.hundredMinutes.packages = this.filterServiceListByProperty(services, 'SZAZ_PERCES');
    this.serviceListingData.euUnlimited.packages = this.filterServiceListByProperty(services, 'EU_KORLATLAN');
  }

  private filterServiceListByProperty(services: FindAllMobileServiceResponseDto[], packageType: PackageType): any {
    return services
      .filter((item) => item.arTipus === packageType)
      .sort((a, b) => {
        return parseInt(b.bennfoglaltNet.replace(/\D/g, '')) - parseInt(a.bennfoglaltNet.replace(/\D/g, ''));
      });
  }

  withDevice(): void {
    this.router.navigate(['/keszulekek'], { relativeTo: null });
  }
}
