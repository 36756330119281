import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlShortenerService } from '../../services/url-shortener/url-shortener.service';
import { LandingPageService } from '../../../modules/landing-pages/landing-page.service';

@Injectable({
  providedIn: 'root',
})
export class ShortUrlGuard implements CanActivate {
  constructor(
    private router: Router,
    private urlShortenerService: UrlShortenerService,
    private landingPageService: LandingPageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const shortUrl = route.paramMap.get('shortUrl')?.trim()?.toLocaleLowerCase();
    if (!shortUrl) {
      return false;
    }
    this.urlShortenerService.findByShortUlr({ shortUrl }).subscribe((response) => {
      const paramsObject: Params = {};
      const splittedUrl = response.longUrl.split('?');
      const recommender = response.recommender;

      const baseUrl = splittedUrl[0];
      const queryParams = splittedUrl[1];

      if (queryParams) {
        queryParams.split('&').forEach((param) => {
          const paramArray = param.split('=');
          if (paramsObject[paramArray[0]]) {
            paramsObject[paramArray[0]] = paramsObject[paramArray[0]].concat(',', paramArray[1]);
          } else {
            paramsObject[paramArray[0]] = paramArray[1];
          }
        });
      }

      if (recommender) {
        this.landingPageService.setRecommender(recommender);
      }
      this.router.navigate([baseUrl], { relativeTo: null, queryParams: paramsObject });
      return true;
    });
  }
}
