import { KeycloakOptions } from 'keycloak-angular';
import { unsecured } from '../app/global/API.constants';

const keycloakConfig: KeycloakOptions = {
  bearerPrefix: 'Bearer',
  initOptions: {
    onLoad: 'check-sso',
    checkLoginIframe: false,
  },
  bearerExcludedUrls: [`/api/v1${unsecured}`],
};

export const environment = {
  production: true,
  keycloak: keycloakConfig,
};
