<ng-container [ngSwitch]="type">
  <div
    (openChange)="dropdownStatusChange($event)"
    class="filter-dropdown ntfc-order-by-wrapper"
    ngbDropdown
    placement="bottom"
    id="dropdownSorterSelector"
    *ngSwitchCase="'sort'"
  >
    <button
      [class]="{ 'ntfc-order-by-dropdown-toggle-opened': opened }"
      class="ntfc-order-by-dropdown-toggle"
      ngbDropdownToggle
      [disabled]="isDisabled"
    >
      <img alt="" src="../../../../assets/images/sort_by_icon.svg" />
      {{ selectedLabel.name }}
    </button>
    <div class="ntfc-order-by-dropdown-data" ngbDropdownMenu>
      <button (click)="onClickDropdownItem(label)" *ngFor="let label of labelList" ngbDropdownItem>
        {{ label.name }}
      </button>
    </div>
  </div>
  <div
    class="ntfc-selector-dropdown-100"
    ngbDropdown
    placement="bottom"
    id="dropdownFullWidthSelector"
    [class]="{ 'unset-background': selectedLabel.value }"
    *ngSwitchCase="'dropdown-full-width'"
  >
    <button
      class="ntfc-selector-dropdown-100 padding-10"
      ngbDropdownToggle
      [disabled]="isDisabled"
      [class]="{
        'default-dropdown-item-color': !selectedLabel.value,
        'ntfc-form-control-success': selectedLabel.value
      }"
    >
      {{ selectedLabel.name }}
    </button>
    <div class="ntfc-selector-option" ngbDropdownMenu>
      <button (click)="onClickDropdownItem(label)" *ngFor="let label of labelList" ngbDropdownItem>
        {{ label.name }}
      </button>
    </div>
  </div>
  <div
    (openChange)="dropdownStatusChange($event)"
    class="ntfc-new-dropdown-wrapper"
    ngbDropdown
    placement="bottom"
    id="dropdownSelector"
    *ngSwitchCase="'dropdown'"
  >
    <button
      [class]="{ 'ntfc-new-dropdown-toggle-opened': opened }"
      class="ntfc-new-dropdown-toggle"
      ngbDropdownToggle
      [disabled]="isDisabled"
    >
      {{ selectedLabel.name }}
    </button>
    <div class="ntfc-new-dropdown-toggle-data" ngbDropdownMenu>
      <button (click)="onClickDropdownItem(label)" *ngFor="let label of labelList" ngbDropdownItem>
        {{ label.name }}
      </button>
    </div>
  </div>
</ng-container>
