import { Component, Input, ViewChild } from '@angular/core';
import { ShareDialogComponent } from '../../dialogs/share-dialog/share-dialog.component';

@Component({
  selector: 'ose-global-share-button',
  templateUrl: './global-share-button.component.html',
  styleUrls: ['./global-share-button.component.scss'],
})
export class GlobalShareButtonComponent {
  @Input() shareText: string;
  @Input() shareClass: string;
  @ViewChild(ShareDialogComponent) shareDialogComponent: ShareDialogComponent;

  constructor() {}

  share(): void {
    this.shareDialogComponent.open();
  }
}
