import { CookieService } from 'ngx-cookie-service';
import { EnumPipe } from './pipes/enum-pipe/enum.pipe';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDropdownModule,
  NgbModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ColleagueCarouselComponent } from './components/colleague-carousel/colleague-carousel.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { DynamicPipe } from './pipes/dynamic-pipe/dynamic.pipe';
import { UnitPipe } from './pipes/unit-pipe/unit.pipe';
import { ChipComponent } from './components/chip/chip.component';
import { DslPackageComponent } from './components/dsl-package/dsl-package.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NewMobileServiceChooserDialogComponent } from './dialogs/new-mobile-service-chooser-dialog/new-mobile-service-chooser-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { CookieDialogComponent } from './dialogs/cookie-dialog/cookie-dialog.component';
import { MismatchSimDialogComponent } from './dialogs/mismatch-sim-dialog/mismatch-sim-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { LargeMobilePackageComponent } from './components/large-mobile-package/large-mobile-package.component';
import { ResellerShopCardComponent } from './components/reseller-map/reseller-shop-card/reseller-shop-card.component';
import { EndlessLineComponent } from './components/endless-line/endless-line.component';
import { NgModule } from '@angular/core';
import { DeliveryDatePipe } from './pipes/delivery-date-pipe/delivery-date.pipe';
import { ServiceChooserComponent } from './components/service-chooser/service-chooser.component';
import { MonthlyPaymentDialogComponent } from './dialogs/monthly-payment-dialog/monthly-payment-dialog.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { ResellerUserCardComponent } from './components/reseller-map/reseller-user-card/reseller-user-card.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GlobalShareButtonComponent } from './components/global-share-button/global-share-button.component';
import { ShareDialogComponent } from './dialogs/share-dialog/share-dialog.component';
import { TermComponent } from './components/term/term.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MaterialModule } from './material/material-module';
import { ContactUsFormComponent } from './components/contact-us-form/contact-us-form.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { PersonIdentifyDialogComponent } from './dialogs/person-identify-dialog/person-identify-dialog.component';
import { ResellerMapComponent } from './components/reseller-map/reseller-map.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StepperComponent } from './components/stepper/stepper.component';
import { SliderComponent } from './components/slider/slider.component';
import { GlobalShareRibbonComponent } from './components/global-share-ribbon/global-share-ribbon.component';
import { PillComponent } from './components/pill/pill.component';
import { GreyRibbonWithShareButtonComponent } from './components/grey-ribbon-with-share-button/grey-ribbon-with-share-button.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { MobilePackageCardComponent } from './components/mobile-package-card/mobile-package-card.component';
import { MobilePackagesTableComponent } from './components/mobile-packages-table/mobile-packages-table.component';
import { PackageTypePipe } from './pipes/package-type-pipe/package-type.pipe';
import { SwiperModule } from 'swiper/angular';
import { AllPackagesComponent } from './components/all-packages/all-packages.component';
import { WINDOW, WindowService } from './services/window/window.service';
import { PhoneNumberFormatPipe } from './pipes/phone-number-pipe/phone-number-format.pipe';
import { NgVarDirective } from './directives/ng-var-directive/ng-var.directive';

export function factoryFn(windowService: WindowService): Window {
  return windowService.nativeWindow;
}

@NgModule({
  declarations: [
    DynamicPipe,
    EnumPipe,
    UnitPipe,
    ConfirmDialogComponent,
    NotificationComponent,
    LoadingComponent,
    StepperComponent,
    CookieDialogComponent,
    MismatchSimDialogComponent,
    ServiceChooserComponent,
    PersonIdentifyDialogComponent,
    ShareDialogComponent,
    EndlessLineComponent,
    CheckboxComponent,
    ChipComponent,
    ColleagueCarouselComponent,
    DropdownComponent,
    DslPackageComponent,
    MonthlyPaymentDialogComponent,
    GlobalShareButtonComponent,
    ScrollToTopComponent,
    ContactUsFormComponent,
    ResellerMapComponent,
    ResellerUserCardComponent,
    ResellerShopCardComponent,
    SliderComponent,
    ColorPickerComponent,
    NewMobileServiceChooserDialogComponent,
    TermComponent,
    DeliveryDatePipe,
    GlobalShareRibbonComponent,
    PillComponent,
    GreyRibbonWithShareButtonComponent,
    ErrorDialogComponent,
    LargeMobilePackageComponent,
    MobilePackageCardComponent,
    MobilePackagesTableComponent,
    PackageTypePipe,
    AllPackagesComponent,
    PhoneNumberFormatPipe,
    NgVarDirective,
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxMaskModule.forChild(),
    RecaptchaModule,
    NgxSliderModule,
    NgbModule,
    PerfectScrollbarModule,
    GoogleMapsModule,
    RecaptchaFormsModule,
    SwiperModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    DynamicPipe,
    EnumPipe,
    UnitPipe,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTooltipModule,
    ConfirmDialogComponent,
    NgxMaskModule,
    NotificationComponent,
    LoadingComponent,
    StepperComponent,
    CookieDialogComponent,
    MismatchSimDialogComponent,
    ServiceChooserComponent,
    PersonIdentifyDialogComponent,
    ShareDialogComponent,
    EndlessLineComponent,
    CheckboxComponent,
    ChipComponent,
    ColleagueCarouselComponent,
    DropdownComponent,
    DslPackageComponent,
    MonthlyPaymentDialogComponent,
    GlobalShareButtonComponent,
    ScrollToTopComponent,
    ContactUsFormComponent,
    ResellerMapComponent,
    ResellerUserCardComponent,
    ResellerShopCardComponent,
    SliderComponent,
    ColorPickerComponent,
    NewMobileServiceChooserDialogComponent,
    TermComponent,
    DeliveryDatePipe,
    GlobalShareRibbonComponent,
    PillComponent,
    GreyRibbonWithShareButtonComponent,
    ErrorDialogComponent,
    LargeMobilePackageComponent,
    MobilePackageCardComponent,
    MobilePackagesTableComponent,
    PackageTypePipe,
    SwiperModule,
    AllPackagesComponent,
    PhoneNumberFormatPipe,
    NgVarDirective,
  ],
  providers: [
    EnumPipe,
    UnitPipe,
    CookieService,
    WindowService,
    { provide: WINDOW, useFactory: factoryFn, deps: [WindowService] },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    CurrencyPipe,
    DeliveryDatePipe,
  ],
})
export class SharedModule {}
