import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingService } from './core/services/loading-service/loading.service';
import { Subject } from 'rxjs';
import { SEOService } from './core/services/seo-service/seo.service';
import { filter, map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { EnvironmentVariablesService } from './core/services/environment-variable-service/environment-variables.service';
import { ExposedEnvironmentVariable } from '../environments/environment-variables';
import { AuthService } from './core/services/auth-service/auth.service';
import { CookieStorageService } from './core/services/cookie-storage-service/cookie-storage.service';
import { GlobalConstants } from './global/global.constants';
import { CustomIconService } from './core/services/custom-icon-service/custom-icon.service';
import { CookieDialogService } from './shared/services/cookie-dialog/cookie-dialog.service';
import { WINDOW } from './shared/services/window/window.service';
import { JsonLdService } from './shared/services/json-ld/json-ld.service';
import { AdCampaignService } from './shared/services/ad-campaign/ad-campaign.service';

export const env = environment;
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;
// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading = false;
  loadingStatus = false;
  showCookieDisclaimer: boolean;

  private _destroy$ = new Subject<void>();
  private previousUrl: string;

  constructor(
    public loadingService: LoadingService,
    public seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private environmentVariablesService: EnvironmentVariablesService,
    private authService: AuthService,
    @Inject(WINDOW) private window: Window,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformID: object,
    private cookieStorageService: CookieStorageService,
    private router: Router,
    private iconService: CustomIconService,
    private cookieDialogService: CookieDialogService,
    private jsonLdService: JsonLdService,
    private adCampaignService: AdCampaignService
  ) {}

  ngOnInit(): void {
    // ha az oldalon valahol máshol kell megnyitni a süti szabályzatot
    this.cookieDialogService.shouldOpenCookieDialogAsObservable.pipe(takeUntil(this._destroy$)).subscribe((newVal) => {
      if (newVal) {
        this.shouldShowCookieDialog();
      }
    });

    this.iconService.importIcons();
    this.router.events.pipe(takeUntil(this._destroy$)).subscribe((event) => {
      // wait for the navigation end
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      if (isPlatformBrowser(this.platformID)) {
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((queryParams) => {
          if (
            // ha nem keszülékről megyünk nem készülékre
            (!(this.previousUrl?.includes('/keszulekek/') && this.router.url.includes('/keszulekek/')) ||
              // ha készülékről megyünk nem készülékre
              (this.previousUrl?.includes('/keszulekek/') && !this.router.url.includes('/keszulekek/'))) &&
            queryParams['scroll'] !== 'false'
          ) {
            this.window.scroll(0, 0);
          }
          this.previousUrl = this.router.url;

          this.adCampaignService.setAdCampaign(queryParams[GlobalConstants.adCampaignIdentifier]);
        });

        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
        fbq('track', 'PageView', { page: event.urlAfterRedirects });
      }
    });
    this.loadingService
      .getLoadingSubject()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        this.isLoading = result;
      });

    this.authService
      .getAuthLoadingAsObservable()
      .pipe(takeUntil(this._destroy$))
      .subscribe((loadingStatus) => {
        this.loadingStatus = loadingStatus;
      });

    // DYNAMIC TITLE AND DESCRIPTION GENERATOR
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe(
        (event: {
          cookieDisclaimer: boolean;
          setMetaByDefault: boolean;
          setDefaultJsonLd: boolean;
          meta_title: string;
          meta_description: string;
          meta_keywords: string;
          meta_author: string;
          og_title: string;
          og_description: string;
          og_image: string;
          og_image_type: string;
          og_image_width: string;
          og_image_height: string;
        }) => {
          //SHOULD_CHECK_COOKIE
          if (event.cookieDisclaimer && !this.showCookieDisclaimer) {
            this.shouldShowCookieDialog();
          }

          // SETTING THE DEFAULT JSON LD DATA
          if (event.setDefaultJsonLd) {
            this.jsonLdService.addOrUpdateStructuredData(JsonLdService.orgSchema());
          }

          if (event.setMetaByDefault) {
            //NORMAL META
            this.seoService.updateTitle(event.meta_title);
            this.seoService.updateDescription(event.meta_description);
            this.seoService.updateMetaTag('keywords', event.meta_keywords);
            this.seoService.updateMetaTag('author', event.meta_author);
            //OG
            this.seoService.updateMetaTag('og:title', event.og_title);
            this.seoService.updateMetaTag('og:description', event.og_description);
            this.seoService.updateMetaTag(
              'og:image',
              `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL)}/assets/og/${
                event.og_image
              }`
            );
            this.seoService.updateMetaTag('og:image:type', event.og_image_type);
            this.seoService.updateMetaTag('og:image:width', event.og_image_width);
            this.seoService.updateMetaTag('og:image:height', event.og_image_height);
            this.seoService.updateMetaTag(
              'og:url',
              `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL)}/${this.router.url}`
            );
          }
        }
      );
  }

  cookieAccepted(event: string): void {
    this.showCookieDisclaimer = false;
    this.cookieDialogService.cookieDialogExcepted.next(true);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // Megnézzük, hogy már elfogadta-e a szüti szabályzatot. Ha nem, akkor feldobjuk neki.
  private shouldShowCookieDialog(): void {
    if (!this.cookieStorageService.getItem(GlobalConstants.cookieAcceptedKey)) {
      this.showCookieDisclaimer = true;
      this.cookieDialogService.cookieDialogExcepted.next(false);
    }
  }
}
