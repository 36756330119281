import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreModule } from '../core.module';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../services/auth-service/auth.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap, tap } from 'rxjs/operators';
import { KEYCLOAK } from '../injection-token/keycloak-token';
import { NotificationService } from '../../shared/components/notification/notification.service';

@Injectable({
  providedIn: CoreModule,
})
export class AuthGuard implements CanLoad {
  private authenticated: boolean;
  private roles: string[];

  constructor(
    protected router: Router,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: object,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    this.authService.sendAuthLoadingEvent(true);
    if (isPlatformBrowser(this.platformId)) {
      if (!this.authService.initCalled) {
        return new Promise((resolve, reject) => {
          this.authService.initKeycloak().then((result) => {
            if (result.isSuccessful) {
              const keycloakService = this.injector.get(KEYCLOAK);
              try {
                fromPromise(keycloakService.isLoggedIn())
                  .pipe(
                    tap((isLoggedIn) => (this.authenticated = isLoggedIn)),
                    switchMap(() => fromPromise(this.isAccessAllowed()))
                  )
                  .subscribe((isAccessAllowed) => {
                    this.roles = keycloakService.getUserRoles(true);
                    this.authService.sendAuthLoadingEvent(false);
                    resolve(isAccessAllowed);
                  });
              } catch (error) {
                reject('An error happened during access validation. Details:' + error);
              }
            } else {
              this.authService.sendAuthLoadingEvent(false);
              this.router.navigate(['/'], { relativeTo: null });
              setTimeout(() => {
                this.notificationService.error('Az authentikációs szerver nem elérhető! Kérjük próbálkozzon később!');
              });
              reject();
            }
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          const keycloakService = this.injector.get(KEYCLOAK);
          try {
            fromPromise(keycloakService.isLoggedIn())
              .pipe(
                tap((isLoggedIn) => (this.authenticated = isLoggedIn)),
                switchMap(() => fromPromise(this.isAccessAllowed()))
              )
              .subscribe((isAccessAllowed) => {
                this.roles = keycloakService.getUserRoles(true);
                this.authService.sendAuthLoadingEvent(false);
                resolve(isAccessAllowed);
              });
          } catch (error) {
            reject('An error happened during access validation. Details:' + error);
            this.authService.sendAuthLoadingEvent(false);
          }
        });
      }
    } else {
      this.router.navigate(['/'], { relativeTo: null, skipLocationChange: true });
      return false;
    }
  }

  // canActivateChild(
  //   childRoute: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const keycloakService = this.injector.get(KEYCLOAK);
  //     // eslint-disable-next-line no-async-promise-executor
  //     return new Promise((resolve, reject) => {
  //       combineLatest([this._helperSubject$, this.authService.getInitCalledAsObservable()])
  //         .pipe(filter(([tmp, initCalled]) => initCalled))
  //         .subscribe(() => {
  //           try {
  //             fromPromise(keycloakService.isLoggedIn())
  //               .pipe(
  //                 tap((isLoggedIn) => (this.authenticated = isLoggedIn)),
  //                 switchMap(() => fromPromise(this.isAccessAllowed()))
  //               )
  //               .subscribe((isAccessAllowed) => {
  //                 this.roles = keycloakService.getUserRoles(true);
  //                 resolve(isAccessAllowed);
  //               });
  //           } catch (error) {
  //             reject('An error happened during access validation. Details:' + error);
  //           }
  //         });
  //     });
  //   } else {
  //     this.router.navigate(['']);
  //     return false;
  //   }
  // }

  isAccessAllowed(): Promise<boolean> {
    const keycloakService = this.injector.get(KEYCLOAK);
    return new Promise((resolve, reject) => {
      if (!this.authenticated) {
        keycloakService
          .login()
          .then(() => {
            resolve(true);
          })
          .catch((e) => {
            reject(false);
          });
      }
      return resolve(true);
    });
  }
}
