import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor() {
    // EMPTY
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = req.clone({ setHeaders: { 'X-Relation-Id': '1' } });

    return next.handle(clonedReq);
  }
}
