import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let fbq: Function;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  public eventEmitter(eventType: 'track' | 'trackCustom', eventName: string, extraData?: object): void {
    fbq(eventType, eventName, extraData);
  }
}
