import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import {
  IMobilePackageCustomIconStyle,
  IMobilPackageCardCustomIcon,
  MobilePackageDetails,
  MobilePackageDisplayType,
} from './large-mobile-package.interfaces';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ose-large-mobile-package',
  templateUrl: './large-mobile-package.component.html',
  styleUrls: ['./large-mobile-package.component.scss'],
})
export class LargeMobilePackageComponent implements OnInit {
  @Input() packageDetails: MobilePackageDetails;
  @Output() buttonClicked = new EventEmitter<MobilePackageDetails>();
  @Input() displayAsPillOnMobile: boolean;

  packageDisplayType = MobilePackageDisplayType;
  imageSrc: string;
  unlimitedIconSrc: string;

  innerWidth: number;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    switch (this.packageDetails.packageDisplayType) {
      case MobilePackageDisplayType.WHITE_BASE:
        this.imageSrc = this.getImageSrc('grey_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_grey.svg');
        break;
      case MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE:
        this.imageSrc = this.getImageSrc('orange_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_grey.svg');
        break;
      case MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND:
        this.imageSrc = this.getImageSrc('orange_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_grey.svg');
        break;
      case MobilePackageDisplayType.GREY_BASE:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_grey.svg');
        break;
      case MobilePackageDisplayType.ORANGE_BASE:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.ORANGE_BASE_WITH_GREY:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.ORANGE_BASE_WITH_BLACK:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.GREY_BASE_WITH_ORANGE:
        this.imageSrc = this.getImageSrc('orange_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_grey.svg');
        break;
      case MobilePackageDisplayType.PURPLE_BASE:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.PURPLE_BASE_WITH_ORANGE:
        this.imageSrc = this.getImageSrc('orange_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.TURQUOISE_BASE:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.TURQUOISE_BASE_WITH_ORANGE:
        this.imageSrc = this.getImageSrc('orange_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.RED_BASED:
        this.imageSrc = this.getImageSrc('dark_grey_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
      case MobilePackageDisplayType.RED_BASED_WITH_WHITE:
        this.imageSrc = this.getImageSrc('white_small_linear_rectangle.svg');
        this.unlimitedIconSrc = this.getImageSrc('infinity_white.svg');
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = (event.target as Window).innerWidth;
  }

  onClickPackageButton(): void {
    this.buttonClicked.emit(this.packageDetails);
  }

  getPackageDisplayTypeForPill(packageDetails: MobilePackageDetails): string {
    const finalPackageDisplayType: MobilePackageDisplayType = packageDetails.packageDisplayTypePill
      ? packageDetails.packageDisplayTypePill
      : packageDetails.packageDisplayType;

    switch (finalPackageDisplayType) {
      case MobilePackageDisplayType.WHITE_BASE:
        return 'white-base';
      case MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE:
        return 'white-base-with-orange';
      case MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND:
        return 'white-base-with-orange-no-background';
      case MobilePackageDisplayType.ORANGE_BASE:
        return 'orange-base';
      case MobilePackageDisplayType.ORANGE_BASE_WITH_GREY:
        return 'orange-base-with-grey';
      case MobilePackageDisplayType.ORANGE_BASE_WITH_BLACK:
        return 'orange-base-with-black';
      case MobilePackageDisplayType.GREY_BASE:
        return 'grey-base';
      case MobilePackageDisplayType.GREY_BASE_WITH_ORANGE:
        return 'grey-base-with-orange';
      case MobilePackageDisplayType.PURPLE_BASE:
        return 'purple-base';
      case MobilePackageDisplayType.PURPLE_BASE_WITH_ORANGE:
        return 'purple-base-with-orange';
      case MobilePackageDisplayType.TURQUOISE_BASE:
        return 'turquoise-base';
      case MobilePackageDisplayType.TURQUOISE_BASE_WITH_ORANGE:
        return 'turquoise-base-with-orange';
      case MobilePackageDisplayType.RED_BASED:
        return 'red-base';
      case MobilePackageDisplayType.RED_BASED_WITH_WHITE:
        return 'red-base-with-white';
    }
  }

  getCustomIconSrc(icon: string | IMobilPackageCardCustomIcon): string {
    if (typeof icon === 'string') {
      return icon;
    }
    return icon.url;
  }

  getCustomIconStyles(icon: string | IMobilPackageCardCustomIcon): Object {
    if (typeof icon === 'string') {
      return null;
    }
    const style: IMobilePackageCustomIconStyle = {};
    if (icon.size) {
      style.width = `${icon.size.width}px`;
      style.height = `${icon.size.height}px`;
    }
    if (icon.position) {
      style.left = `${icon.position.left}px`;
      style.top = `${icon.position.right}px`;
    }
    return style;
  }

  getPillButtonClass(packageDisplayType: MobilePackageDisplayType): string {
    if (
      packageDisplayType === MobilePackageDisplayType.TURQUOISE_BASE ||
      packageDisplayType === MobilePackageDisplayType.ORANGE_BASE_WITH_GREY
    ) {
      return 'white';
    }
    if (
      packageDisplayType === MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE ||
      packageDisplayType === MobilePackageDisplayType.PURPLE_BASE ||
      packageDisplayType === MobilePackageDisplayType.PURPLE_BASE_WITH_ORANGE ||
      packageDisplayType === MobilePackageDisplayType.TURQUOISE_BASE_WITH_ORANGE
    ) {
      return 'secondary';
    }
    if (
      packageDisplayType === MobilePackageDisplayType.RED_BASED_WITH_WHITE ||
      packageDisplayType === MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND
    ) {
      return 'secondary black';
    }
    if (packageDisplayType === MobilePackageDisplayType.ORANGE_BASE_WITH_BLACK) {
      return 'white-with-black';
    }
    return 'primary';
  }

  private getImageSrc(imageName: string): string {
    return `assets/images/${imageName}`;
  }
}
