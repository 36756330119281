import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { IMetaData } from '../../../global/global.interfaces';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  netfoneTitlePostfix = ' | Netfone';

  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string): void {
    this.title.setTitle(title + this.netfoneTitlePostfix);
  }

  updateDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateMetaTag(property: string, value: string): void {
    this.meta.updateTag({ name: property, content: value });
  }

  setOgMetaData(data: IMetaData): void {
    data.og_type = 'website';
    Object.keys(data).forEach((item) => {
      let keyword = item.replace('_', ':');
      if (keyword.includes('meta:')) {
        keyword = keyword.replace('meta:', '');
        if (this.meta.getTag(`name="${keyword}"`)) {
          this.meta.updateTag({ name: keyword, content: data[item as keyof IMetaData] });
        } else {
          this.meta.addTag({ name: keyword, content: data[item as keyof IMetaData] });
        }
      } else {
        if (this.meta.getTag(`property="${keyword}"`)) {
          this.meta.updateTag({ property: keyword, content: data[item as keyof IMetaData] });
        } else {
          this.meta.addTag({ property: keyword, content: data[item as keyof IMetaData] });
        }
      }
    });
  }
}
