import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EndlessLineType } from './endless-line.interfaces';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ose-endless-line',
  templateUrl: './endless-line.component.html',
  styleUrls: ['./endless-line.component.scss'],
})
export class EndlessLineComponent implements OnInit, OnDestroy {
  @Input() type: EndlessLineType = 'gray';
  @Input() typeSubject$: BehaviorSubject<EndlessLineType>;
  imageSrc: string;

  constructor() {}

  ngOnInit(): void {
    if (this.type) {
      this.generateSvgImage(this.type);
    }
    if (this.typeSubject$) {
      this.typeSubject$.subscribe((newType: EndlessLineType) => {
        this.type = newType;
        this.generateSvgImage(this.type);
      });
    }
  }

  private generateSvgImage(type: EndlessLineType): void {
    switch (type) {
      case 'dark-gray':
        this.imageSrc = this.getSVGFilePath('curve_dark_gray');
        break;
      case 'gray':
        this.imageSrc = this.getSVGFilePath('curve_gray');
        break;
      case 'black':
        this.imageSrc = this.getSVGFilePath('curve_black');
        break;
      case 'light-gray':
        this.imageSrc = this.getSVGFilePath('curve_light_gray');
        break;
      case 'orange':
        this.imageSrc = this.getSVGFilePath('curve_yellow');
        break;
      case 'tan':
        this.imageSrc = this.getSVGFilePath('curve_tan');
        break;
      case 'white':
        this.imageSrc = this.getSVGFilePath('curve_white');
        break;
      case 'orange-white':
        this.imageSrc = this.getSVGFilePath('curve_white');
        break;
      case 'red-black':
        this.imageSrc = this.getSVGFilePath('curve_red');
        break;
      case 'orange-black-55':
      case 'orange-black-0':
        this.imageSrc = this.getSVGFilePath('curve_yellow');
        break;
    }
  }

  getSVGFilePath(svg_name: string): string {
    return `assets/images/${svg_name}.svg`;
  }

  ngOnDestroy(): void {
    if (this.typeSubject$) {
      this.typeSubject$.next(null);
      this.typeSubject$.complete();
    }
  }
}
