<div *ngIf="innerWidth">
  <div *ngIf="!displayAsPillOnMobile || (displayAsPillOnMobile && innerWidth > 768); else pill">
    <div
      *ngIf="!packageDetails.newPackageDisplayMode; else newPackageDisplayMode"
      class="package-wrapper"
      [class]="{
        'white-base': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE,
        'white-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE_WITH_ORANGE,
        'orange-base': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE,
        'grey-base': packageDetails.packageDisplayType === packageDisplayType.GREY_BASE,
        'grey-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.GREY_BASE_WITH_ORANGE,
        'purple-base': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE,
        'purple-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE,
        'turquoise-base': packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE,
        'turquoise-base-with-orange':
          packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE
      }"
    >
      <div class="top-section">
        <ng-container *ngIf="!packageDetails.unlimitedInternet; else unlimitedInternet">
          <h1 class="package-title" [class]="{ 'no-internet': packageDetails.noInternet }">
            {{ packageDetails.size }}
          </h1>
        </ng-container>
        <div
          class="extra-detail"
          [class]="{
            'white-base': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE,
            'white-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE_WITH_ORANGE,
            'orange-base': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE,
            'grey-base': packageDetails.packageDisplayType === packageDisplayType.GREY_BASE,
            'grey-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.GREY_BASE_WITH_ORANGE,
            'purple-base': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE,
            'purple-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE,
            'turquoise-base': packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE,
            'turquoise-base-with-orange':
              packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE,
            'orange-base-with-grey': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_GREY,
            inline: packageDetails.extraDetailInline
          }"
          *ngIf="packageDetails.extraDetail"
        >
          {{ packageDetails.extraDetail }}
        </div>
      </div>
      <div class="package-price">
        <img class="price-background" [src]="imageSrc" alt="" />
        <ng-container *ngIf="!packageDetails.priceText; else onlyTextTemplate">
          <span class="price">{{ packageDetails.price | number }}</span>
          <div class="huf-per-month-wrapper">
            <span [class]="packageDetails.displayVat ? 'huf-and-vat-per-month' : 'huf-per-month'">{{
              packageDetails.displayVat ? 'Ft+Áfa' : 'Ft/hó'
            }}</span>
            <span class="huf-and-vat-per-month" *ngIf="packageDetails.displayVat"><br />/hó</span>
          </div>
        </ng-container>
        <ng-template #onlyTextTemplate>
          <div class="only-text-wrapper">
            <span class="only-text-content">{{ packageDetails.priceText }}</span>
          </div>
        </ng-template>
      </div>
      <small
        class="package-details"
        [ngClass]="{
          white:
            packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE ||
            packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE ||
            packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE ||
            packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE
        }"
        [class.enlarged]="packageDetails.priceText"
        >{{ packageDetails.details }}</small
      >
      <div class="footer">
        <ng-container *ngIf="!packageDetails.priceText; else onlyTextButtonTemplate">
          <button
            class="new-netfone-button uppercase"
            [ngClass]="getPillButtonClass(packageDetails.packageDisplayType)"
            (click)="onClickPackageButton()"
          >
            {{ packageDetails.view ? 'Megnézem' : 'Ezt kérem' }}
          </button>
        </ng-container>
        <ng-template #onlyTextButtonTemplate>
          <ose-endless-line class="retail-background" type="light-gray"></ose-endless-line>
          <span class="only-text-button-replacement">Lakossági csomag</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #newPackageDisplayMode>
  <div
    class="package-wrapper new-min-height"
    [class]="{
      'purple-base': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE,
      'purple-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE,
      'turquoise-base': packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE,
      'turquoise-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE,
      'white-base': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE,
      'white-base-with-orange-no-background':
        packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND,
      'red-base': packageDetails.packageDisplayType === packageDisplayType.RED_BASED,
      'red-base-with-white': packageDetails.packageDisplayType === packageDisplayType.RED_BASED_WITH_WHITE,
      'orange-base-with-grey': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_GREY,
      'orange-base-with-black': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_BLACK
    }"
  >
    <div class="top-section new">
      <ng-container *ngIf="!packageDetails.unlimitedInternet; else unlimitedInternet">
        <ng-container *ngIf="!packageDetails.hundredMinutes; else hundredMinutes">
          <h1
            class="package-title new"
            [class]="{
              'no-internet': packageDetails.noInternet,
              'custom-package': packageDetails.custom,
              barlow: packageDetails.isBarlow
            }"
          >
            {{ packageDetails.size }}
          </h1>
        </ng-container>
      </ng-container>
      <div
        class="extra-detail new"
        [class]="{
          'purple-base': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE,
          'purple-base-with-orange': packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE,
          'turquoise-base': packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE,
          'white-base': packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE,
          'white-base-with-orange-no-background':
            packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND,
          'turquoise-base-with-orange':
            packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE,
          'red-base': packageDetails.packageDisplayType === packageDisplayType.RED_BASED,
          'red-base-with-white': packageDetails.packageDisplayType === packageDisplayType.RED_BASED_WITH_WHITE,
          'orange-base-with-grey': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_GREY,
          'orange-base-with-black': packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_BLACK,
          inline: packageDetails.extraDetailInline,
          barlow: packageDetails.isBarlow
        }"
        *ngIf="packageDetails.extraDetail"
      >
        {{ packageDetails.extraDetail }}
      </div>
    </div>
    <div class="package-price new" [class]="{ barlow: packageDetails.isBarlow }">
      <img class="price-background" [src]="imageSrc" alt="" />
      <ng-container *ngIf="!packageDetails.priceText; else onlyTextTemplate">
        <span class="price">{{ packageDetails.price | number }}</span>
        <div class="huf-per-month-wrapper">
          <span [class]="packageDetails.displayVat ? 'huf-and-vat-per-month' : 'huf-per-month'">{{
            packageDetails.displayVat ? 'Ft+Áfa' : 'Ft/hó'
          }}</span>
          <span class="huf-and-vat-per-month" *ngIf="packageDetails.displayVat"><br />/hó</span>
        </div>
        <img *ngIf="packageDetails.summerSale" class="summer-icon" src="assets/images/nap.svg" alt="Nap" />
      </ng-container>
      <ng-template #onlyTextTemplate>
        <div class="only-text-wrapper new">
          <span class="only-text-content">{{ packageDetails.priceText }}</span>
        </div>
      </ng-template>
    </div>
    <ng-container *ngIf="!packageDetails.hideIcons; else iconPlaceholder">
      <ng-container *ngIf="!packageDetails.customIcons; else customIcon">
        <div class="package-images">
          <img
            class="icon"
            [src]="
              packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE
                ? 'assets/images/okos_dontes_1_grey.svg'
                : 'assets/images/okos_dontes_1_orange.svg'
            "
            alt=""
          />
          <img
            class="icon"
            [src]="
              packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE
                ? 'assets/images/huseg_nelkul_1_grey.svg'
                : 'assets/images/huseg_nelkul_1_orange.svg'
            "
            alt=""
          />
        </div>
      </ng-container>
    </ng-container>
    <ng-template #customIcon>
      <div class="package-images">
        <img
          class="icon"
          *ngIf="packageDetails.customIcons.firstIcon"
          [src]="getCustomIconSrc(packageDetails.customIcons.firstIcon)"
          [ngStyle]="getCustomIconStyles(packageDetails.customIcons.firstIcon)"
          alt=""
        />
        <img
          class="icon"
          *ngIf="packageDetails.customIcons.secondIcon"
          [src]="getCustomIconSrc(packageDetails.customIcons.secondIcon)"
          [ngStyle]="getCustomIconStyles(packageDetails.customIcons.secondIcon)"
          alt=""
        />
      </div>
    </ng-template>
    <ng-template #iconPlaceholder>
      <div class="package-images icon-placeholder"></div>
    </ng-template>
    <div *ngIf="packageDetails.extra50gb" class="extra-50-gb">
      <img class="description-image" src="assets/images/extra-50-gb-text.svg" alt="1x 50 GB Ajánték" />
    </div>
    <div class="footer new">
      <button
        class="new-netfone-button uppercase"
        [class]="
          packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE ||
          packageDetails.packageDisplayType === packageDisplayType.PURPLE_BASE_WITH_ORANGE ||
          packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE_WITH_ORANGE
            ? 'secondary'
            : packageDetails.packageDisplayType === packageDisplayType.RED_BASED_WITH_WHITE ||
              packageDetails.packageDisplayType === packageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND
            ? 'secondary black'
            : packageDetails.packageDisplayType === packageDisplayType.TURQUOISE_BASE ||
              packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_GREY
            ? 'white'
            : packageDetails.packageDisplayType === packageDisplayType.ORANGE_BASE_WITH_BLACK
            ? 'white-with-black'
            : 'primary'
        "
        (click)="onClickPackageButton()"
      >
        {{ packageDetails.view ? 'Megnézem' : 'Ezt kérem' }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #unlimitedInternet>
  <img class="unlimited-internet" [src]="unlimitedIconSrc" alt="Korlátlan internet" />
</ng-template>

<ng-template #hundredMinutes>
  <h1 class="hundred-minutes">
    100 perc <span class="white">lebeszélhető</span>
    {{ packageDetails.unlimitedInternet ? 'korlátlan' : packageDetails.size }} internet
  </h1>
</ng-template>

<ng-template #pill>
  <div class="mobile-package-wrapper" [class]="getPackageDisplayTypeForPill(packageDetails)">
    <ng-container *ngIf="!packageDetails.custom; else pillCustomPackage">
      <div class="pill-package-price">
        <span class="price">{{ packageDetails.price | number }}</span>
        <span class="huf-per-month">{{ packageDetails.displayVat ? 'Ft+Áfa' : 'Ft/hó' }}</span>
      </div>
    </ng-container>
    <ng-template #pillCustomPackage>
      <span class="pill-custom-package"
        >Saját csomagot <br />
        választok</span
      >
    </ng-template>
    <div
      class="pill-package-size"
      [class]="packageDetails.pillConfiguration?.sccClass"
      *ngIf="!packageDetails.custom && packageDetails.pillConfiguration"
    >
      <span
        *ngIf="packageDetails.pillConfiguration.row1"
        [style]="{ color: packageDetails.pillConfiguration.row1.color }"
        >{{ packageDetails.pillConfiguration.row1.text }}
      </span>
      <br />
      <span
        *ngIf="packageDetails.pillConfiguration.row2"
        [style]="{ color: packageDetails.pillConfiguration.row2?.color }"
        >{{ packageDetails.pillConfiguration.row2?.text }}
      </span>
      <br />
      <span
        *ngIf="packageDetails.pillConfiguration.row3"
        [style]="{ color: packageDetails.pillConfiguration.row3?.color }"
        >{{ packageDetails.pillConfiguration.row3?.text }}
      </span>
    </div>
    <span class="pill-package-size"></span>

    <button
      class="new-netfone-button uppercase"
      [ngClass]="getPillButtonClass(packageDetails.packageDisplayType)"
      (click)="onClickPackageButton()"
    >
      {{ packageDetails.view ? 'Megnézem' : 'Ezt kérem' }}
    </button>
  </div>
</ng-template>
