import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { WINDOW } from '../window/window.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private router: Router
  ) {}

  scroll(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  scrollToTop(): void {
    const windowHelper = this.window;
    (function smoothscroll(): void {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        windowHelper.requestAnimationFrame(smoothscroll);
        windowHelper.scrollTo(0, currentScroll - 150);
        document.getElementById('scrollToTopButton').blur();
      }
    })();
  }

  scrollToElement(elementName: string): void {
    setTimeout(() => {
      const element: HTMLElement = document.getElementById(elementName);
      if (element) {
        window.scroll({
          top: elementName === 'top' ? 0 : element.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  navigateAndScrollToElement(navigateTo: string, scrollTo: string): void {
    this.router.navigate([navigateTo], { relativeTo: null }).then(() => this.scrollToElement(scrollTo));
  }
}
