export const InactiveMultipleLandingPages: string[] = [
  'lakossagi-korlatlanmobil-1GB-3990Ft-ert-neked',
  'lakossagi-korlatlanmobil-1GB-3990Ft-ert',
  'lakossagi-korlatlanmobil-1GB-3990Ft-neked',
  'lakossagi-korlatlanmobil-100GB-6490Ft-ert',
  'lakossagi-korlatlanmobil-100GB-6490Ft-ert-neked',
  'lakossagi-korlatlanmobil-100GB-6490Ft-ert-most',
  'lakossagi-korlatlanmobil-100GB-6490Ft',
  'lakossagi-korlatlanmobil-4gb-3990Ft-ert',
  'lakossagi-korlatlanmobil-4gb-3990Ft',
  'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most',
  'lakossagi-korlatlanmobil-50gb-4990Ft-most',
  'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked',
  'lakossagi-korlatlanmobil-50gb-4990Ft-ert',
  'lakossagi-korlatlanmobil-100gb-5990Ft-ert',
  'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked',
  'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most',
  'lakossagi-korlatlanmobil-100gb-5990Ft',
];
