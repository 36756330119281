import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  defaultEnvironmentVariablesValues,
  ExposedEnvironmentVariable,
  exposedEnvironmentVariables,
} from '../../../../environments/environment-variables';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentVariablesService {
  private readonly transferKey = makeStateKey<{ [key: string]: string }>('envVars');
  private readonly vars: { [key: string]: string } = {};

  constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformServer(this.platformId)) {
      exposedEnvironmentVariables.forEach((envVarName) => {
        if (environment.production && process.env[envVarName]) {
          this.vars[envVarName] = process.env[envVarName];
        }
      });

      this.transferState.set<{ [key: string]: string }>(this.transferKey, this.vars);
    }

    if (isPlatformBrowser(this.platformId)) {
      this.vars = this.transferState.get<{ [key: string]: string }>(this.transferKey, {});
    }
  }

  public getVar(name: ExposedEnvironmentVariable): string {
    if (name in this.vars) {
      return this.vars[name];
    }
    if (environment.production) {
      throw Error(`${name} environment variable is missing`);
    }

    const defaultValue = defaultEnvironmentVariablesValues[name];
    return defaultValue;
  }
}
