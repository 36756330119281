<ng-template #shareDialogTemplate let-c="close">
  <img class="close-icon" (click)="close()" src="../../../../assets/images/black-close.svg" alt="Bezárás" />
  <div class="title">Megosztás</div>
  <ng-container *ngIf="currentShareUrl; else loader">
    <div class="icon-container">
      <div class="icon-wrapper">
        <img
          alt="Facebook"
          title="Facebook"
          class="social-media-icon"
          (click)="shareToFacebook()"
          src="../../../../assets/images/new_social_media_icons/facebook_icon.svg"
        />
        <span class="icon-name">Facebook</span>
      </div>
      <div class="icon-wrapper">
        <img
          alt="Messenger"
          title="Messenger"
          class="social-media-icon"
          (click)="shareToMessenger()"
          src="../../../../assets/images/new_social_media_icons/messenger_icon.svg"
        />
        <span class="icon-name">Messenger</span>
      </div>
      <div class="icon-wrapper">
        <img
          alt="Viber"
          title="Viber"
          class="social-media-icon"
          (click)="shareToViber()"
          src="../../../../assets/images/new_social_media_icons/viber_icon.svg"
        />
        <span class="icon-name">Viber</span>
      </div>
      <div class="icon-wrapper">
        <img
          alt="Whatsapp"
          title="Whatsapp"
          class="social-media-icon"
          (click)="shareToWhatsapp()"
          src="../../../../assets/images/new_social_media_icons/whatsapp_icon.svg"
        />
        <span class="icon-name">Whatsapp</span>
      </div>
      <div class="icon-wrapper">
        <img
          alt="Telegram"
          title="Telegram"
          class="social-media-icon"
          (click)="shareToTelegram()"
          src="../../../../assets/images/new_social_media_icons/telegram_icon.svg"
        />
        <span class="icon-name">Telegram</span>
      </div>
    </div>
    <p *ngIf="copied" class="link-copied">Link sikeresen kimásolva!</p>
    <div class="short-url">
      <input
        aria-describedby="shortUrl"
        class="ntfc-input-100 mr-2"
        id="shortUrl"
        placeholder="Rövid URL"
        type="text"
        disabled
        [ngModel]="currentShareUrl"
      />
      <button class="new-netfone-button primary" (click)="copyShortUlr()" [disabled]="!currentShareUrl">Másolás</button>
    </div>
    <ng-container *ngIf="recommender; else setRecommenderTemplate">
      <div class="current-recommender">
        <div class="form-group">
          <label for="recommender" class="ntfc-form-label">Megosztási kód</label>
          <div class="input-wrapper">
            <input
              aria-describedby="shortUrl"
              class="ntfc-input-100 ntfc-form-control-success mr-2"
              id="recommender"
              placeholder="Megosztási kód"
              type="text"
              disabled
              [ngModel]="recommender.nicknev"
            />
            <button class="new-netfone-button primary" (click)="removeRecommender()">Eltávolítás</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #setRecommenderTemplate>
    <button class="stealth-button" (click)="setRecommenderDisplayVisibility(true)" *ngIf="!displayNewRecommenderInput">
      Rendelkezem megosztási kóddal
    </button>

    <div *ngIf="displayNewRecommenderInput">
      <p *ngIf="noUserFound" class="no-user-found">Mibás a megosztó kód.</p>
      <div class="form-group">
        <label
          for="newRecommender"
          class="ntfc-form-label"
          [ngClass]="{
            'ntfc-has-error': recommenderNickname.invalid && (recommenderNickname.dirty || recommenderNickname.touched),
            'ntfc-has-success': recommenderNickname.valid && (recommenderNickname.dirty || recommenderNickname.touched)
          }"
          >Megosztási kód</label
        >
        <div class="input-wrapper">
          <input
            [ngClass]="{
              'ntfc-form-control-danger':
                recommenderNickname.invalid && (recommenderNickname.dirty || recommenderNickname.touched),
              'ntfc-form-control-success':
                recommenderNickname.valid && (recommenderNickname.dirty || recommenderNickname.touched)
            }"
            aria-describedby="shortUrl"
            class="ntfc-input-100 mr-2"
            id="newRecommender"
            placeholder="Megosztási kód"
            type="text"
            [formControl]="recommenderNickname"
          />
          <button
            class="new-netfone-button primary"
            (click)="validateNewRecommender()"
            [disabled]="recommenderNickname.invalid"
          >
            Beállítás
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #loader>
    <div class="short-url-loading-wrapper">
      <app-loading></app-loading>
    </div>
  </ng-template>
</ng-template>
