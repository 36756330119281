<section class="welcome-section">
  <ose-endless-line class="black-background" type="black"></ose-endless-line>
  <div class="netfone-container">
    <div class="title-wrapper">
      <h1 class="title-bar">Válassz a legjobb <span class="white">mobilcsomagok</span> közül</h1>
    </div>
    <div class="icon-wrapper">
      <img class="mobile-service-icon" src="assets/images/akcio_ikon_piros.svg" alt="Akció" />
    </div>
  </div>
</section>
<section class="subtitle-section">
  <div class="netfone-container">
    <h2 class="subtitle">Legnépszerűbb csomagjaink</h2>
    <h4 class="subtitle secondary">10 hónap hűséggel</h4>
  </div>
</section>
<section class="fix-packages-section">
  <div class="netfone-container">
    <div class="packages-wrapper">
      <ose-large-mobile-package
        *ngFor="let packageItem of packages"
        [packageDetails]="packageItem"
        (buttonClicked)="order($event)"
        [displayAsPillOnMobile]="true"
      ></ose-large-mobile-package>
    </div>
  </div>
</section>

<ose-all-packages [data]="allServicesData" *ngIf="allServicesData"></ose-all-packages>

<section class="share-section">
  <div class="netfone-container share-wrapper">
    <h3 class="share-description">
      Együtt óvjuk a környezetet, ezért az árainkat úgy kalkuláltuk, hogy a számlát e-mailben küldjük és nem postán.
      E-pack kedvezmény nélkül a havi díjak minden szolgáltatás csomag esetén egységesen automatikusan 700 Ft-al
      emelkednek. <br />
      Az árak tartalmazzák az Általános Forgalmi Adót. A tájékoztatás nem teljeskörű.
    </h3>
    <ose-global-share-button
      [shareText]="'Ha tetszett, szólj a barátaidnak is'"
      [shareClass]="'border-radius-10'"
    ></ose-global-share-button>
  </div>
</section>
