<div class="netfone-container" *ngIf="serviceList">
  <div
    class="list"
    [class]="{
      black: displayType === mobilePackagesTableDisplayTypeEnum.BLACK,
      orange: displayType === mobilePackagesTableDisplayTypeEnum.ORANGE,
      tan: displayType === mobilePackagesTableDisplayTypeEnum.TAN
    }"
  >
    <div class="list-header">
      <h4 class="title">{{ tableHeaderData.title }}</h4>
      <h5 class="subtitle">{{ tableHeaderData.subtitle }}</h5>
    </div>
    <div class="list-data" *ngFor="let item of serviceList">
      <div class="service-price-wrapper" [class]="getClassNameByStyle(item)">
        <div class="service-price">{{ getServiceDetails(item)?.haviDij | number: '3.' }}</div>
        <div class="price-unit">Ft/hó</div>
      </div>
      <div class="service-details">
        <div class="service-internet">
          <span class="internet-amount">{{ item.bennfoglaltNet }}</span>
          <span class="internet-unit">net/hó</span>
        </div>
        <button class="new-netfone-button secondary black" (click)="order(item)">EZT KÉREM</button>
      </div>
    </div>
  </div>
</div>
