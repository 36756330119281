<div class='cookie-disclaimer-wrapper'>
  <div class="cookie-disclaimer-title">Ne haragudj, hogy zavarunk</div>
  <div class="cookie-disclaimer-content">
    Weboldalunkon „cookie”-kat (továbbiakban „süti”) alkalmazunk. Ezek olyan fájlok, melyek információt tárolnak a
    böngésződben annak érdekében, hogy a felhasználói élményed a legjobb legyen.
  </div>
  <div class="cookie-disclaimer-content">
    A „sütiket” az elektronikus hírközlésről szóló 2003. évi C. törvény, az elektronikus kereskedelmi szolgáltatások, az
    információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény, valamint az
    Európai Unió előírásainak megfelelően használjuk.
  </div>
  <div class="cookie-disclaimer-content">Ehhez a hozzájárulásodat kérjük.</div>
  <div class="cookie-disclaimer-button-container">
    <button (click)="confirm()" class="new-netfone-button primary accept-cookies">Sütik engedélyezése</button>
  </div>
</div>
