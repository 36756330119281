import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor() {
    // EMPTY
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('http')) {
      return next.handle(req);
    }
    // Amennyiben asseteket szeretnénk lekérdezni, akkor FONTOS, hogy azoknak az URL-jét ne módosítsa
    if (req.url.indexOf('assets') !== -1) {
      return next.handle(req);
    }

    const clonedReq = req.clone({ url: '/api/v1' + req.url });

    return next.handle(clonedReq);
  }
}
