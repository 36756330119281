export enum RecommendationType {
  OKOSTELEFON_PREMIUM = 'Prémium okostelefon',
  NYOMOGOMBOS = 'Nyomógombos',
  OKOSTELEFON_BELEPO = 'Belépő okostelefon',
  OKOSTELEFON_KOZEP = 'Közép okostelefon',
  STRAPATELEFON = 'Strapatelefon',
}

export enum SortType {
  NAME_ASC = 'Név szerint növekvő',
  NAME_DESC = 'Név szerint csökkenő',
  PRICE_ASC = 'Ár szerint növekvő',
  PRICE_DESC = 'Ár szerint csökkenő',
}

export enum PackageTypeEnum {
  BELFOLDI_KORLATLAN = 'Belföldi korlátlan',
  EU_KORLATLAN = 'EU korlátlan',
  SZAZ_PERCES = '100 perces',
  LEBESZELHETO = 'Lebeszélhető',
  EGYEB = 'Egyéb',
}

export enum SegmentEnum {
  LAKOSSAGI = 'Lakossági',
  UZLETI = 'Üzleti',
}

export enum ContactFormStatus {
  SENT = 'Elküldve',
  SEND = 'Küldés',
}

export enum PaymentMethod {
  BANK_PAYMENT = 'BANK_TRANSFER',
  GROUP_PAYMENT = 'DIRECT_DEBIT',
  CHECK_PAYMENT = 'YELLOW_CHEQUE',
  BANKCARD_PAYMENT = 'CREDIT_CARD',
}

export enum InvoiceType {
  E_INVOICE = 'E_BILLING',
  CLASSIC_INVOICE = 'NORMAL_BILLING',
}

export enum CallTimeframe {
  ALL_DAY = 'ALL_DAY',
  MORNING = 'MORNING',
  NOON = 'NOON',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export enum MismatchSimDialogResult {
  NEW_SIM = 'NEW_SIM',
  CHANGE_PACKAGE = 'CHANGE_PACKAGE',
  CHANGE_PACKAGE_AND_NEW_PHONE = 'CHANGE_PACKAGE_AND_NEW_PHONE',
}

export enum MonthlyPaymentDialogResult {
  NAVIGATE = 'NAVIGATE',
  DONT_NAVIGATE = 'DONT_NAVIGATE',
}

export enum InternetTypesEnum {
  'TWO_HUNDRED_MB' = '200MB',
  'ONE_GB' = '1GB',
  'FOUR_GB' = '4GB',
  'TWENTY_GB' = '20GB',
  'FIFTY_GB' = '50GB',
  'HUNDRED_GB' = '100GB',
  INFINITE = 'Korlatlan',
}

export enum SegmentTypesEnum {
  LAKOSSAGI = 'LAKOSSAGI',
  UZLETI = 'UZLETI',
}

export enum MobilePackageTypesEnum {
  'SZAZ_PERCES' = 'SZAZ_PERCES',
  'BELFOLDI_KORLATLAN' = 'BELFOLDI_KORLATLAN',
  'EU_KORLATLAN' = 'EU_KORLATLAN',
}

export enum OrderTypeEnum {
  MOBILE_SERVICE = 'MOBILE_SERVICE',
  DEVICE_ONLY = 'DEVICE_ONLY',
  DSL_SERVICE = 'DSL_SERVICE',
}

export enum OrderTypeQueryParamEnum {
  MOBILE_SERVICE = 'MOBILE_SERVICE',
  DLS_SERVICE = 'DLS_SERVICE',
  PHONE = 'PHONE',
}

export enum MapTypeEnum {
  RESELLER_ONLY = 'RESELLER_ONLY',
  FRANCHISE_PARTNER_ONLY = 'FRANCHISE_PARTNER_ONLY',
  ALL = 'ALL',
}
export enum OutsideTheEuFeeType {
  ROAMING = 'roaming',
  INTERNATIONAL = 'nemzetkozi',
}

export enum PartnerTypeEnum {
  PRIVATE_PERSON = 'PRIVATE_PERSON',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  CRM_CUSTOMER = 'CRM_CUSTOMER',
}

export enum IdentificationTypeEnum {
  IDENTITY_CARD = 'IDENTITY_CARD',
  OLD_IDENTITY_CARD = 'OLD_IDENTITY_CARD',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
}

export enum MsisdnPortingEnum {
  NEW_MSISDN = 'NEW_MSISDN',
  PORT_IN = 'PORT_IN',
}

export enum CurrentProviderEnum {
  VODAFONE = 'VODAFONE',
  TELENOR = 'TELENOR',
  TELEKOM = 'TELEKOM',
  UPC = 'UPC',
  DIGI = 'DIGI',
  TARR = 'TARR',
  TESCO_MOBILE = 'TESCO_MOBILE',
}

export enum CurrentServiceTypeEnum {
  PREPAID = 'PREPAID',
  POST_PAID = 'POST_PAID',
}

export enum OrderServiceTypeEnum {
  MOBILE = 'MOBILE',
  XDSL = 'XDSL',
}

export enum MenuStyleEnum {
  NORMAL = 'NORMAL',
  REDUCED = 'REDUCED',
}

export enum PhoneServiceProvidersEnum {
  TELEKOM = 'TELEKOM',
  VODAFONE = 'VODEFAONE',
}

export enum MobilePackagesDisplayTypeEnum {
  BLACK = 'black',
  ORANGE = 'orange',
  TAN = 'tan',
}

export enum CookieAcceptanceStatusEnum {
  ACCEPTED = 'accepted',
}

export enum CurrentSaleIdEnum {
  SALE = 'sale',
}
