import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loaderState = new Subject<boolean>();

  constructor() {
    // EMPTY
  }

  getLoadingSubject(): Observable<boolean> {
    return this._loaderState.asObservable().pipe(debounceTime(1000));
  }

  showLoading(): void {
    this._loaderState.next(true);
  }

  hideLoading(): void {
    this._loaderState.next(false);
  }
}
