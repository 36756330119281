<ng-container *ngIf="menuStyle === menuStyleEnum.NORMAL; else reducedTemplate">
  <header class="header_container">
    <div class="navbar_container">
      <div class="logo">
        <img
          alt="Netfone Logo"
          routerLink="/"
          src="../../../../assets/images/netfone_logo_orange.svg"
          (click)="menuOpen = menuOpen ? !menuOpen : false"
        />
      </div>
      <nav class="navbar navbar-expand-lg">
        <button (click)="menuOpen = !menuOpen" class="toggle-button">
          <img [src]="menuOpen ? 'assets/images/navbar-close.svg' : 'assets/images/navbar-toggler.svg'" alt="" />
        </button>

        <div class="normal-navbar">
          <ul class="navbar_list">
            <li class="navbar_list_item" routerLink="/mobil-szolgaltatas" routerLinkActive="active-link">Csomagok</li>
            <li class="navbar_list_item" routerLink="/vezetekes-internet" routerLinkActive="active-link">
              Otthoni Net
            </li>
            <li class="navbar_list_item" routerLink="/keszulekek" routerLinkActive="active-link">Készülék</li>

            <li class="navbar_list_item" routerLink="/sim-regisztracio" routerLinkActive="active-link">
              SIM regisztráció
            </li>
            <li class="navbar_list_item" routerLink="/kapcsolat" routerLinkActive="active-link">Kapcsolat</li>
            <li class="navbar_list_item portal" (click)="navigateToPortal()">
              <div class="image-wrapper">
                <img alt="" src="../../../../assets/images/profile_icon.svg" />
              </div>
              <span>Ügyfélkapu</span>
            </li>
            <li *ngIf="authService.isAuthenticated" class="nav-item navbar_list_item" ngbDropdown>
              <a
                aria-expanded="false"
                aria-haspopup="true"
                class="nav-link cursor-pointer"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                ngbDropdownToggle
              >
                {{ authService.loggedInUser?.nev }}
              </a>
              <div aria-labelledby="navbarDropdownMenuLink" ngbDropdownMenu>
                <a class="partners-dropdown-item" ngbDropdownItem routerLink="tagoknak/profil">Profil</a>
                <a class="partners-dropdown-item" ngbDropdownItem routerLink="tagoknak/megosztas">Megosztás</a>
                <a class="partners-dropdown-item" ngbDropdownItem routerLink="tagoknak/statisztika">Statisztika</a>
                <a (click)="onClickLogout()" class="partners-dropdown-item" ngbDropdownItem>Kijelentkezés</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
  <div *ngIf="menuOpen" class="mobile-menu">
    <div class="menu-list">
      <span
        (click)="menuOpen = !menuOpen"
        class="navbar_list_item"
        routerLink="/mobil-szolgaltatas"
        routerLinkActive="active-link"
      >
        Csomagok
      </span>
      <span
        (click)="menuOpen = !menuOpen"
        class="navbar_list_item"
        routerLink="/vezetekes-internet"
        routerLinkActive="active-link"
      >
        Otthoni Net
      </span>
      <span
        (click)="menuOpen = !menuOpen"
        class="navbar_list_item"
        routerLink="/keszulekek"
        routerLinkActive="active-link"
      >
        Készülék
      </span>
      <span
        (click)="menuOpen = !menuOpen"
        class="navbar_list_item"
        routerLink="/sim-regisztracio"
        routerLinkActive="active-link"
      >
        SIM regisztráció
      </span>
      <span
        (click)="menuOpen = !menuOpen"
        class="navbar_list_item"
        routerLink="/kapcsolat"
        routerLinkActive="active-link"
      >
        Kapcsolat
      </span>
      <span class="navbar_list_item rounded_list_item portal" (click)="navigateToPortal()">
        <img alt="" src="../../../../assets/images/user-menu-inverse.webp" />
        Ügyfélkapu
      </span>
      <div *ngIf="authService.isAuthenticated" class="menu-list">
        <span class="logged-in-user-name">{{ authService.loggedInUser?.nev }} </span>
        <div class="grey-line"></div>
        <span (click)="menuOpen = !menuOpen" class="navbar_list_item" routerLink="tagoknak/profil">Profil</span>
        <span (click)="menuOpen = !menuOpen" class="navbar_list_item" routerLink="tagoknak/megosztas">Megosztás</span>
        <span (click)="menuOpen = !menuOpen" class="navbar_list_item" routerLink="tagoknak/statisztika"
          >Statisztika</span
        >
        <span (click)="menuOpen = !menuOpen; onClickLogout()" class="navbar_list_item">Kijelentkezés</span>
      </div>
    </div>
  </div>
  <div (click)="menuOpen = !menuOpen" *ngIf="menuOpen" class="grey-layer"></div>
</ng-container>

<ng-template #reducedTemplate>
  <section class="reduced-header-section">
    <div class="netfone-container">
      <div class="link-wrapper">
        <a routerLink="/">
          <img class="logo" src="../../../../assets/images/netfone_logo_orange.svg" alt="Netfone Logo" />
        </a>
        <a href="https://www.mobilhalo.hu">
          <img class="logo" src="../../../../assets/images/mobilhalo_logo.svg" alt="Mobilháló Logo" />
        </a>
      </div>
    </div>
  </section>
</ng-template>
