import {
  MobilePackageDetails,
  MobilePackageDisplayType,
} from '../shared/components/large-mobile-package/large-mobile-package.interfaces';

export const SHARED_MOBILE_PACKAGES: MobilePackageDetails[] = [
  {
    newPackageDisplayMode: true,
    size: '50 GB',
    price: 6600,
    details: '',
    extraDetail: 'Korlátlan belföldi beszélgetés',
    crmIdentifier: '110083320',
    loyaltyMonth: 10,
    view: false,
    isBarlow: true,
    extra50gb: false,
    packageDisplayType: MobilePackageDisplayType.RED_BASED_WITH_WHITE,
    pillConfiguration: {
      row1: {
        text: 'Korlátlan',
        color: '#fff',
      },
      row2: {
        text: '50 GB',
        color: '#fff',
      },
      sccClass: 'two',
    },
    customIcons: {
      firstIcon: 'assets/images/akcio_arga_feher_felirat_szeles.svg',
    },
  },
  {
    newPackageDisplayMode: true,
    size: '50 GB',
    price: 4600,
    details: '',
    extraDetail: '100 perc belföldi beszélgetés',
    crmIdentifier: '110083315',
    loyaltyMonth: 10,
    view: false,
    isBarlow: true,
    packageDisplayType: MobilePackageDisplayType.RED_BASED_WITH_WHITE,
    pillConfiguration: {
      row1: {
        text: '100 perc',
        color: '#fff',
      },
      row2: {
        text: '50 GB',
        color: '#fff',
      },
      sccClass: 'two',
    },
    customIcons: {
      firstIcon: 'assets/images/akcio_arga_feher_felirat_szeles.svg',
    },
  },
  {
    newPackageDisplayMode: true,
    size: '100 GB',
    price: 7600,
    details: '',
    extraDetail: 'Korlátlan belföldi beszélgetés',
    crmIdentifier: '110083321',
    loyaltyMonth: 10,
    view: false,
    isBarlow: true,
    packageDisplayType: MobilePackageDisplayType.RED_BASED_WITH_WHITE,
    pillConfiguration: {
      row1: {
        text: 'Korlátlan',
        color: '#fff',
      },
      row2: {
        text: '100 GB',
        color: '#fff',
      },
      sccClass: 'two',
    },
    customIcons: {
      firstIcon: 'assets/images/akcio_arga_feher_felirat_szeles.svg',
    },
  },
];
