import { MatStepperIntl } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class StepperHuIntl implements MatStepperIntl {
  readonly changes: Subject<void> = new Subject<void>();
  optionalLabel = 'Opcionális';
  completedLabel: string;
  editableLabel: string;
}
