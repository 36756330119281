export const GlobalConstants = {
  SuccessMessages: {
    LOGIN: 'Sikeres Belépés!',
    LOGOUT: 'Sikeres Kijelentkezés!',
    UPLOAD: 'Sikeres feltöltés!',
    UPlOAD_UNDO: 'Sikeresen visszavonva!',
    UPLOAD_CONFIRM: 'Sikeresen megerősítve!',
    SAVE: 'Sikeres mentés!',
    UPDATE: 'Sikeres módosítás!',
    DELETE: 'Sikeres törlés',
    SEND: 'Az üzenet sikeresen elküldve!',
  },
  ErrorMessages: {
    UNSUPPORTED_CHARACTER_IN_EXCEL: 'A file neve nem megfelelő karaktert tartalmaz!',
    UNEXPECTED_ERROR: 'Váratlan hiba!',
    UNAUTHORISED: 'Önnek nincs joga az oldal megtekintéséhez, ki lett jelentkeztetve!',
  },
  BackendMessages: {
    UserUnauthorizedException: 'Nincs joga az oldal megtekintéséhez!',
    BadCredentialsException: 'Hibás felhasználónév vagy jelszó!',
    AszfNotFoundException: 'Az ÁSZF nem található!',
    UserNotFoundException: 'A felhasználó nem található!',
    ContentNotFoundException: 'A tartalom nem található!',
    MaxUploadSizeExceededException: 'A file mérete meghaladja a maximális méretet!',
    UserAlreadyExists: 'A felhasználó már létezik!',
    JelentkezettekSzamaBiggerThanZeroException: 'Az eseményt nem lehet törölni, már jelentkeztek rá!',
    // TODO: A hibaüzenetet javítani, amint a backend oldalról szét lesznek szedve az esetek
    SmsException: 'Az azonosítás során hiba történt!',
    SzolgaltatasNotFoundException: 'A szolgáltatás nem található!',
  },
  FormErrors: {
    UserWithThisEmailAlreadyExists: 'Az adott email címmel már létezik felhasználó',
    UserWithThisCrmAlreadyExists: 'Az adott CRM id-val már létezik felhasználó!',
    ContentWithSameLandingPageAzonositoException: 'Létezik már content ezzel a landing page azonositóval!',
    EsemenyWithSameLandingPageAzonositoException: 'Létezik már esemény ezzel a landing page azonositóval!',
  },
  OrderErrors: {
    BadRequest:
      'Hibás adatokat adtál meg a kitöltés során, így a megrendelést nem tudta a rendszer feldolgozni. Kérjük ellenőrizd a kitöltést!',
  },
  sessionStorageKeys: {
    selectedService: 'selectedService',
    installmentMode: 'installmentMode',
    JWT: 'JWT',
    selectedMobileServiceDetails: 'selectedMobileServiceDetails',
    recommender: 'recommender',
    simRecommender: 'simRecommender',
    simActivatedKey: 'simKey',
    adCampaignIdentifier: 'adCampaignIdentifier',
  },
  cookieAcceptedKey: 'cookieAccepted',
  structuredDataId: 'structuredData',
  adCampaignIdentifier: 'adCampaignIdentifier',
};
