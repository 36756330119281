import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ColleagueData } from './colleague-carousel.interfaces';
import { isPlatformBrowser } from '@angular/common';
import { strings } from '../../functions/global-string.functions';

@Component({
  selector: 'ose-colleague-carousel',
  templateUrl: './colleague-carousel.component.html',
  styleUrls: ['./colleague-carousel.component.scss'],
})
export class ColleagueCarouselComponent implements OnInit {
  colleagueDataList: ColleagueData[] = [
    {
      name: 'Vinnai Zoltán',
      imageName: this.getImageSource('Vinnai_Zoltan.webp'),
      description:
        'Bolt menedzserként járom az országot nap mint nap azért, hogy egyre több boltban elérhető legyen a Netfone szolgáltatása. Hiszem, hogy a Netfone csomagjai a legjobbak között vannak és ha erre vált valaki, akkor elégedett lesz és több pénz marad a pénztárcájában.  A viszonteladó boltokban dolgozó kollégáink tudása kiemelkedő és nagyon segítőkészek, bátran buzdítok mindenkit, hogy ha kérdése van, tanácsra van szüksége, keresse fel partnereink valamelyikét.',
    },
    {
      name: 'Kövesdi Szilvia',
      imageName: this.getImageSource('Kovesdi_Szilvia.webp'),
      description:
        'Én is már több, mint 10 éve dolgozom, mint Netfone-os. Ügyfélszolgálati munkatársként kezdtem, itt tanultam meg az ügyfelenik ügyintézésének minden titkát, módját. A munkám minőségére a vezetőim is felfigyeltek, ezért már 4 éve kineveztek, így az ügyfélszolgálat vezetőjeként engem és a csoportomat hívod, amikor segítségre van szükséged. Mindig arra törekszünk, hogy a lehető leggyorsabban és a legjobb minőségben oldjuk meg a hibákat, kezeljük le a problémákat, hogy Te elégedetten telefonálj vagy internetezz a Netfone szolgáltatásaival.',
    },
    {
      name: 'Andrasek Csaba',
      imageName: this.getImageSource('Andrasek_Csaba.webp'),
      description:
        "'Minden mobil és vezetékes szolgáltatás mögött egy komplex műszaki platform üzemel melynek fejlesztésért és üzemeltetéséért az én csapatom felel. Ugyan kívülállóként bonyolultnak tűnnek ezek a műszaki rendszerek, de a csapatom komoly tudással, lelkesedéssel működteti annak érdekében, hogy Te elégedetten telefonálj és internetezz a mobiloddal. A mi munkánkban az a legjobb, hogy embereket, családokat kötünk össze a technológia segítségével.",
    },
    {
      name: 'Hammer Erika',
      imageName: this.getImageSource('Hammer_Erika.webp'),
      description:
        'A Netfone pénzügyei kompletten hozzám tartoznak. Mint a pénzügyért felelős vezető, koordinálom a szállítói, vevői számlákat, utalásokat, pénzügyeket annak érdekében, hogy minden pénzügyi folyamat flottul menjen és az ügyfelek nyugodtan, elégedetten tudjanak telefonálni, internetezni. Mindig is erre a pályára készültem, nem véletlen, hogy már az óvodában is kocka volt a jelem :) Biztos lehetsz benne, hogy a pontosságom,  precízségem okán mindig rendben lesz a személyes folyószámlád. Szerencsére a magánéletben egyáltalán nem mondhatom magam “kockának”, imádom a sportokat, a lakberendezést és minden olyan tevékenységet, ahol a kreativitásommal élhetek.\n' +
        'A mobiltelefon mindenben a segítségünkre van, akár dolgozunk, sportolunk, kirándulunk, vásárolunk, vagy ha csak egy szuper receptre van szükségünk a vasárnapi ebédhez. Használd Te is mindenhez a Netfone szolgáltatását és telefonját!',
    },
    {
      name: 'Inglis Viktor',
      imageName: this.getImageSource('Inglis_Viktor.webp'),
      description:
        'A mobiltelefon mára önkifejezési eszközzé lépett elő és talán a legfontosabb személyes tárgyunk lett. Olyan tudást tartunk a kezünkben, amit néhány éve el sem tudtunk volna képzelni. A mobiltelefon üzletág vezetőjeként én szerzem be neked a legjobb telefonokat. De egy vagány új telefon mit sem ér egy jó szolgáltatás nélkül. Válaszd ki Te is a kedvenc csomagodat, és adom hozzá a telefont!',
    },
    {
      name: 'Sallai Ágnes',
      imageName: this.getImageSource('Sallai_Agnes.webp'),
      description:
        'Stabil, megbízható szolgáltatást nyújtunk, de ha mégis probléma merül fel, a lehető leggyorsabban orvosoljuk azt. Egyike vagyok azoknak, akik segítenek Neked, hogy a hiba mielőbb megoldásra kerüljön. Nyugodt szívvel ajánlom a Netfone szolgáltatásait, mert tudom, hogy jó kezekben leszel. Fordulj hozzánk bizalommal! :)',
    },
    {
      name: 'Mészáros László',
      imageName: this.getImageSource('Meszaros_Laszlo.webp'),
      description:
        'A Netfone ügyvezetőjeként az a feladatom, hogy karmestere legyek egy olyan profi zenekarnak, ami a legnagyobb együttesek mellett szeretne a közönsége kegyeiben járni. Szerencsésnek mondhatom magam, mert tényleg a legjobb zenészekkel dolgozhatok és alkothatunk olyan szolgáltatásokat, amelyeket a legjobb szívvel ajánlok neked is. Üzletemberként sok cég életében vettem már részt, a nevemhez fűződik több ismert vállalat sikere, de igazán a Netfone-ban teljesedik ki a menedzser tudásom és ennek eredményeként a Netfone fejlődése töretlen és kiemelkedő.',
    },
    {
      name: 'Baranyi Balázs',
      imageName: this.getImageSource('Baranyi_Balazs.webp'),
      description:
        'Sok éves tapasztalattal rendelkezem a mobiltelefonok országos disztribúciójának kiépítésében, működtetésében, ami azt jelenti, hogy milyen csatornákon keresztül jutnak el a készülékek a gyártóktól a Te kezedbe. Ezt a tudást felhasználva építettünk ki egy viszonteladói hálózatot annak érdekében, hogy a Netfone szuper csomagjai mindenkihez eljuthassanak. Fontos a virtuális világban való megjelenésünk, de számomra legalább ennyire lényeges, hogy az üzletekben is megtaláld a Netfone szolgáltatásait országosan. Keresd a Netfone logóval ellátott mobiltelefon értékesítő boltokat.',
    },
    {
      name: 'Árvai Csaba',
      imageName: this.getImageSource('Arvai_Csaba.webp'),
      description:
        'A műszaki üzemeltető csapat egyik meghatározó tagja vagyok a Netfone-ban. A mi csapatunk biztosítja, hogy a szolgáltatások megbízhatóan működjenek. 24 órában monitorozzuk és ha kell, javítjuk a hálózatot, a szervereket és a berendezéseket, így Te csak válassz a csomagjaink közül és élvezd a villámgyors internetet és a jó minőségű telefonálást.',
    },
    {
      name: 'Ormai Gilda',
      imageName: this.getImageSource('Ormai_Gilda.webp'),
      description:
        'Minden üzleti vállalkozásban szükség van pénzügyi fegyelemre. Én vezetem a behajtási csoportot. Szerencsére velem nagyon ritkán találkoznak ügyfeleink, mivel az iparági átlag feletti a fizetési fegyelem, erre nagyon büszke vagyok.  Bátran és jó szívvel ajánlom az új csomagjainkat, melyek nem csak jók, hanem igazán pénztárca barátok is.',
    },
    {
      name: 'Rózsahegyi Béla',
      imageName: this.getImageSource('Rozsahegyi_Bela.webp'),
      description:
        'Az én mindennapjaim a szerverek, routerek, hálózati elemek varázslatos világában zajlanak. A mobil és vezetékes szolgáltatások műszaki sport az, amiben én vagyok a Netfone egyik csatára. Láttad a Mátrixot, ahol a biteket látták és nem a valós világot? Na én vagyok az a mérnök, aki, miközben fejleszt, embereket, családokat lát, akik boldogan és elégedetten használják a Netfone szolgáltatásait.',
    },
    {
      name: 'Malik Donát',
      imageName: this.getImageSource('Malik_Donat.webp'),
      description:
        'Több, mint 10 éve vagyok a Netfone egyik stabil munkatársa. Call Centeresként kezdtem a pályafutásom, majd értékesítő, később sales csoportvezető lettem. Kipróbáltam magam a nagykereskedelmi szolgáltatásokban is. Mindig is kreatív ember voltam és érdekelt a marketing, az évek során komoly tapasztalatokkal gazdagodtam ezen a területen, így ennek köszönhetően lettem a Netfone marketing menedzsere. Azok a hirdetések amelyekkel találkozol, az én munkám eredményei.',
    },
    {
      name: 'Malik Petra',
      imageName: this.getImageSource('Malik_Petra.webp'),
      description:
        'Lassan 10 éve dolgozom a Netfone csapatában. Talán én vagyok a legjobb példa arra, hogy ha valaki elhivatott és szereti a munkáját, milyen karrier utat tud bejárni. A Netfone lett a második otthonom és családom, ebben a környezetben találtam magamra. Az évek alatt megtanultam mindent az értékesítésről, a távközlésről, a mobilpiacról, de talán ennél is lényegesebb, hogy megtanultam az emberekkel bánni, motiválni, irányítani és mára én lettem az értékesítés vezetője és még a marketing csapatot is erősítem. Velem és a csapatommal beszélsz, amikor szerződést kötsz egy jó mobil csomagra vagy otthoni internetre. Megtaláljuk neked a legjobb csomagot, és hidd el, Te is elégedett ügyfelünk leszel.',
    },
    {
      name: 'Birgány-Kiss Ágnes',
      imageName: this.getImageSource('Birgany_Kiss_Agnes.webp'),
      description:
        '2019 márciusában érkeztem a Netfone csapatába, mint értékesítő. Éles váltás volt a korábbi munkámhoz képest, de az idő múlásával az élet igazolta, hogy jó döntést hoztam. Kommunikatív embernek tartom magam, ami a munkámnak az egyik fontos eleme, hiszen így születnek a későbbiekben a sikeres üzletek. Közvetlen vagyok és #mindigkedves, így az ügyfelek is szívesen fordulnak hozzám kérdéseikkel. Nálunk a kiemelt kapcsolattartás valóban kiemelt, közvetlen számainkat senki elől nem titkoljuk.',
    },
    {
      name: 'Tokodi Rita',
      imageName: this.getImageSource('Tokodi_Rita.webp'),
      description:
        'A szolgáltatások boszorkánykonyhájában dolgozva az én feladataim közé tartozik a szolgáltatás csomagjaink tervezése, kialakítása. Folyamatosan azon gondolkodom, hogy milyen ajánlatokkal adhatok valami pluszt meglévő és leendő ügyfeleinknek. A mostani csomagjainkra nagyon büszke vagyok, mert látható a sikerük, nagyon sokan választják azokat.  Próbáld ki Te is!',
    },
    {
      name: 'Tony',
      imageName: this.getImageSource('Tony.webp'),
      description:
        'A hivatalos titulusom hangulat menedzser. Minden héten több napot járok dolgozni a gazdimmal és tudom, hogy a legjobbat hozom ki a munkatársaimból. Megérzem kinek van szüksége szeretetre, ki akarja simogatni a buksim és kinek kell az igazi szeretetteljes stresszoldás. Azokon a napokon, amikor bent vagyok az irodában mindenkinek jobb a kedve és azon versenyeznek a többiek, kinek az ölébe feküdjek. Ha Te is boldog akarsz lenni, akkor legyél Netfone-os, mert minden SIM-kártyát én is megvizsgálok.',
    },
    {
      name: 'Szabó Aranka',
      imageName: this.getImageSource('Szabo_Aranka.webp'),
      description:
        'Pörgős személyiségemmel kicsivel több, mint egy éve színesítem a Netfone csapatát. A mindennapjaimon azon dolgozom, hogy a megrendelőinkből lehető leggyorsabban ügyfelek váljanak. Energiabombaként hatok a kollégákra és ezt igyekszem átadni az ügyfelek felé is. Ha szeretnél egy jó csapathoz tartozni, baráti csomagárakkal, válassz minket!',
    },
    {
      name: 'Korcsok Zita',
      imageName: this.getImageSource('Korcsok_Zita.webp'),
      description:
        'Közel 1 éve igazoltam át a Netfone értékesítési csapatába. Dinamikus és sikeres értékesítő vagyok. Szeretem a kihívásokat és ebben a munkában ezt meg is találtam :) Nagyon sok ügyféllel szerződöm, ami boldogsággal tölt el. Én vagyok az az értékesítő, aki előtt nincs akadály, bármilyen kérdéssel fordulsz hozzám, hidd el, megoldom. Az értékesítési feladat azért áll ilyen közel hozzám, mert nagyon jól mintázza az emberi kapcsolatokat és számomra az emberek nagyon fontosak.',
    },
    {
      name: 'Lakatos Emese',
      imageName: this.getImageSource('Lakatos_Emese.webp'),
      description:
        'Velem beszélsz, amikor valamilyen kérdésed vagy problémád van. Az ügyfélszolgálati csapatban a front office részen dolgozom, mi vesszük fel a telefonokat mikor a 1250-es számot hívod. Mindig is fontos volt számomra, hogy a leggyorsabb orvosoljuk a problémákat, hiszen a Netfone számára az ügyfél az első. Azt szoktuk bent mondani, hogy mi az ügyfelektől kapjuk a fizetésünket, ezért is a legfontosabb, hogy Te elégedett legyél.',
    },
    {
      name: 'Lajer László, Lézer',
      imageName: this.getImageSource('Lajer_Laszlo.webp'),
      description:
        'Az egész életem rock ‘n roll, mindig is zenekarok világában dolgoztam. Több neves rockbandának vagyok a menedzsere, így hozzám igazán passzol a Rockmobil, mint márka. Tartom a kapcsolatot a zenekarokkal és az én feladatom a marketing, magának a Rockmobilnak a megismertetése, mind a rock zenészekkel, mind a rock rajongókkal. Ha igazán vagány rockos mobilszolgáltatást keresel, akkor ez a Rockmobil!',
    },
    {
      name: 'Kiss Anita',
      imageName: this.getImageSource('Kiss_Anita.webp'),
      description:
        'Sales csapatot erősítem már több, mint 1 éve. Mikor ide jöttem dolgozni a Netfone-hoz nem ismertem a céget és meg is lepett milyen pezsgő élet van itt, mennyi ügyfél szeretne Netfone-os mobilszolgáltatást. Miután megismertem a csomagokat , magát a céget, az ügyfeleket, a azonnal meg is értettem, miért jönnek át ennyien hozzánk. Azt gondolom, a legjobb döntés Netfone-osnak lenni akár munkatársaként, akár ügyfélként.',
    },
    {
      name: 'Jankó Kinga',
      imageName: this.getImageSource('Janko_Kinga.webp'),
      description:
        'Az ügyfélszolgálat back-office munkatársaként régóta a feladatom a szolgáltatások létesítése. A munkám eredménye az egyik leglátványosabb, hiszen én vagyok az, aki bekapcsolom a szolgáltatást Neked. Sok éve oszlopos tagja vagyok ennek a motornak és az egyik legfontosabb fogaskerékként jó érzés, hogy sok elégedett ügyfelünk van. Bizalommal ajánlom magunkat és a szolgáltatásunkat, mert pontosan látom, milyen jó minőségben szolgáljuk ki az Ügyfeleinket.',
    },
    {
      name: 'Kun István',
      imageName: this.getImageSource('Kun_Istvan.webp'),
      description:
        'Kezdetektől alapító tagja vagyok a Netfone-nak. Az én feladatom a sales és a marketing csapat összehangolása, a szolgáltatások fejlesztése a termékmenedzser munkatársaimmal. Vezetőként nagyon fontos számomra, hogy a világban látott trendeket tartva, de sokszor megelőzve olyan szolgáltatásokat fejlesszünk, ami tényleg az ügyfeleink megelégedésére szolgálnak. Bátran ajánlom a Netfone mobil és vezetékes szolgáltatásait, mert tudom, hogy a legjobb technológiák alkalmazásával, magas színvonalú tudással rendelkező munkatársakkal és innovatív partnerekkel vezetjük be a piacra és nyújtjuk neked.',
    },
    {
      name: 'Hostyinszki Ádám',
      imageName: this.getImageSource('Hostyinszki_Adam.webp'),
      description:
        'Jelenleg készülék termékmenedzserként dolgozom. Karrieremet az ügyfélszolgálati csapatban kezdtem és mivel nagyon pontosan és elhivatottan dolgoztam, lehetőséget kaptam bizonyítani más területen is. A telefonok mindig is foglalkoztattak, viszont engem nem csak a technológiai fejlődés, az újdonságok, hanem a piaci trendek az új márkák bevezetésének marketing módszerei is érdekeltek. Így kerülhettem a készülék üzletágba termékmenedzserként. Mindenkinek ajánlom, hogy egy Netfone csomag mellé válasszon egy telefont is.',
    },
    {
      name: 'Lucza Kitti',
      imageName: this.getImageSource('Lucza_Kitti.webp'),
      description:
        'A legsportosabb behajtási munkatársa vagyok a Netfone-nak. Több, mint három éve vagyok Netfone-os és annak ellenére nagyon szeretem a munkám, hogy a pénzügyi behajtás nem a legkönnyebb feladat. Büszke vagyok arra, hogy ezeket a nehéz helyzeteket is jól kezelem és a legtöbbször az ügyfelek is elégedettek. Mivel a feladataim egy része stresszes, így a sportban tudom az energiáimat kiadni és megteremteni a belső harmóniát. Folyamatosan növekszik az előfizetőink száma, ami azt bizonyítja, hogy a Netfone szolgáltatása és csomagárai találkoznak az ügyfelek igényeivel.',
    },
  ];

  activeColleague: ColleagueData;
  desktopViewActive = false;
  tabletViewActive = false;

  activeItemIndex = 2;

  private scrollEventHandlingActive = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (!this.desktopViewActive && this.isDesktopViewActive()) {
      this.desktopViewActive = true;
      this.tabletViewActive = false;
      this.activeItemIndex = 1;
    }
    if (!this.tabletViewActive && this.isTabletViewActive()) {
      this.desktopViewActive = false;
      this.tabletViewActive = true;
      this.activeItemIndex = 0;
    }
  }

  private isDesktopViewActive(): boolean {
    return window.innerWidth >= 1200;
  }

  private isTabletViewActive(): boolean {
    return window.innerWidth >= 768 && window.innerWidth < 1200;
  }

  ngOnInit(): void {
    strings.sort(this.colleagueDataList, 'name');

    if (isPlatformBrowser(this.platformId)) {
      this.desktopViewActive = this.isDesktopViewActive();
      this.tabletViewActive = this.isTabletViewActive();

      // Random pontról indul
      const randomShiftNumber = Math.floor(Math.random() * this.colleagueDataList.length);
      this.colleagueDataList.unshift(
        ...this.colleagueDataList.splice(this.colleagueDataList.length - randomShiftNumber, randomShiftNumber)
      );

      this.activeItemIndex = this.desktopViewActive ? 2 : 0;
      this.activeColleague = this.colleagueDataList[this.activeItemIndex];
    }
  }

  jumpToNextPerson(): void {
    const firstElement = this.colleagueDataList.shift();
    this.colleagueDataList.push(firstElement);
    this.activeColleague = this.colleagueDataList[this.activeItemIndex];
  }

  jumpToPrevPerson(): void {
    const firstElement = this.colleagueDataList.pop();
    this.colleagueDataList.unshift(firstElement);
    this.activeColleague = this.colleagueDataList[this.activeItemIndex];
  }

  jumpToPerson(index: number, colleague: ColleagueData): void {
    if (index === this.activeItemIndex) {
      return;
    }

    if (index > this.activeItemIndex) {
      const elementsUntilClickedPerson = this.colleagueDataList.splice(0, index - 1);
      this.colleagueDataList.push(...elementsUntilClickedPerson);
    } else {
      const elementBeforeClickedPerson = this.colleagueDataList.pop();
      this.colleagueDataList.unshift(elementBeforeClickedPerson);
    }
    this.activeColleague = colleague;
  }

  jumpToPersonMobile(index: number, colleague: ColleagueData): void {
    this.activeColleague = colleague;
  }

  onScroll(scrollEvent: Event): void {
    if (!this.scrollEventHandlingActive) {
      window.requestAnimationFrame(() => {
        this.scrollEventHandlingActive = true;
        const calculatedActiveItemIndex = Math.round(
          (scrollEvent.target as HTMLDivElement).scrollLeft / this.getCarouselElementWidth()
        );
        if (calculatedActiveItemIndex !== this.activeItemIndex) {
          this.activeItemIndex = calculatedActiveItemIndex;
          this.activeColleague = this.colleagueDataList[this.activeItemIndex];
        }
        this.scrollEventHandlingActive = false;
      });
    }
  }

  private getImageSource(imageName: string): string {
    return 'assets/images/colleagues/' + imageName;
  }

  private getCarouselElementWidth(): number {
    if (this.desktopViewActive) {
      return 600;
    }

    if (this.tabletViewActive) {
      return 310;
    }

    return 160;
  }
}
