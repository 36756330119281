import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PaymentMethod } from '../../../global/global.enums';

export function validateBankAccountNumber(): ValidatorFn {
  return (control: FormGroup): ValidationErrors | null => {
    const regExp = new RegExp(/^\d{8}-\d{8}(-\d{8})?$/);
    const bankControl = control.get('bankAccountNumber');
    if (!bankControl) {
      return null;
    }
    let errorBody: ValidationErrors;
    const countryField = control.parent?.get('personalDataForm.country');
    if (![undefined, null, 'Magyarország'].includes(countryField?.value)) {
      errorBody = bankControl?.value == null || bankControl?.value?.trim().length === 0 ? { required: true } : null;
    } else if (
      [PaymentMethod.CHECK_PAYMENT, PaymentMethod.BANKCARD_PAYMENT].includes(control.get('paymentMethod').value)
    ) {
      errorBody = bankControl?.value?.length > 0 && regExp.exec(bankControl.value) == null ? { required: true } : null;
    } else {
      errorBody = regExp.exec(bankControl.value) == null ? { required: true } : null;
    }

    let finalErrorMessages: ValidationErrors = errorBody;
    if (bankControl.errors) {
      const { required, ...restErrorMessages } = bankControl.errors;
      finalErrorMessages = { ...errorBody, ...restErrorMessages };
    }

    if (finalErrorMessages && Object.keys(finalErrorMessages).length === 0) {
      finalErrorMessages = null;
    }

    bankControl.setErrors(finalErrorMessages);
    return null;
  };
}
