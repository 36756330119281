<div class="overflow-x-y-hidden app-container" *ngIf="!loadingStatus; else loadingTemplate">
  <app-header></app-header>
  <ose-cookie-dialog *ngIf="showCookieDisclaimer" (cookieAccepted)="cookieAccepted($event)"></ose-cookie-dialog>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
  <app-notification></app-notification>
  <app-footer></app-footer>
  <app-scroll-to-top></app-scroll-to-top>
</div>
<ng-template #loadingTemplate>
  <div class="loading-wrapper">
    <app-loading></app-loading>
    <h1 class="loading-title">Csatlakozás az authentikációs szerverhez...</h1>
  </div>
</ng-template>
