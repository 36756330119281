import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { MobileServicesComponent } from './modules/mobile-services/mobile-services.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CustomLandingGuard } from './shared/guards/custom-landing-guard/custom-landing.guard';
import { PartnerPlusResolver } from './modules/partners/partner-plus/partner-plus.resolver';
import { ShortUrlGuard } from './shared/guards/short-url-guard/short-url.guard';
import { InactiveMultipleLandingPages } from './shared/constants/landing-pages.constants';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'kapcsolat',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then((m) => m.ContactUsModule),
  },
  {
    path: 'keszulekek',
    loadChildren: () => import('./modules/devices/devices.module').then((m) => m.DevicesModule),
  },
  {
    path: 'keszulekek/:name',
    loadChildren: () => import('./modules/phone-detail/phone-detail.module').then((m) => m.PhoneDetailModule),
  },
  {
    path: 'altalanos-szerzodesi-feltetelek',
    loadChildren: () =>
      import('./modules/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule),
  },
  {
    path: 'gyermekek-biztonsagos-internetezese',
    loadChildren: () =>
      import('./modules/internet-safety-for-children/internet-safety-for-children.module').then(
        (m) => m.InternetSafetyForChildrenModule
      ),
  },
  {
    path: 'apn-beallitas',
    loadChildren: () => import('./modules/apn-settings/apn-settings.module').then((m) => m.ApnSettingsModule),
  },
  {
    path: 'adatvedelmi-tajekoztato',
    loadChildren: () => import('./modules/gdpr/gdpr.module').then((m) => m.GdprModule),
  },
  {
    path: 'lefedettseg',
    loadChildren: () => import('./modules/coverage/coverage.module').then((m) => m.CoverageModule),
  },
  {
    path: 'osszekapcsolasra-és-hivasvegzodtetesre-vonatkozo-altalanos-feltetelek',
    loadChildren: () =>
      import(
        './modules/general-conditions-for-connection-and-termination-of-calls/general-conditions-for-connection-and-termination-of-calls.module'
      ).then((m) => m.GeneralConditionsForConnectionAndTerminationOfCallsModule),
  },
  {
    path: 'mobil-szolgaltatas',
    loadChildren: () => import('./modules/mobile-services/mobile-services.module').then((m) => m.MobileServicesModule),
  },
  {
    path: 'szolgaltatasok',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/lakossagi-korlatlan',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/lakossagi-korlatlan-eu',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/lakossagi-alapcsomag',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/lakossagi-tarskartya',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/uzleti-korlatlan',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'szolgaltatasok/uzleti-korlatlan-eu',
    redirectTo: 'mobil-szolgaltatas',
  },
  {
    path: 'vezetekes-internet',
    loadChildren: () => import('./modules/dsl-service/dsl-services.module').then((m) => m.DslServicesModule),
  },
  {
    path: 'megrendeles',
    loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'sim-regisztracio',
    loadChildren: () => import('./modules/activate-sim/activate-sim.module').then((m) => m.ActivateSimModule),
  },
  {
    path: 'sim-aktivalas',
    redirectTo: 'sim-regisztracio',
  },
  {
    path: 'cofidis-aruhitel',
    loadChildren: () => import('./modules/trade-loan/trade-loan.module').then((m) => m.TradeLoanModule),
  },
  {
    path: 'gyakori-kerdesek',
    loadChildren: () => import('./modules/faq-page/faq-page.module').then((m) => m.FaqPageModule),
  },
  {
    path: 'percdijak',
    loadChildren: () =>
      import('./modules/outside-the-eu-fee/outside-the-eu-fee.module').then((m) => m.OutsideTheEuFeeModule),
  },
  {
    path: 'tagoknak',
    loadChildren: () => import('./modules/partners/partners.module').then((m) => m.PartnersModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'akcios-keszulekek-reszlet-nelkul/:username',
    component: HomeComponent,
    canActivate: [CustomLandingGuard],
  },

  {
    path: 'partnerplus/:username',
    children: [
      {
        path: '',
        pathMatch: 'full',
        resolve: { data: PartnerPlusResolver },
        component: MobileServicesComponent,
      },
      {
        path: ':crmIdentifier',
        resolve: { data: PartnerPlusResolver },
        component: HomeComponent,
      },
    ],
  },

  {
    path: 'netfone-partner/:username',
    loadChildren: () =>
      import('./modules/landing-pages/netfone-partner/netfone-partner.module').then((m) => m.NetfonePartnerModule),
  },
  {
    path: 'csongrad-csanad-varmegye/:username',
    loadChildren: () =>
      import('./modules/landing-pages/csongrad-csanad-varmegye-partner/csongrad-csanad-varmegye-partner.module').then(
        (m) => m.CsongradCsanadVarmegyePartnerModule
      ),
  },
  {
    path: 'netfone-korlatlan-mobil-50gb-6600ft/:username',
    loadChildren: () =>
      import('./modules/landing-pages/unlimited-fifty-gb/unlimited-fifty-gb.module').then(
        (m) => m.UnlimitedFiftyGbModule
      ),
  },
  {
    path: 'netfone-korlatlan-mobil-100gb-7600ft/:username',
    loadChildren: () =>
      import('./modules/landing-pages/unlimited-hundred-gb/unlimited-hundred-gb.module').then(
        (m) => m.UnlimitedHundredGbModule
      ),
  },
  {
    path: 'netfone-olcso-mobilcsomagok-nalam/:username',
    loadChildren: () =>
      import('./modules/landing-pages/cheap-packages/cheap-packages.module').then((m) => m.CheapPackagesModule),
  },
  {
    matcher: (url) => {
      if (InactiveMultipleLandingPages.includes(url[0].path)) {
        const returnObject = {
          consumed: url,
          posParams: {
            landingPageId: new UrlSegment(url[0].path, {}),
          },
        };
        if (url.length === 2) {
          // @ts-ignore
          returnObject.posParams['username'] = new UrlSegment(url[1].path, {});
        }
        return returnObject;
      }
      return null;
    },
    component: HomeComponent,
    canActivate: [CustomLandingGuard],
  },
  {
    matcher: (url) => {
      if (url.length <= 2 && url[0].path.match(/^lakossagi[-_-].*$/)) {
        const returnObject = {
          consumed: url,
          posParams: {
            landingPageId: new UrlSegment(url[0].path, {}),
          },
        };
        if (url.length === 2) {
          // @ts-ignore
          returnObject.posParams['username'] = new UrlSegment(url[1].path, {});
        }
        return returnObject;
      }
      return null;
    },
    loadChildren: () =>
      import('./modules/landing-pages/multiple-packages/multiple-packages.module').then(
        (m) => m.MultiplePackagesModule
      ),
  },

  {
    path: 's/:shortUrl',

    component: HomeComponent,
    canActivate: [ShortUrlGuard],
  },
  {
    path: '404',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
  {
    path: ':username',
    loadChildren: () => import('./modules/personal-site/personal-site.module').then((m) => m.PersonalSiteModule),
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
