import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { DropdownLabel, DropdownType } from './dropdown.interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ose-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DropdownComponent), multi: true }],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  @Input() labelList: DropdownLabel[];
  @Input() type: DropdownType = 'sort';
  @Input() isDisabled = false;
  selectedLabel: DropdownLabel;

  defaultLabel: DropdownLabel = { name: 'Kérem válasszon...', value: null };

  opened = false;

  constructor() {}

  ngOnInit(): void {
    this.selectedLabel = this.labelList[0];
  }

  dropdownStatusChange(event: boolean): void {
    if (!this.isDisabled) this.opened = event;
  }

  onClickDropdownItem(item: DropdownLabel): void {
    if (!this.isDisabled) {
      this.selectedLabel = item;
      this.onChange(this.selectedLabel.value);
    }
  }

  onChange(fn: any): void {
    //
  }

  onTouched(fn: any): void {
    //
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(labelValue: string): void {
    const selectedLabel = this.labelList.find((label) => label.value === labelValue);
    this.selectedLabel = selectedLabel ?? this.defaultLabel;
  }
}
