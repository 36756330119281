import { Injectable } from '@angular/core';
import { IMultiplePackage } from './multiple-packages.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MultiplePackagesService {
  get packages(): IMultiplePackage[] {
    return this._packages;
  }

  private colors = {
    white: '#fff',
    orange: '#ffc300',
    grey: '#4D5357',
    black: '#000',
  };

  private _packages: IMultiplePackage[] = [
    // 100 perc 20 GB
    {
      landingPageId: 'lakossagi-100perc-20GB-2990Ft',
      images: {
        pcImage: 'lakossagi-100perc-20GB-2990Ft.webp',
        tabletImage: 'lakossagi-100perc-20GB-2990Ft-tablet.webp',
        mobileImage: 'lakossagi-100perc-20GB-2990Ft.webp',
      },
      icons: [
        {
          url: 'red_akcio_with_black_text.webp',
          positionAndSize: {
            pc: {
              top: 115,
              left: 500,
              width: 190,
              height: 169,
            },
            tablet: {
              top: 21,
              left: 510,
              width: 190,
              height: 169,
            },
            mobile: {
              top: 44,
              left: 252,
              width: 101,
              height: 90,
            },
          },
        },
      ],
      packageDetails: {
        name: '100 perc beszéd',
        internet: '20 GB',
        price: 2990,
        crmPackageId: '110082883',
        loyaltyMonth: 10,
      },
      colorDetails: {
        titleColor: this.colors.black,
        nameColor: this.colors.black,
        buttonType: 'black-with-white',
        equalsSignColor: this.colors.white,
        plusSignColor: this.colors.white,
        priceColor: this.colors.white,
        recommenderColor: this.colors.white,
        backgroundColor: '#fcc802',
      },
      meta: {
        meta_title: 'Keveset beszélsz, sokat netezel?',
        meta_description:
          'Örömmel értesítelek, hogy a Netfone mobilszolgáltató partnere vagyok, így személyesen tudlak Téged kiszolgálni akciós és kedvező mobiltelefon előfizetéseinkkel. ',
        meta_keywords:
          'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
        og_title: 'Keveset beszélsz, sokat netezel?',
        og_description: 'Én vagyok a személyes garancia, hogy jó döntést hozol, ha szerződést kötsz.',
        og_image: 'lakossagi-100perc-20GB-2990Ft-20230406.jpg',
        og_image_type: 'image/jpeg',
        og_image_width: '1200',
        og_image_height: '630',
      },
    },
    //
    // //4GB
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-4gb-3990Ft-ert',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-4gb-3990Ft-ert.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-4gb-3990Ft-ert.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-4gb-3990Ft-ert.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_black_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 130,
    //           left: 500,
    //           width: 170,
    //           height: 158,
    //         },
    //         tablet: {
    //           top: 40,
    //           left: 460,
    //           width: 132,
    //           height: 122,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 76,
    //           height: 70,
    //         },
    //       },
    //     },
    //   ],
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '4 GB',
    //     price: 3990,
    //     crmPackageId: '110082886',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'black-with-white',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.white,
    //     backgroundColor: '#fcb001',
    //   },
    //   meta: {
    //     meta_title: 'Csak annak ajánlom, aki tudatosan használja a mobilját és a pénzét.',
    //     meta_description:
    //       'Örömmel értesítelek, hogy a Netfone mobilszolgáltató partnere vagyok, így személyesen tudlak Téged kiszolgálni akciós és kedvező mobiltelefon előfizetéseinkkel. ',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Csak annak ajánlom, aki tudatosan használja a mobilját és a pénzét.',
    //     og_description: 'Én vagyok a személyes garancia, hogy jó döntést hozol, ha szerződést kötsz.',
    //     og_image: 'lakossagi-korlatlanmobil-4gb-3990Ft-ert-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-4gb-3990Ft',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-4gb-3990Ft.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-4gb-3990Ft.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-4gb-3990Ft.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_black_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 130,
    //           left: 500,
    //           width: 170,
    //           height: 158,
    //         },
    //         tablet: {
    //           top: 40,
    //           left: 460,
    //           width: 132,
    //           height: 122,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 76,
    //           height: 70,
    //         },
    //       },
    //     },
    //   ],
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '4 GB',
    //     price: 3990,
    //     crmPackageId: '110082886',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'black-with-white',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.white,
    //     backgroundColor: '#fcb001',
    //   },
    //   meta: {
    //     meta_title: 'Neked ajánlom, mert tudom, hogy tudatosan mobilozol',
    //     meta_description:
    //       'Örömmel értesítelek, hogy a Netfone mobilszolgáltató partnere vagyok, így személyesen tudlak Téged kiszolgálni akciós és kedvező mobiltelefon előfizetéseinkkel. ',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Neked ajánlom, mert tudom, hogy tudatosan mobilozol',
    //     og_description: 'Én vagyok a személyes garancia, hogy jó döntést hozol, ha szerződést kötsz.',
    //     og_image: 'lakossagi-korlatlanmobil-4gb-3990Ft-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    //
    // // 50GB
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_black_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 30,
    //           left: 500,
    //           width: 190,
    //           height: 169,
    //         },
    //         tablet: {
    //           top: 21,
    //           left: 510,
    //           width: 190,
    //           height: 169,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 101,
    //           height: 90,
    //         },
    //       },
    //     },
    //   ],
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '50 GB',
    //     price: 4990,
    //     crmPackageId: '110082795',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'secondary black',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.white,
    //     backgroundColor: '#dd8dad',
    //   },
    //   meta: {
    //     meta_title: 'Ezt láttad? 50GB korlátlan beszéddel 4990Ft itt Nála!',
    //     meta_description:
    //       'Rendeld meg kedvező mobilcsomagunkat és partnerünk személyesen segít Neked akár a szerződéskötésben, akár a jövőben is. Kattints, vagy hívd fel!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Ezt láttad? 50GB korlátlan beszéddel 4990Ft itt Nála!',
    //     og_description:
    //       'Korlátlan beszéd + 50GB internet 4990Ft-ért! Rendeld meg kedvező mobilcsomagunkat és partnerünk személyesen segít Neked akár a szerződéskötésben, akár a jövőben is. Kattints, vagy hívd fel!',
    //     og_image: 'lakossagi-korlatlanmobil-50gb-4990Ft-neked-most-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-50gb-4990Ft-most',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-most.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-most.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-most.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_black_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 180,
    //           left: 985,
    //           width: 190,
    //           height: 169,
    //         },
    //         tablet: {
    //           top: 21,
    //           left: 510,
    //           width: 190,
    //           height: 169,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 101,
    //           height: 90,
    //         },
    //       },
    //     },
    //   ],
    //
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '50 GB',
    //     price: 4990,
    //     crmPackageId: '110082795',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'secondary black',
    //     equalsSignColor: this.colors.orange,
    //     plusSignColor: this.colors.orange,
    //     priceColor: this.colors.orange,
    //     recommenderColor: this.colors.black,
    //     backgroundColor: '#fdfdfe',
    //   },
    //   meta: {
    //     meta_title: 'Na, ezt nézd! Korlátlan beszéd 50GB internettel 4990Ft-ért itt Tőlem megrendelhető!',
    //     meta_description:
    //       'Ez a mobilcsomag Neked való! Korlátlan beszélgetés, 50GB internet. Rendeld meg most a kedvező mobilcsomagokat nyújtó Netfone üzletkötőjétől!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Na, ezt nézd! Korlátlan beszéd 50GB internettel 4990Ft-ért itt Tőlem megrendelhető!',
    //     og_description:
    //       'Korlátlan beszélgetés mellé 50GB internetet adunk 4990Ft-ért. Ez a mobilcsomag Neked való! Rendeld meg itt üzletkötőnktől.',
    //     og_image: 'lakossagi-korlatlanmobil-50gb-4990Ft-most-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_black_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 20,
    //           left: 495,
    //           width: 165,
    //           height: 146,
    //         },
    //         tablet: {
    //           top: 21,
    //           left: 510,
    //           width: 190,
    //           height: 169,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 101,
    //           height: 90,
    //         },
    //       },
    //     },
    //   ],
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '50 GB',
    //     price: 4990,
    //     crmPackageId: '110082795',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'secondary black',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColorTop: this.colors.white,
    //     recommenderColor: this.colors.black,
    //     backgroundColor: '#f59fa3',
    //   },
    //   meta: {
    //     meta_title: 'Tátva marad a szád! Korlátlan mobilcsomag 50GB nettel 4990Ft!',
    //     meta_description:
    //       'Korlátlan mobilcsomag 50GB nettel 4990Ft-ért, hogy mindig online legyél! Kedvező mobil a Netfone üzletkötőjétől. Rendeld meg itt és most!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Tátva marad a szád! Korlátlan mobilcsomag 50GB nettel 4990Ft!',
    //     og_description:
    //       'Bárhol, akár a strandon is megkötheted 4990Ft-ért 50GB-os korlátlan csomagunkat, csak kattints! ',
    //     og_image: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-neked-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert.webp',
    //   },
    //   icons: [
    //     {
    //       url: 'red_akcio_with_white_text.webp',
    //       positionAndSize: {
    //         pc: {
    //           top: 15,
    //           left: 830,
    //           width: 190,
    //           height: 169,
    //         },
    //         tablet: {
    //           top: 21,
    //           left: 510,
    //           width: 190,
    //           height: 169,
    //         },
    //         mobile: {
    //           top: 44,
    //           left: 252,
    //           width: 101,
    //           height: 90,
    //         },
    //       },
    //     },
    //   ],
    //   packageDetails: {
    //     name: 'Korlátlan beszéd',
    //     internet: '50 GB',
    //     price: 4990,
    //     crmPackageId: '110082795',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.white,
    //     nameColor: this.colors.black,
    //     buttonType: 'black-with-white',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.black,
    //     backgroundColor: '#f4a503',
    //   },
    //   meta: {
    //     meta_title: 'Kiugróan jó ajánlat! Korlátlan beszélgetés 50GB nettel 4990Ft-ért!',
    //     meta_description:
    //       'Kiugróan jó ajánlat! Korlátlan beszélgetés 50GB nettel 4990Ft-ért! Rendeld meg itt a Netfone mobilszolgáltató üzletkötőjétől!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Kiugróan jó ajánlat! Korlátlan beszélgetés 50GB nettel 4990Ft-ért!',
    //     og_description: 'Korlátlan beszélgetés, 50GB internettel. 4990Ft-ért! Rendeld meg itt üzletkötőnktől!',
    //     og_image: 'lakossagi-korlatlanmobil-50gb-4990Ft-ert-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    //
    // // 100GB
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert.webp',
    //   },
    //   packageDetails: {
    //     name: 'Korlátlan beszéld',
    //     internet: '100 GB',
    //     price: 5990,
    //     crmPackageId: '110082853',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'black-with-white',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.black,
    //     backgroundColor: '#f3b6b8',
    //   },
    //   meta: {
    //     meta_title: 'A legmenőbb mobilcsomag! Korlátlan beszélgetés + 100GB = 5990Ft/hó',
    //     meta_description:
    //       'Legyél menő! Kattints és 5990Ft-ért kösd meg korlátlan beszélgetést és 100GB internetet tartalmazó új mobilcsomagodat üzletkötőnknél!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'A legmenőbb mobilcsomag! Korlátlan beszélgetés + 100GB = 5990Ft/hó',
    //     og_description:
    //       'Legyél menő! Kattints és 5990Ft-ért kösd meg akár számhordozással is csomagodat itt, üzletkötődnél!',
    //     og_image: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked.webp',
    //   },
    //   packageDetails: {
    //     name: 'Korlátlan beszéld',
    //     internet: '100 GB',
    //     price: 5990,
    //     crmPackageId: '110082853',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.white,
    //     buttonType: 'secondary black',
    //     equalsSignColor: this.colors.orange,
    //     plusSignColor: this.colors.orange,
    //     priceColor: this.colors.orange,
    //     recommenderColor: this.colors.white,
    //     backgroundColor: '#3398a6',
    //   },
    //   meta: {
    //     meta_title: 'Ezt már láttad? Korlátlan mobil 100GB internettel 5990Ft/hó! Kösd meg most!',
    //     meta_description:
    //       'Ezt már láttad? Menő kedvező mobilcsomag. Rendeld meg Te is most a kedvező mobilcsomagokat nyújtó Netfone üzletkötőjétől!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Ezt már láttad? Korlátlan mobil 100GB internettel 5990Ft/hó! Kösd meg most!',
    //     og_description: 'Menő kedvező mobilcsomag! Korlátlan beszélgetés 100GB internettel 5990Ft/hó.',
    //     og_image: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-neked-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most.webp',
    //   },
    //   packageDetails: {
    //     name: 'Korlátlan beszéld',
    //     internet: '100 GB',
    //     price: 5990,
    //     crmPackageId: '110082853',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.orange,
    //     nameColor: this.colors.white,
    //     buttonType: 'secondary',
    //     equalsSignColor: this.colors.orange,
    //     plusSignColor: this.colors.orange,
    //     priceColor: this.colors.orange,
    //     recommenderColor: this.colors.white,
    //     backgroundColor: '#b290cf',
    //   },
    //   meta: {
    //     meta_title: 'Hoppá! Ezt kerested! Korlátlan beszéd + 100GB net = 5990Ft/hó. Kattints!',
    //     meta_description:
    //       'Koráltan beszélgetés mellé 100GB internetet adunk 5990Ft/hó áron. Ezt a Netfone személyes üzletkötőjén, azaz rajtam keresztül is meg tudod rendelni. Kattints!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Nekünk ez kell! Alacsony havidíjas mobilcsomagot rendelj itt!',
    //     og_description:
    //       'Hoppá! Megtaláltad! 100GB internet a korlátlan beszélgetés mellé is csak 5990Ft-ért. Rendeld meg most! Rendeld meg itt üzletkötőnktől.',
    //     og_image: 'lakossagi-korlatlanmobil-100gb-5990Ft-ert-most-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
    // {
    //   landingPageId: 'lakossagi-korlatlanmobil-100gb-5990Ft',
    //   images: {
    //     pcImage: 'lakossagi-korlatlanmobil-100gb-5990Ft.webp',
    //     tabletImage: 'lakossagi-korlatlanmobil-100gb-5990Ft.webp',
    //     mobileImage: 'lakossagi-korlatlanmobil-100gb-5990Ft.webp',
    //   },
    //   packageDetails: {
    //     name: 'Korlátlan beszéld',
    //     internet: '100 GB',
    //     price: 5990,
    //     crmPackageId: '110082853',
    //     loyaltyMonth: 10,
    //   },
    //   colorDetails: {
    //     titleColor: this.colors.black,
    //     nameColor: this.colors.black,
    //     buttonType: 'secondary secondary-white',
    //     equalsSignColor: this.colors.white,
    //     plusSignColor: this.colors.white,
    //     priceColor: this.colors.white,
    //     recommenderColor: this.colors.black,
    //     backgroundColor: '#f6b0c0',
    //   },
    //   meta: {
    //     meta_title: 'Hoppá! Ezt kerested! Korlátlan beszéd + 100GB net = 5990Ft/hó. Kattints!',
    //     meta_description:
    //       'Koráltan beszélgetés mellé 100GB internetet adunk 5990Ft/hó áron. Ezt a Netfone személyes üzletkötőjén, azaz rajtam keresztül is meg tudod rendelni. Kattints!',
    //     meta_keywords:
    //       'lefedettség, térerő, telefonálás, internet, giga, gigaerős, 3G,  4G, 5G, mobil, flotta, telefon, korlátlan, iphone, samsung, egyéni előfizetés, üzleti előfizetés,  percdíj, havidíj, hűség, hűség nélkül',
    //     og_title: 'Hoppá! Ezt kerested! Korlátlan beszéd + 100GB net = 5990Ft/hó. Kattints!',
    //     og_description:
    //       'Hoppá! Megtaláltad! 100GB internet a korlátlan beszélgetés mellé is csak 5990Ft-ért. Rendeld meg most! Rendeld meg itt üzletkötőnktől.',
    //     og_image: 'lakossagi-korlatlanmobil-100gb-5990Ft-20230307.jpg',
    //     og_image_type: 'image/jpeg',
    //     og_image_width: '1200',
    //     og_image_height: '630',
    //   },
    // },
  ];
}
