import { Injectable } from '@angular/core';
import { MultiplePackagesService } from '../../../modules/landing-pages/multiple-packages/multiple-packages.service';
import { MenuStyleEnum } from '../../../global/global.enums';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _urlsWithReducedDesign = [
    'netfone-partner',
    'netfone-korlatlan-mobil-50gb-6600ft',
    'netfone-korlatlan-mobil-100gb-7600ft',
    'netfone-olcso-mobilcsomagok-nalam',
  ];

  constructor(private multiplePackagesService: MultiplePackagesService) {}

  getMenuDisplayModeFromUrl(url: string): MenuStyleEnum {
    if (this.multiplePackagesService.packages.find((item) => item.landingPageId === url)) {
      return MenuStyleEnum.REDUCED;
    }
    if (this._urlsWithReducedDesign.includes(url.split('/')[0])) {
      return MenuStyleEnum.REDUCED;
    }
    return MenuStyleEnum.NORMAL;
  }
}
