export const strings = (() => {
  /**
   * Check if any of the values are contains spaces only
   * @param fields [array]
   */
  const hasOnlyWhitespaces = (fields: string[]): boolean => {
    let status = true;
    fields.forEach((item) => {
      if (typeof item === 'string') {
        if (item.trim().length === 0) {
          status = false;
        }
      }
    });
    return status;
  };

  /**
   * Byte to Kilobyte conversion
   * @param size
   */
  const convertToKB = (size: number): number => {
    return (Math.round(size / 1024) * 100) / 100;
  };

  /**
   * trim around
   * @value string
   */
  const trim = (value: any): string => (typeof value === 'string' ? value.trim() : value);

  /**
   *
   * @url url
   * @params params
   */
  const addUrlParams = (url: string, params: object): string => {
    let urlWithParams = url;
    const uparams = url.replace(/\//g, '').split(':');
    uparams.forEach((urlParam) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (params[urlParam] !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        urlWithParams = urlWithParams.replace(`:${urlParam}`, params[urlParam]);
      }
    });
    return `${urlWithParams}/`;
  };

  const replaceURLParams = (url: string, params: object): string => {
    let replacedUrl = url;
    Object.keys(params).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      replacedUrl = replacedUrl.replace(`:${key}`, params[key]);
    });
    return replacedUrl;
  };

  const addQueryParams = (url: string, params: object): string => {
    let replacedUrl = url + '?';
    Object.keys(params).forEach((key, index) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      replacedUrl += `${key}=${params[key]}`;
      if (index < Object.keys(params).length - 1) {
        replacedUrl += '&';
      }
    });
    return replacedUrl;
  };

  /**
   * Replaces multiple spaces to a single one
   * @value
   */
  const singleSpaces = (value: string): string => value.replace(/ +/g, ' ');

  /**
   * Random text generation
   * @param radix
   * @param startCut
   * @param endCut
   */
  const randomText = (radix = 36, startCut = 2, endCut = 15): string =>
    Math.random().toString(radix).substring(startCut, endCut) +
    Math.random().toString(radix).substring(startCut, endCut);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sort = (source, sortBy, dir: 'asc' | 'desc' = 'asc') => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sortedSource = source.sort((obj1, obj2) =>
      obj1[sortBy].toLocaleLowerCase().localeCompare(obj2[sortBy].toLocaleLowerCase())
    );

    return dir === 'asc' ? sortedSource : sortedSource.reverse();
  };

  return {
    addUrlParams,
    replaceURLParams,
    addQueryParams,
    hasOnlyWhitespaces,
    randomText,
    convertToKB,
    singleSpaces,
    sort,
    trim,
  };
})();
