import { ApplicationRef, DoBootstrap, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { AuthService } from './core/services/auth-service/auth.service';
import { KEYCLOAK } from './core/injection-token/keycloak-token';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from './shared/components/notification/notification.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [],
  imports: [AppModule, SharedModule, KeycloakAngularModule],
  providers: [
    {
      provide: KEYCLOAK,
      useExisting: KeycloakService,
    },
  ],
})
export class KeycloakModule implements DoBootstrap {
  constructor(
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object,
    private notificationService: NotificationService
  ) {}

  ngDoBootstrap(appRef: ApplicationRef): void {
    if (isPlatformBrowser(this.platformId) && !this.authService.initCalled) {
      this.authService.initKeycloak().then((result) => {
        if (!result.isSuccessful) {
          this.notificationService.error('Az authentikációs szerver nem elérhető! Kérjük próbálkozzon később!');
        }
      });
    }
    appRef.bootstrap(AppComponent);
  }
}
