import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageService } from '../../../modules/landing-pages/landing-page.service';
import { FindUserByNicknevResponseDto } from '../../../modules/personal-site/personal-site.interfaces';
import { FormControl, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { SocialShareService } from '../../services/social-share/social-share.service';
import { GetShortUrlResponseDto } from '../../interfaces/url-shortener.interfaces';
import { UrlShortenerService } from '../../services/url-shortener/url-shortener.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'ose-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent implements OnInit, OnDestroy {
  @ViewChild('shareDialogTemplate') shareDialogTemplate: TemplateRef<unknown>;

  currentShareUrl: string;
  recommender: FindUserByNicknevResponseDto;
  displayNewRecommenderInput: boolean;

  recommenderNickname = new FormControl(null, Validators.required);
  noUserFound: boolean;
  modalReference: NgbModalRef;

  copied: boolean;
  isShortUrl: boolean;

  private _destroy$ = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private landingPageService: LandingPageService,
    @Inject(PLATFORM_ID) private platformId: object,
    private socialShareService: SocialShareService,
    private urlShortenerService: UrlShortenerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.socialShareService.copied.next(false);
    this.socialShareService.copiedAsObservable.subscribe((newVal) => {
      this.copied = newVal;
    });
  }

  open(urlToShare?: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.socialShareService.innerWidth = window.innerWidth;
    }
    this.recommender = this.landingPageService.getRecommender();
    this.recommenderNickname.reset();

    if (urlToShare) {
      this.isShortUrl = false;
      this.currentShareUrl = urlToShare;
      return;
    }
    this.isShortUrl = true;
    this.setCurrentShareUrl();

    this.modalReference = this.modalService.open(this.shareDialogTemplate, {
      backdrop: true,
      keyboard: false,
      centered: true,
      windowClass: 'share-dialog',
    });
    this.modalReference.result.then(
      () => {
        this.setRecommenderDisplayVisibility(false);
      },
      () => {
        this.setRecommenderDisplayVisibility(false);
      }
    );
  }

  setRecommenderDisplayVisibility(visible: boolean): void {
    this.displayNewRecommenderInput = visible;
    this.recommender = null;
  }

  shareToMessenger(): void {
    this.socialShareService.shareToMessenger(this.currentShareUrl);
  }

  shareToViber(): void {
    this.socialShareService.shareToViber(this.currentShareUrl);
  }

  shareToFacebook(): void {
    this.socialShareService.shareToFacebook(this.currentShareUrl);
  }

  shareToWhatsapp(): void {
    this.socialShareService.shareToWhatsapp(this.currentShareUrl);
  }

  shareToTelegram(): void {
    this.socialShareService.shareToTelegram(this.currentShareUrl);
  }

  copyShortUlr(): void {
    this.socialShareService.copyShortUlr(this.currentShareUrl);
  }

  close(): void {
    this.modalReference.close('X');
  }

  validateNewRecommender(): void {
    this.landingPageService.findUserByNickname({ nicknev: this.recommenderNickname.value }).subscribe(
      (user) => {
        this.recommender = user;
        this.landingPageService.setRecommender(this.recommender);
        this.noUserFound = false;
        if (this.isShortUrl) {
          this.setCurrentShareUrl();
        }
      },
      () => {
        this.noUserFound = true;
      }
    );
  }

  removeRecommender(): void {
    this.landingPageService.deleteRecommender();
    this.setRecommenderDisplayVisibility(false);
    this.setCurrentShareUrl();
  }

  private setCurrentShareUrl(): void {
    this.urlShortenerService
      .shortenUrl({
        longUrl: this.router.url,
        recommender: this.recommender?.nicknev,
      })
      .subscribe((response: GetShortUrlResponseDto) => {
        this.currentShareUrl = this.socialShareService.generateCurrentUrl(response.shortUrl);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
