import { Inject, Injectable } from '@angular/core';
import {
  IDeviceStructuredDataSchema,
  IOrgSchema,
  IFranchisePartnerStructuredDataSchema,
  SchemaInterfaces,
  IVeaResellerShopStructuredDataSchema,
  ILandingPageStructuredDataDTO,
  ILandingPageStructuredDataSchema,
} from '../../interfaces/json-ld.interfaces';
import { DOCUMENT } from '@angular/common';
import { FindBaseDeviceDetailDataResponeDto, PhoneDetailsColorDTO } from '../../../modules/devices/phone.interfaces';
import { GlobalConstants } from '../../../global/global.constants';
import { FindUserByNicknevResponseDto } from '../../../modules/personal-site/personal-site.interfaces';
import { ExposedEnvironmentVariable } from '../../../../environments/environment-variables';
import { EnvironmentVariablesService } from '../../../core/services/environment-variable-service/environment-variables.service';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  scriptType = 'application/json+ld';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private environmentVariablesService: EnvironmentVariablesService
  ) {}

  /**
   * Get the organization json ld schema with data
   */

  static orgSchema = (): IOrgSchema => ({
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    url: 'www.netfone.hu',
    image: [
      'https://netfone.hu/assets/og/Netfone_nyitooldal_telefon_egyszerubben_2022_1.jpg',
      'https://netfone.hu/assets/og/Netfone_Mobil_Szolgaltatas_2022_1.jpg',
    ],
    name: 'Netfone Telecom',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Budapest, Magyarország',
      postalCode: '1119',
      streetAddress: 'Mohai út 38, M38 Irodaház',
    },
    email: 'ugyfelszolgalat@netfone.hu',
    description:
      'Mi leszünk az új mobilszolgáltatód! Korlátlan és havidíjas csomagokat kínálunk a legjobb áron. Tökéletes lefedettség Magyarországon és külföldön!',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+3618781800',
      contactType: 'Ügyfélszolgálat',
    },
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['https://schema.org/Monday', 'https://schema.org/Tuesday'],
        opens: '10:00',
        closes: '17:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'https://schema.org/Wednesday',
        opens: '10:00',
        closes: '20:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['https://schema.org/Thursday', 'https://schema.org/Friday'],
        opens: '10:00',
        closes: '17:00',
      },
    ],
    sameAs: ['https://www.facebook.com/netfone.hu', 'https://www.instagram.com/netfone_official'],
  });

  /**
   * Get the device json ld data
   * @param deviceDetails
   * @param imageUrl
   * @param deviceUrl
   * @param activeColor
   */
  generateProductStructuredData(
    deviceDetails: FindBaseDeviceDetailDataResponeDto,
    imageUrl: string,
    deviceUrl: string,
    activeColor: PhoneDetailsColorDTO
  ): IDeviceStructuredDataSchema {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: deviceDetails.keszulek.megnevezes,
      image: imageUrl,
      url: deviceUrl,
      color: activeColor.szin,
      description: deviceDetails.keszulek.rovidLeiras,
      brand: {
        '@type': 'Thing',
        name: deviceDetails.keszulek.gyarto,
      },
      offers: {
        '@type': 'Offer',
        priceCurrency: 'HUF',
        price: deviceDetails.keszulek.listaAr.toString(),
        itemCondition: 'http://schema.org/NewCondition',
        availability: 'http://schema.org/InStock',
        seller: {
          '@type': 'LocalBusiness',
          name: 'Netfone Telecom',
        },
      },
    };
  }

  /**
   * Get franchise partner structured data
   * @param user
   */
  generateFranchisePartnerStructuredData(user: FindUserByNicknevResponseDto): IFranchisePartnerStructuredDataSchema {
    return {
      '@context': 'https://schema.org',
      '@type': 'Person',
      address: {
        '@type': 'PostalAddress',
        addressLocality: user.telepules,
      },
      email: `mailto:${user.email}`,
      image: `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.PROFILKEP_URL)}/${user.nicknev}.jpg`,
      name: user.nev,
      telephone: user.telefonszam,
      url: `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL)}/${user.nicknev}`,
      description: user.motto,
    };
  }

  /**
   * Get vea shop structured json ld data
   * @param shop
   */
  generateVeaResellerShopStructuredData(shop: FindUserByNicknevResponseDto): IVeaResellerShopStructuredDataSchema {
    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      address: {
        '@type': 'PostalAddress',
        addressLocality: shop.telepules,
      },
      email: `mailto:${shop.email}`,
      image: `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.PROFILKEP_URL)}/${shop.nicknev}.jpg`,
      name: shop.nev,
      telephone: shop.telefonszam,
      url: `${this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL)}/${shop.nicknev}`,
      description: shop.motto,
    };
  }

  /**
   * Get landingpage json ld data
   * @param service
   */
  generateLandingPageStructuredData(service: ILandingPageStructuredDataDTO): ILandingPageStructuredDataSchema {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: service.name,
      image: service.image,
      url: service.url,
      description: service.description ?? '',
      offers: {
        '@type': 'Offer',
        priceCurrency: 'HUF',
        price: service.price.toString(),
        seller: {
          '@type': 'LocalBusiness',
          name: 'Netfone Telecom',
        },
      },
    };
  }

  removeStructuredData(id: string): void {
    const item = this._document.head.querySelector(`#${id}`);
    if (item) {
      this._document.head.removeChild(item);
    }
  }

  /**
   * Update the json ld tag with the current page data
   * @param data
   */
  addOrUpdateStructuredData(data: SchemaInterfaces): void {
    const item = this._document.head.querySelector(`#${GlobalConstants.structuredDataId}`);
    if (!item) {
      const script = this._document.createElement('script');
      script.setAttribute('id', GlobalConstants.structuredDataId);
      script.type = this.scriptType;
      script.text = JSON.stringify(data);
      this._document.head.appendChild(script);
    } else {
      (this._document.head.querySelector(
        `#${GlobalConstants.structuredDataId}`
      ) as HTMLScriptElement).text = JSON.stringify(data);
    }
  }
}
