import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { XhrService } from '../xhr-service/xhr.service';
import { Observable, ReplaySubject } from 'rxjs';
import { KEYCLOAK } from '../../injection-token/keycloak-token';
import { GetLoggedInUserResponseDto } from '../../../modules/partners/profile/profile.interfaces';
import { EnvironmentVariablesService } from '../environment-variable-service/environment-variables.service';
import { ExposedEnvironmentVariable } from '../../../../environments/environment-variables';
import { KeycloakOptions } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { ProfileService } from '../../../modules/partners/profile/profile.service';
import { KeycloakInitResult } from './auth.interfaces';
import { LandingPageService } from '../../../modules/landing-pages/landing-page.service';
import { WINDOW } from '../../../shared/services/window/window.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated = false;
  private _loggedInUser: GetLoggedInUserResponseDto;
  private _initCalled$ = new ReplaySubject<boolean>(1);
  initCalled = false;
  private _showAuthLoading = new ReplaySubject<boolean>(1);

  constructor(
    private xhr: XhrService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(WINDOW) private window: Window,
    private injector: Injector,
    private environmentVariablesService: EnvironmentVariablesService,
    private profileService: ProfileService,
    private landingPageService: LandingPageService
  ) {}

  get loggedInUser(): GetLoggedInUserResponseDto {
    return this._loggedInUser;
  }

  set loggedInUser(loggedInUser: GetLoggedInUserResponseDto) {
    this._loggedInUser = loggedInUser;
  }

  getInitCalledAsObservable(): Observable<boolean> {
    return this._initCalled$.asObservable();
  }

  initIsCalled(): void {
    this._initCalled$.next(this.initCalled);
  }

  login(): void {
    this.injector.get(KEYCLOAK).login();
  }

  logout(): void {
    this.injector.get(KEYCLOAK).logout(this.environmentVariablesService.getVar(ExposedEnvironmentVariable.NETFONE_URL));
  }

  initKeycloak(): Promise<KeycloakInitResult> {
    this.initCalled = true;
    const locationObject: Location = this.window.location;
    const options: KeycloakOptions = {
      ...environment.keycloak,
      initOptions: {
        ...environment.keycloak.initOptions,
        silentCheckSsoRedirectUri: `${locationObject.origin}/silent-check-sso.html`,
      },
      config: {
        realm: this.environmentVariablesService.getVar(ExposedEnvironmentVariable.KEYCLOAK_REALM),
        clientId: this.environmentVariablesService.getVar(ExposedEnvironmentVariable.KEYCLOAK_CLIENT_ID),
        url: this.environmentVariablesService.getVar(ExposedEnvironmentVariable.KEYCLOAK_URL),
      },
    };

    const keycloakService = this.injector.get(KEYCLOAK);

    return Promise.race([
      keycloakService.init(options),
      new Promise((resolve, reject) => {
        setTimeout(() => reject(), 5000);
      }),
    ])
      .then(
        (initResult) => {
          return keycloakService.isLoggedIn();
        },
        (reason) => {
          return new Promise((resolve, reject) => {
            reject({ isSuccessful: false });
          });
        }
      )
      .then(
        (isLoggedIn) => {
          if (isLoggedIn) {
            this.isAuthenticated = true;
            this.profileService.loggedInUser().subscribe((response) => {
              this.loggedInUser = response;
              this.landingPageService.setRecommenderAfterLogin(response);
            });
          }
          return { isSuccessful: true };
        },
        (rejectionValue) => {
          return rejectionValue;
        }
      );
  }

  sendAuthLoadingEvent(isAuthLoading: boolean): void {
    this._showAuthLoading.next(isAuthLoading);
  }

  getAuthLoadingAsObservable(): Observable<boolean> {
    return this._showAuthLoading.asObservable();
  }
}
