import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../../../shared/components/notification/notification.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../global/global.constants';

@Injectable({
  providedIn: 'root',
})
export class XhrService {
  constructor(private http: HttpClient, private injector: Injector) {}

  get<T>(url: string, options?: object, OnSuccess?: string, ShowError = true): Observable<T> {
    return this.http
      .get<T>(url, { ...options })
      .pipe(
        tap((result) => {
          if (OnSuccess) {
            this.handleSuccessNotification(OnSuccess);
          }
        }),
        catchError((err) => {
          if (ShowError) {
            this.handlerErrorNotification(err);
          }
          return throwError(err);
        })
      );
  }

  post<T>(url: string, body: any, options?: object, OnSuccess?: string, ShowError = true): Observable<T> {
    return this.http
      .post<T>(url, body, { ...options })
      .pipe(
        tap((result) => {
          if (OnSuccess) {
            this.handleSuccessNotification(OnSuccess);
          }
        }),
        catchError((err) => {
          if (ShowError) {
            this.handlerErrorNotification(err);
          }
          return throwError(err);
        })
      );
  }

  private get router(): Router {
    return this.injector.get<Router>(Router);
  }

  private get notificationService(): NotificationService {
    return this.injector.get<NotificationService>(NotificationService);
  }

  private handleSuccessNotification(OnSuccess: string): void {
    this.notificationService.success(OnSuccess);
  }

  private handlerErrorNotification(error: HttpErrorResponse): void {
    //Ha 401: kijelentkeztetés
    if (error.status === 401) {
      sessionStorage.clear();
      this.router.navigate(['']);
      this.notificationService.error(GlobalConstants.ErrorMessages.UNAUTHORISED);
      return;
    }

    const errorContent: { errorType: string } = error.error;

    if (errorContent?.errorType) {
      //Alap hibakezelés
      if (errorContent.errorType in GlobalConstants.BackendMessages) {
        const castedErrorType = errorContent.errorType as keyof typeof GlobalConstants.BackendMessages;

        this.notificationService.error(GlobalConstants.BackendMessages[castedErrorType]);
        return;
      } else if (!(errorContent.errorType in GlobalConstants.FormErrors)) {
        this.notificationService.error(GlobalConstants.ErrorMessages.UNEXPECTED_ERROR);
        return;
      }
    } else {
      // Nem lekezelt hiba
      this.notificationService.error(GlobalConstants.ErrorMessages.UNEXPECTED_ERROR);
      return;
    }
  }
}
