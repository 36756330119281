import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { XhrService } from '../xhr-service/xhr.service';
import { EnvironmentVariablesService } from '../environment-variable-service/environment-variables.service';
import { isPlatformBrowser } from '@angular/common';
import { MobileServicesService } from '../../../modules/mobile-services/mobile-services.service';

export interface InitResponseDto {
  keszulekUrl: string;
  mobilhaloUrl: string;
  netfoneUrl: string;
  profilKepUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class InitService {
  initResponse: InitResponseDto;

  constructor(
    private xhrService: XhrService,
    private mobileServicesService: MobileServicesService,
    private environmentVariablesService: EnvironmentVariablesService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  initCall(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      return new Promise((resolve) => {
        this.mobileServicesService.findAllMobileService().subscribe((servicesResult) => {
          this.mobileServicesService.allMobileServices = servicesResult;
          resolve();
        });
      });
    }
    return new Promise((resolve) => {
      resolve();
    });
  }
}
