<div class="card-wrapper" [class]="classNameByStyle">
  <div class="card-header">
    <div class="package-type" [class]="{ smaller: data.arTipus === 'EU_KORLATLAN' }">{{ headerTitleData }}</div>
    <div class="internet">{{ data.bennfoglaltNet }}</div>
  </div>
  <div class="card-details">
    <div class="price">
      <div class="amount">{{ serviceDetails?.haviDij | number: '3.' }}</div>
      <div class="unit">Ft/hó</div>
    </div>
    <div class="description" [innerHTML]="descriptionData"></div>
    <div class="icons-wrapper">
      <ng-container *ngIf="!(serviceDetails?.styleId || isWithoutLoyalty); else hasIcon">
        <div class="icon-placeholder"></div>
      </ng-container>

      <ng-template #hasIcon>
        <img
          *ngIf="serviceDetails?.styleId === 'sale'"
          src="assets/images/akcio_ikon_piros_szeles.svg"
          alt="Akció"
          class="icon sale"
        />
        <img
          *ngIf="isWithoutLoyalty"
          src="assets/images/witout_loyalty_orange_icon_black_text.svg"
          alt="Hűségidő nélkül"
          class="icon without-loyalty"
        />
      </ng-template>
    </div>
    <button class="new-netfone-button secondary black" (click)="order()">EZT KÉREM</button>
  </div>
</div>
