import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryDate',
})
export class DeliveryDatePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (+value <= 0) {
      return 'Azonnal';
    }
    return `${value} munkanap`;
  }
}
