import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MobilePackageDetails } from '../../shared/components/large-mobile-package/large-mobile-package.interfaces';
import { MobileServicesService } from './mobile-services.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IMobileServiceResolverData } from './mobile-services.interface';
import { SHARED_MOBILE_PACKAGES } from '../../global/mobile-services.constants';

@Component({
  selector: 'app-mobile-services',
  templateUrl: './mobile-services.component.html',
  styleUrls: ['./mobile-services.component.scss'],
})
export class MobileServicesComponent implements OnInit, OnDestroy {
  packages: MobilePackageDetails[] = SHARED_MOBILE_PACKAGES;

  innerWidth: number;
  allServicesData: IMobileServiceResolverData;
  private $destroy = new Subject();

  constructor(
    private mobileServicesService: MobileServicesService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }

    this.activatedRoute.data
      .pipe(
        takeUntil(this.$destroy),
        map((data) => data.data)
      )
      .subscribe((data: IMobileServiceResolverData) => {
        this.allServicesData = data;
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = (event.target as Window).innerWidth;
  }

  order(packageItem: MobilePackageDetails): void {
    this.mobileServicesService.order(packageItem);
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
