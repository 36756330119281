import { FindUserByNicknevResponseDto } from '../modules/personal-site/personal-site.interfaces';

export const PartnerplusConstants: FindUserByNicknevResponseDto[] = [
  {
    crmAzonosito: 160882,
    nev: 'EMAG',
    isUnregisteredUser: true,
    hideAsRecommender: false,
  },
  {
    crmAzonosito: 172580,
    nev: 'Thinksmart Kft.',
    isUnregisteredUser: true,
    hideAsRecommender: false,
  },
  {
    crmAzonosito: 184151,
    nicknev: 'billkiller',
    isUnregisteredUser: true,
    hideAsRecommender: true,
  },
  {
    crmAzonosito: 256841,
    nev: 'JupiNet Távközlési Kft.',
    isUnregisteredUser: true,
    hideAsRecommender: false,
    displayOnlyName: true,
  },
];
