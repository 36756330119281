import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { CookieStorageService } from '../../../core/services/cookie-storage-service/cookie-storage.service';
import { FindUserByNicknevResponseDto } from '../../../modules/personal-site/personal-site.interfaces';
import { GlobalConstants } from '../../../global/global.constants';
import { SessionStorageService } from '../../../core/services/session-storage-service/session-storage.service';
import { CookieAcceptanceStatusEnum } from '../../../global/global.enums';

@Component({
  selector: 'ose-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CookieDialogComponent {
  @Output() cookieAccepted = new EventEmitter<CookieAcceptanceStatusEnum>();
  constructor(
    private cookieStorageService: CookieStorageService,
    private sessionStorageService: SessionStorageService
  ) {}

  confirm(): void {
    this.cookieStorageService.setItem(GlobalConstants.cookieAcceptedKey, CookieAcceptanceStatusEnum.ACCEPTED, 365);
    const recommender = this.sessionStorageService.getObject<FindUserByNicknevResponseDto>(
      GlobalConstants.sessionStorageKeys.recommender
    );
    const simRecommender = this.sessionStorageService.getObject<FindUserByNicknevResponseDto>(
      GlobalConstants.sessionStorageKeys.simRecommender
    );
    if (recommender) {
      this.cookieStorageService.setItem(GlobalConstants.sessionStorageKeys.recommender, JSON.stringify(recommender), 3);
    }
    if (simRecommender) {
      this.cookieStorageService.setItem(
        GlobalConstants.sessionStorageKeys.simRecommender,
        JSON.stringify(simRecommender),
        3
      );
    }
    this.cookieAccepted.emit(CookieAcceptanceStatusEnum.ACCEPTED);
  }
}
