import { Injectable } from '@angular/core';
import { SessionStorageService } from '../../core/services/session-storage-service/session-storage.service';
import { GlobalConstants } from '../../global/global.constants';
import {
  InstallmentHasChangedEvent,
  SelectedPhonePackageDetails,
  ServiceHasChangedEvent,
} from './selected-phone-package.interfaces';
import { Observable, Subject } from 'rxjs';
import { DslServicesService } from '../dsl-service/dsl-services.service';

@Injectable({
  providedIn: 'root',
})
export class SelectedPhonePackageService {
  selectedPhonePackageDetails: SelectedPhonePackageDetails;
  private _selectedServiceHasChanged$ = new Subject<SelectedPhonePackageDetails>();

  constructor(private sessionStorageService: SessionStorageService, private dslServicesService: DslServicesService) {
    const selectedPhonePackageDetailsFromSS = this.sessionStorageService.getObject<SelectedPhonePackageDetails>(
      GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails
    );
    if (selectedPhonePackageDetailsFromSS) {
      this.selectedPhonePackageDetails = selectedPhonePackageDetailsFromSS;
    }
  }

  setSelectedPhonePackageDetails(phonePackageDetails: SelectedPhonePackageDetails, emitEvent = true): void {
    this.selectedPhonePackageDetails = phonePackageDetails;
    this.sessionStorageService.setItem(
      GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails,
      JSON.stringify(this.selectedPhonePackageDetails)
    );

    if (emitEvent) {
      this._selectedServiceHasChanged$.next(this.selectedPhonePackageDetails);
    }
  }

  updateSelectedPhonePackageDetails(phonePackageDetails: Partial<SelectedPhonePackageDetails>, emitEvent = true): void {
    this.selectedPhonePackageDetails = { ...this.selectedPhonePackageDetails, ...phonePackageDetails };
    this.sessionStorageService.setItem(
      GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails,
      JSON.stringify(this.selectedPhonePackageDetails)
    );

    if (emitEvent) {
      this._selectedServiceHasChanged$.next(this.selectedPhonePackageDetails);
    }
  }

  getSelectedPhonePackageDetails(): SelectedPhonePackageDetails {
    if (this.selectedPhonePackageDetails) {
      return this.selectedPhonePackageDetails;
    }
    const objectFromSS = this.sessionStorageService.getObject<SelectedPhonePackageDetails>(
      GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails
    );

    let result: SelectedPhonePackageDetails = null;

    if (objectFromSS) {
      result = {
        crmAzonosito: +objectFromSS.crmAzonosito,
        husegIdo: +objectFromSS.husegIdo,
        reszletFutamido:
          objectFromSS.reszletFutamido == null || objectFromSS.reszletFutamido + '' == '0'
            ? null
            : +objectFromSS.reszletFutamido,
      };
    }
    return result;
  }

  get selectedServiceHasChanged(): Observable<SelectedPhonePackageDetails> {
    return this._selectedServiceHasChanged$.asObservable();
  }

  clearSelectedPhonePackageDetails(): void {
    this.selectedPhonePackageDetails = null;
    this.removeSelectedPhonePackageDetailsFromSS();
  }

  removeSelectedPhonePackageDetailsFromSS(): void {
    this.sessionStorageService.removeItem(GlobalConstants.sessionStorageKeys.selectedMobileServiceDetails);
  }
}
