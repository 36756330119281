import { Injectable } from '@angular/core';
import { XhrService } from '../../core/services/xhr-service/xhr.service';
import { API } from '../../global/API.constants';
import {
  FindAllMobileServiceRequestDto,
  FindAllMobileServiceResponseDto,
  FindMobileServiceByCRMResponseDto,
  ServicePricingDto,
} from './mobile-services.interface';
import { Observable, Subject } from 'rxjs';
import { SelectedPhonePackageDetails } from '../devices/selected-phone-package.interfaces';
import { OrderService } from '../order/order.service';
import { Router } from '@angular/router';
import { SelectedPhonePackageService } from '../devices/selected-phone-package.service';
import { GoogleAnalyticsService } from '../../core/services/google-analytics-service/google-analytics.service';
import { InternetTypesEnum, OrderTypeQueryParamEnum } from '../../global/global.enums';
import { FindByCRMRequestDto } from '../../global/global.interfaces';
import { MobilePackageDetails } from '../../shared/components/large-mobile-package/large-mobile-package.interfaces';
import { IOrderUrlMobileAndDslServiceQueryParams, IOrderUrlQueryParams } from '../order/order.interfaces';

@Injectable({
  providedIn: 'root',
})
export class MobileServicesService {
  private _allMobileServices: FindAllMobileServiceResponseDto[] = [];

  get allMobileServices(): FindAllMobileServiceResponseDto[] {
    return this._allMobileServices;
  }
  private updatedMobileOrderObject = new Subject<boolean>();

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set allMobileServices(value: FindAllMobileServiceResponseDto[]) {
    value.forEach((item) => {
      item.bennfoglaltNet = item.bennfoglaltNet === 'Korlátlan' ? InternetTypesEnum.INFINITE : item.bennfoglaltNet;
    });
    this._allMobileServices = value;
  }

  constructor(
    private xhr: XhrService,
    private orderService: OrderService,
    private router: Router,
    private selectedPhonePackageService: SelectedPhonePackageService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  findAllMobileService(request?: FindAllMobileServiceRequestDto): Observable<FindAllMobileServiceResponseDto[]> {
    return this.xhr.get(API.services.findAllMobile, { params: request });
  }

  findMobileServiceByCRM(request: FindByCRMRequestDto): Observable<FindMobileServiceByCRMResponseDto> {
    return this.xhr.post<FindMobileServiceByCRMResponseDto>(API.services.findByCRM, request);
  }

  findMobileServiceByCrmId(crmId: number): Promise<FindAllMobileServiceResponseDto> {
    return new Promise<FindAllMobileServiceResponseDto>((resolve) => {
      resolve(this.allMobileServices.find((item) => +item.crmAzonosito === crmId));
    });
  }

  getCurrentPackagePriceByLoyaltyMonth(
    data: FindAllMobileServiceResponseDto,
    selectedLoyaltyTime: number
  ): ServicePricingDto {
    return data.szolgaltatasArazasResponseDTOList.find((item) => +item.husegHonap === +selectedLoyaltyTime);
  }

  updateOrderObjectWithSelectedMobileService(
    packageItem: FindAllMobileServiceResponseDto,
    dontNavigate?: boolean
  ): void {
    this.findMobileServiceByCRM({ crmAzonosito: +packageItem.crmAzonosito }).subscribe((result) => {
      const selectedPhonePackageDetails: SelectedPhonePackageDetails = {
        reszletFutamido: 0,
        husegIdo: null,
        crmAzonosito: result.crmAzonosito,
      };

      this.selectedPhonePackageService.setSelectedPhonePackageDetails(selectedPhonePackageDetails);

      const pricing = result.szolgaltatasArazasResponseDTOList.find(
        (arazas) => arazas.husegHonap === packageItem.husegHonap
      );

      this.orderService.selectedMobileService = {
        selectedPackageBaseDetails: {
          arTipus: result.arTipus,
          crmAzonosito: result.crmAzonosito,
          csomagNev: result.csomagNev,
        },
        selectedPackagePrices: {
          haviDij: pricing.haviDij,
          husegHonap: pricing.husegHonap,
          egyszeriDij: pricing.egyszeriDij,
          crmAzonosito: pricing.crmAzonosito,
        },
      };

      this.orderService.selectedPhone = null;
      this.orderService.selectedDslService = null;
      if (!dontNavigate) {
        // A dontNavigate a SIM aktiválásnál van beállítva, hogyha csak egy csomag van, akkor az be legyen állítva, mint kiválasztott csomag. Ekkor viszont nem kattintott a megrendelés gombra.
        this.googleAnalyticsService.eventEmitter('kattintas', 'szolgaltatasok_megrendelem', null, 'gomb');
        const serviceQueryParams: IOrderUrlMobileAndDslServiceQueryParams = {
          crmIdentifier: result.crmAzonosito,
          loyaltyMonth: pricing.husegHonap,
        };
        const queryParams: IOrderUrlQueryParams = {
          type: OrderTypeQueryParamEnum.MOBILE_SERVICE,
          data: serviceQueryParams,
        };

        const encodedQueryParams = btoa(JSON.stringify(queryParams));

        this.router.navigate(['megrendeles'], { relativeTo: null, queryParams: { od: encodedQueryParams } });
      } else {
        this.updatedMobileOrderObject.next(true);
      }
    });
  }

  order(packageItem: MobilePackageDetails): void {
    this.findMobileServiceByCrmId(+packageItem.crmIdentifier).then((result) => {
      this.updateOrderObjectWithSelectedMobileService({ ...result, husegHonap: packageItem.loyaltyMonth ?? 0 });
    });
  }
}
