import { Component, Input } from '@angular/core';
import { CurrentSaleIdEnum, MobilePackagesDisplayTypeEnum, SegmentTypesEnum } from '../../../global/global.enums';
import {
  FindAllMobileServiceResponseDto,
  ServicePricingDto,
} from '../../../modules/mobile-services/mobile-services.interface';
import { ISelectedMobileServiceDetails } from '../../interfaces/mobile-packages-table.interfaces';
import { MobileServicesService } from '../../../modules/mobile-services/mobile-services.service';

@Component({
  selector: 'ose-mobile-package-card',
  templateUrl: './mobile-package-card.component.html',
  styleUrls: ['./mobile-package-card.component.scss'],
})
export class MobilePackageCardComponent {
  @Input() data!: FindAllMobileServiceResponseDto;
  @Input() dropdownSelectedData!: ISelectedMobileServiceDetails;
  @Input() displayType: MobilePackagesDisplayTypeEnum = MobilePackagesDisplayTypeEnum.ORANGE;

  constructor(private mobileServicesService: MobileServicesService) {}

  get headerTitleData(): string {
    switch (this.data.arTipus) {
      case 'BELFOLDI_KORLATLAN':
        return 'Korlátlan';
      case 'SZAZ_PERCES':
        return '100 perc';
      case 'EU_KORLATLAN':
        return 'EU Korlátlan';
    }
  }

  get descriptionData(): string {
    switch (this.data.arTipus) {
      case 'BELFOLDI_KORLATLAN':
        return `Korlátlan beszélgetés belföldön <br> ${this.data.bennfoglaltNet} internet`;
      case 'SZAZ_PERCES':
        return `100 perc beszélgetés belföldön <br> ${this.data.bennfoglaltNet} internet <br> További percdíj ${
          this.dropdownSelectedData.selectedSegmentType === SegmentTypesEnum.LAKOSSAGI ? '19' : '24'
        } Ft`;
      case 'EU_KORLATLAN':
        return `Korlátlan beszélgetés belföldön és az EU országok irányában <br>${this.data.bennfoglaltNet} internet`;
    }
  }

  get serviceDetails(): ServicePricingDto {
    return this.mobileServicesService.getCurrentPackagePriceByLoyaltyMonth(
      this.data,
      this.dropdownSelectedData.selectedLoyaltyTime
    );
  }

  get isWithoutLoyalty(): boolean {
    return this.dropdownSelectedData.selectedLoyaltyTime === 0;
  }

  get classNameByStyle(): string[] {
    const classList = [];

    switch (this.serviceDetails?.styleId) {
      case CurrentSaleIdEnum.SALE:
        classList.push(CurrentSaleIdEnum.SALE);
        break;
      default:
        break;
    }
    classList.push(this.displayType);
    return classList;
  }

  order(): void {
    this.mobileServicesService.updateOrderObjectWithSelectedMobileService({
      ...this.data,
      husegHonap: this.dropdownSelectedData.selectedLoyaltyTime,
    });
  }
}
