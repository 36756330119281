import { Injectable } from '@angular/core';
import { XhrService } from '../../core/services/xhr-service/xhr.service';
import { API } from '../../global/API.constants';
import { RegisterClickRequestDto } from './landingpage.interfaces';
import { Observable, throwError } from 'rxjs';
import { SessionStorageService } from '../../core/services/session-storage-service/session-storage.service';
import { GlobalConstants } from '../../global/global.constants';
import { FindUserByNicknevRequestDto, FindUserByNicknevResponseDto } from '../personal-site/personal-site.interfaces';
import { CookieStorageService } from '../../core/services/cookie-storage-service/cookie-storage.service';
import { GetLoggedInUserResponseDto } from '../partners/profile/profile.interfaces';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService {
  constructor(
    private xhr: XhrService,
    private sessionStorageService: SessionStorageService,
    private cookieStorageService: CookieStorageService,
    private router: Router
  ) {}

  registerClick(request: RegisterClickRequestDto): Observable<void> {
    return this.xhr.post<void>(API.share.registerClick, request, {}, '', false);
  }

  findUserByNickname(request: FindUserByNicknevRequestDto): Observable<FindUserByNicknevResponseDto> {
    return this.xhr.post<FindUserByNicknevResponseDto>(API.user.findByNickname, request, {}, '', false);
  }

  setRecommender(user: FindUserByNicknevResponseDto): void {
    this.deleteRecommender();
    if (this.cookieStorageService.getItem(GlobalConstants.cookieAcceptedKey)) {
      this.cookieStorageService.setItem(GlobalConstants.sessionStorageKeys.recommender, JSON.stringify(user), 3);
    } else {
      this.sessionStorageService.setItem(GlobalConstants.sessionStorageKeys.recommender, JSON.stringify(user));
    }
  }

  findAndSetRecommender(username: string): void {
    this.findUserByNickname({ nicknev: username }).subscribe((user: FindUserByNicknevResponseDto) => {
      this.setRecommender(user);
    });
  }

  setRecommenderInCookie(user: FindUserByNicknevResponseDto): void {
    this.deleteRecommender();
    this.cookieStorageService.setItem(GlobalConstants.sessionStorageKeys.recommender, JSON.stringify(user), 3);
  }

  setRecommenderAfterLogin(user: GetLoggedInUserResponseDto): void {
    this.findUserByNickname({ nicknev: user.nicknev }).subscribe((response) => {
      this.setRecommender(user);
    });
  }

  setSimRecommender(user: FindUserByNicknevResponseDto): void {
    this.deleteSimRecommender();
    if (this.cookieStorageService.getItem(GlobalConstants.cookieAcceptedKey)) {
      this.cookieStorageService.setItem(GlobalConstants.sessionStorageKeys.simRecommender, JSON.stringify(user), 3);
    } else {
      this.sessionStorageService.setItem(GlobalConstants.sessionStorageKeys.simRecommender, JSON.stringify(user));
    }
  }

  deleteRecommender(): void {
    this.cookieStorageService.removeItem(GlobalConstants.sessionStorageKeys.recommender);
    this.sessionStorageService.removeItem(GlobalConstants.sessionStorageKeys.recommender);
  }

  deleteSimRecommender(): void {
    this.cookieStorageService.removeItem(GlobalConstants.sessionStorageKeys.simRecommender);
    this.sessionStorageService.removeItem(GlobalConstants.sessionStorageKeys.simRecommender);
  }

  getRecommender(): FindUserByNicknevResponseDto {
    if (this.cookieStorageService.getItem(GlobalConstants.cookieAcceptedKey)) {
      return this.cookieStorageService.getObject<FindUserByNicknevResponseDto>(
        GlobalConstants.sessionStorageKeys.recommender
      );
    } else {
      return this.sessionStorageService.getObject<FindUserByNicknevResponseDto>(
        GlobalConstants.sessionStorageKeys.recommender
      );
    }
  }

  initLandingPageData(userNameFromURL: string, landingPageId: string): Observable<FindUserByNicknevResponseDto> {
    return this.findUserByNickname({ nicknev: userNameFromURL }).pipe(
      catchError((error) => {
        this.router.navigate(['404'], { relativeTo: null });
        return throwError(error);
      }),
      tap((response) => {
        this.setRecommender(response);
        this.registerClick({
          landingPageAzonosito: landingPageId,
          nicknev: userNameFromURL,
        }).subscribe();
      })
    );
  }
}
