import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { HttpLoaderInterceptor } from './interceptors/http-loader.interceptor';
import { GoogleAnalyticsService } from './services/google-analytics-service/google-analytics.service';
import { ServerStateInterceptor } from './interceptors/server-state.interceptor';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ServerStateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    GoogleAnalyticsService,
  ],
})
export class CoreModule {}
