<section class="all-packages-section">
  <div class="title-wrapper" [class]="backgroundColor" *ngIf="withTitle">
    <div class="netfone-container">
      <div class="title">Összes csomagunk</div>
    </div>
  </div>
  <div class="netfone-container">
    <h2 class="loyalty-month-title">Válassz hűségidőt</h2>
    <div class="loyalty-and-segment-dropdown">
      <ose-dropdown
        [labelList]="availableLoyaltyTimesDropdown"
        [formControl]="loyaltySelector"
        type="dropdown"
      ></ose-dropdown>
    </div>
  </div>
  <div class="netfone-container pc-packages">
    <div class="packages-wrapper">
      <ose-mobile-package-card
        *ngFor="let item of serviceListingData.unlimited.packages"
        [data]="item"
        [dropdownSelectedData]="dropdownSelectedData"
        [displayType]="MobilePackagesDisplayTypeEnum.BLACK"
      ></ose-mobile-package-card>
    </div>
    <div class="packages-wrapper">
      <ose-mobile-package-card
        *ngFor="let item of serviceListingData.hundredMinutes.packages"
        [data]="item"
        [dropdownSelectedData]="dropdownSelectedData"
        [displayType]="MobilePackagesDisplayTypeEnum.ORANGE"
      ></ose-mobile-package-card>
    </div>
    <!--    <div class="packages-wrapper" *ngIf="serviceListingData.euUnlimited?.packages?.length > 0">-->
    <!--      <ose-mobile-package-card-->
    <!--        *ngFor="let item of serviceListingData.euUnlimited.packages"-->
    <!--        [data]="item"-->
    <!--        [dropdownSelectedData]="dropdownSelectedData"-->
    <!--        [displayType]="MobilePackagesDisplayTypeEnum.TAN"-->
    <!--      ></ose-mobile-package-card>-->
    <!--    </div>-->
  </div>
  <div class="mobile-packages">
    <ose-mobile-packages-table
      [serviceList]="serviceListingData.unlimited.packages"
      [displayType]="mobilePackagesTableDisplayTypeEnum.BLACK"
      [tableHeaderData]="serviceListingData.unlimited.headerData"
      [dropdownSelectedData]="dropdownSelectedData"
    ></ose-mobile-packages-table>
    <ose-mobile-packages-table
      [serviceList]="serviceListingData.hundredMinutes.packages"
      [displayType]="mobilePackagesTableDisplayTypeEnum.ORANGE"
      [tableHeaderData]="serviceListingData.hundredMinutes.headerData"
      [dropdownSelectedData]="dropdownSelectedData"
    ></ose-mobile-packages-table>
    <!--    <ose-mobile-packages-table-->
    <!--      [serviceList]="serviceListingData.euUnlimited.packages"-->
    <!--      [displayType]="mobilePackagesTableDisplayTypeEnum.TAN"-->
    <!--      [tableHeaderData]="serviceListingData.euUnlimited.headerData"-->
    <!--      [dropdownSelectedData]="dropdownSelectedData"-->
    <!--    ></ose-mobile-packages-table>-->
  </div>
  <div class="netfone-container">
    <button class="new-netfone-button secondary black mb-4" (click)="withDevice()">KÉSZÜLÉKKEL KÉREM</button>
  </div>
</section>
