import { Component, Input } from '@angular/core';
import {
  IMobilePackagesTableHeader,
  ISelectedMobileServiceDetails,
} from '../../interfaces/mobile-packages-table.interfaces';
import {
  FindAllMobileServiceResponseDto,
  ServicePricingDto,
} from '../../../modules/mobile-services/mobile-services.interface';
import { CurrentSaleIdEnum, MobilePackagesDisplayTypeEnum } from '../../../global/global.enums';
import { MobileServicesService } from '../../../modules/mobile-services/mobile-services.service';

@Component({
  selector: 'ose-mobile-packages-table',
  templateUrl: './mobile-packages-table.component.html',
  styleUrls: ['./mobile-packages-table.component.scss'],
})
export class MobilePackagesTableComponent {
  @Input() serviceList!: FindAllMobileServiceResponseDto[];
  @Input() dropdownSelectedData!: ISelectedMobileServiceDetails;
  @Input() tableHeaderData!: IMobilePackagesTableHeader;
  @Input() displayType: MobilePackagesDisplayTypeEnum = MobilePackagesDisplayTypeEnum.ORANGE;

  mobilePackagesTableDisplayTypeEnum = MobilePackagesDisplayTypeEnum;

  constructor(private mobileServicesService: MobileServicesService) {}

  getServiceDetails(data: FindAllMobileServiceResponseDto): ServicePricingDto {
    return this.mobileServicesService.getCurrentPackagePriceByLoyaltyMonth(
      data,
      this.dropdownSelectedData.selectedLoyaltyTime
    );
  }

  getClassNameByStyle(item: FindAllMobileServiceResponseDto): string {
    switch (this.getServiceDetails(item)?.styleId) {
      case CurrentSaleIdEnum.SALE:
        return CurrentSaleIdEnum.SALE;
      default:
        return '';
    }
  }

  order(item: FindAllMobileServiceResponseDto): void {
    this.mobileServicesService.updateOrderObjectWithSelectedMobileService({
      ...item,
      husegHonap: this.dropdownSelectedData.selectedLoyaltyTime,
    });
  }
}
