import { Component, ElementRef, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth-service/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics-service/google-analytics.service';
import { filter } from 'rxjs/operators';
import { MultiplePackagesService } from '../../../modules/landing-pages/multiple-packages/multiple-packages.service';
import { MenuStyleEnum } from '../../../global/global.enums';
import { EnvironmentVariablesService } from '../../services/environment-variable-service/environment-variables.service';
import { ExposedEnvironmentVariable } from '../../../../environments/environment-variables';
import { HeaderService } from '../../services/header-service/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuOpen: boolean;
  menuStyle: MenuStyleEnum = MenuStyleEnum.NORMAL;
  menuStyleEnum = MenuStyleEnum;
  private toggleButton: Element;
  private sidebarVisible: boolean;

  constructor(
    private element: ElementRef,
    public authService: AuthService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private environmentVariablesService: EnvironmentVariablesService,
    private headerService: HeaderService
  ) {
    this.sidebarVisible = false;
  }

  get portalUrl(): string {
    return this.environmentVariablesService.getVar(ExposedEnvironmentVariable.USER_PORTAL_URL);
  }

  ngOnInit(): void {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('ntfc-navbar-toggler')[0];
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((value: NavigationEnd) => {
      this.menuStyle = this.headerService.getMenuDisplayModeFromUrl(value.url.substring(1));
    });
  }

  onClickLogout(): void {
    this.authService.logout();
  }

  navigateToPortal(): void {
    this.userPortalAnalytics();
    this.navigate(this.portalUrl);
  }

  navigate(url: string): void {
    window.open(url, '_parent');
  }

  userPortalAnalytics(): void {
    this.googleAnalyticsService.eventEmitter('kattintas', 'ugyfelkapu_megrendelem', null, 'gomb');
  }
}
